import { COMPANY_TYPES, primaryMain, secondaryLight, secondaryMain } from '../../../../../../utils/constants'
import { Box, Button, Dialog, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import { toConfirm } from '../../../../../../api/confirm'
import DropMenu from '../../../../../helpers/DropMenu'
import { ArrowDropDown } from '@mui/icons-material'
import LicenseExp from './Fields/LicenseExp'
import License from './Fields/License'
import { useFormik } from 'formik'
import Check from './Fields/Check'
import Adr from './Fields/Adr'
import Rd from './Fields/Rd'
import * as Yup from 'yup'


const validationSchema = Yup.object().shape({
    name: Yup.string().max(255, 'Нэр хэт урт байна!').required('Нэр оруулна уу!'),
    rd: Yup.string().matches(/^\d{7}$/, 'Регистр буруу байна!').required('Регистрийн дугаар оруулна уу!'),
    cType: Yup.string().oneOf(Object.keys(COMPANY_TYPES), 'Үйл ажиллагааны чиглэл буруу байна!').required('Үйл ажиллагааны чиглэл сонгоно уу!'),
    license: Yup.array().of(Yup.object()).nullable(),
    licenseExp: Yup.string().nullable().matches(/^\d{4}-\d{2}-\d{2}$/, 'Формат буруу! Жишээ: 2024-01-01'),
    macsUrl: Yup.string().nullable(),
    address: Yup.string().nullable(),
    isReviewed: Yup.boolean().test('isReviewed', ' Утга сонгоно уу!', (value, ctx) => {
        if (value === true) {
            const { name, rd, cType, license, licenseExp, macsUrl, address } = ctx.parent
            if (!name) { return ctx.createError({ path: 'name', message: 'Нэр оруулаагүй байна!' }) }
            if (!rd) { return ctx.createError({ path: 'rd', message: 'Регистрийн дугаар оруулаагүй байна!' }) }
            if (!cType) { return ctx.createError({ path: 'cType', message: 'Үйл ажиллагааны чиглэл сонгоогүй байна!' }) }
            if (!license) { return ctx.createError({ path: 'license', message: 'Тусгай зөвшөөрөл хавсаргаагүй байна!' }) }
            if (!licenseExp) { return ctx.createError({ path: 'licenseExp', message: 'Тусгай зөвшөөрөл дуусах хугацаа оруулаагүй байна!' }) }
            if (!address) { return ctx.createError({ path: 'address', message: 'Хаягийн мэдээлэл оруулаагүй байна!' }) }
            return true
        }
        return true
    })
})

export default function Edit({ sel, handleClose, refetch, setOkMsg, setErr }) {
    const onSubmit = (data) => {
        toConfirm({ companyId: data.user, data })
            .then(res => { handleClose(); setOkMsg('Амжилттай шинэчлэгдлээ!'); refetch(); })
            .catch(_ => { setErr('Түр хүлээгээд дахин оролдоно уу!'); console.log("ER:", _) })
    }

    const { handleSubmit, getFieldProps, setFieldValue, handleChange, values, errors, touched } = useFormik({
        initialValues: sel,
        validationSchema,
        onSubmit,
    })
    const getErrors = (name) => {
        const isErr = Object.keys(errors).includes(name) && Object.keys(touched).includes(name)
        return { error: isErr, helperText: isErr ? errors[name] : ' ' }
    }

    //console.log("V: ", values, errors)

    return (
        <Dialog open={Boolean(sel)} onClose={handleClose} >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', p: 5, pt: 4, width: '600px' }}>
                <Typography fontSize='16px' sx={{ textAlign: 'center', color: primaryMain, mb: 3 }}>Бүртгэл баталгаажуулалт</Typography>
                <TextField 
                    label='Нэр'
                    inputProps={{ style: { fontSize: '15px' }}}
                    InputLabelProps={{ style: { fontSize: '15px' } }}
                    name='name'
                    {...getFieldProps('name')}
                    {...getErrors('name')}
                    size='small'
                    sx={{ width: '100%' }}
                />
                <Rd getFieldProps={getFieldProps} getErrors={getErrors} rd={values?.rd} name={values?.name} />
                <DropMenu
                    title={
                        <TextField
                            label='Үйл ажиллагааны чиглэл'
                            inputProps={{ style: { fontSize: '15px', cursor: 'pointer' }}}
                            InputLabelProps={{ style: { fontSize: '15px' } }}
                            size='small'
                            value={COMPANY_TYPES?.[values?.cType]||''}
                            {...getErrors('cType')}
                            sx={{ width: '100%', borderColor: '#1b2e3c' }}
                            InputProps={{ readOnly: true, endAdornment: (<InputAdornment><ArrowDropDown sx={{ color: '#1b2e3c' }} /></InputAdornment>)}}
                        />
                    }
                    detail={
                        <Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                            {Object.keys(COMPANY_TYPES).map(t => <MenuItem key={`${t}`} onClick={_ => { setFieldValue('cType', t) }} sx={{ width: '245px' }}>
                                    <Typography fontSize={'15px'} sx={{ color: '#1b2e3c' }}>{COMPANY_TYPES[t]}</Typography>
                                </MenuItem>)}
                        </Box>
                    }
                    top={38}
                    left={0}
                    width={'fit-content'}
                />
                <License f={values?.license} setFieldValue={setFieldValue} error={errors?.license} />
                <LicenseExp getFieldProps={getFieldProps} getErrors={getErrors} />
                <Adr address={values?.address} setFieldValue={setFieldValue} error={errors?.address} />
                { 
                    values?.cType === 'S' &&
                        <TextField
                            label='MACS url'
                            inputProps={{ style: { fontSize: '15px' }}}
                            InputLabelProps={{ style: { fontSize: '15px' } }}
                            name='macsUrl'
                            {...getFieldProps('macsUrl')}
                            {...getErrors('macsUrl')}
                            size='small'
                            sx={{ width: '100%' }}
                        />
                }
                <Check label={'Мэдээлэл баталгаажсан эсэх?'} value={values?.isReviewed} handleChange={handleChange} />
                <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', mt: 3 }}>
                    <Button sx={{ color: '' }} variant='contained' onClick={ handleSubmit }>Хадгалах</Button>
                    <Button 
                        sx={{ 
                            backgroundColor: secondaryLight, ml: 2, width: '30%',
                            '&:hover': { backgroundColor: secondaryMain } 
                        }} 
                        onClick={handleClose} variant='contained'>Болих</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
