import { SentimentDissatisfied } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'


const ErrorPage = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100hh', height: '100vh' }}>
      <Typography fontSize='35px' sx={{ m: 2, textShadow: '0 2px 5px', color: 'secondary.light' }}>
          Хуудас олдсонгүй, 404!
      </Typography>
      <SentimentDissatisfied sx={{ color: 'secondary.light', fontSize: '40px' }} />
    </Box>
  )
}

export default ErrorPage