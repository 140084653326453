import { Box, Grow, TableCell } from '@mui/material'
import { useRef, useState } from 'react'

export default function TableCellMenu({ current, dropMenu }) {
    const [isHover, setIsHover] = useState(false)
    const handleHoverOff = () => { setIsHover(false) }

    const rf = useRef(null)
    const cellBox = rf?.current?.getBoundingClientRect()

    return (
        <TableCell
            ref={rf}
            sx={{ position: 'relative', p: 0, '&:hover': { backgroundColor: '#e1e1e1', cursor: 'pointer' } }}
            onMouseEnter={(e) => { setIsHover(true); e.stopPropagation() }}
            onMouseLeave={(e) => { handleHoverOff(); e.stopPropagation() }}
        >
            {current}
            <Grow in={isHover}>
                <Box
                    sx={{
                        position: 'absolute', zIndex: 15000, width: cellBox?.width,
                        top: cellBox?.height, left: '2px', display: isHover ? 'block' : 'none'
                    }}
                >{dropMenu}</Box>
            </Grow>
        </TableCell>
    )
}

export const PROMO_TYPES = {
    1: 'Багцын урамшуулал',
    2: 'Худалдан авалтын урамшуулал',
    3: 'Барааны урамшуулал',
}
export const TARGET_TYPES = {
    1: 'Бүх хэрэглэгч',
    2: 'Шинэ хэрэглэгч',
    3: 'Зорилтот харилцагч',
    4: 'Бүх харилцагч'
}

export const ACTION_TYPES = {
    'ACTION_1': 'Борлуулагчийн үйлдэл',
    'CC': 'Шинэ харилцагч бүртгэсэн',
    'CO': 'Захиалга авсан',
    'ACTION_2': 'Түгээгчийн үйлдэл',
    'SS': 'Түгээлт эхлүүлсэн',
    'ES': 'Түгээлт дуусгасан',
    'GO': 'Захиалга хүлээлгэн өгсөн',
    'RO': 'Захиалгад буцаалт хийсэн',
    'ACTION_3': 'Захиалгын менежерийн үйлдэл',
    'OD': 'Захиалгыг түгээгчид хуваариласан',
    'ODC': 'Захиалгыг түгээлтээс хассан',
    'UOD': 'Захиалгын түгээгч өөрчилсөн',
    'CSh': 'Түгээлт цуцалсан',
    'UOP': 'Захиалгын явц өөрчилсөн',
    'UOS': 'Захиалгын төлөв өөрчилсөн',
    'SOP': 'Захиалгыг бэлтгэгчид хуваариласан',
    'COP': 'Захиалгын бэлтгэгч өөрчилсөн',
    'ROP': 'Захиалгыг бэлтгэхийг болиулсан',
    'ACTION_4': 'Админы үйлдэл',
    'AS': 'Ажилтан нэмсэн',
    'CS': 'Ажилтны мэдээлэл өөрчилсөн',
    'RS': 'Ажилтны мэдээлэл усгасан'
}

export const MACS_ACTION_TYPES = {
    'LO': 'Шинэ захиалгуудын мэдээлэл татсан',
    'CP': 'Бараанууд нэмсэн',
    'UP': 'Бараанууд шинэчилсэн',
    'AIO': 'Захиалгад бараа нэмсэн',
    'UOI': 'Захиалгад тоо/ш засварласан',
    'ROI': 'Захиалгаас бараа хассан',
    'SO': 'Захиалгыг цуцалсан',
    'UOS': 'Захиалгын төлөв өөрчилсөн',
    'UOP': 'Захиалгын явц өөрчилсөн'
}
