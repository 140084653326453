import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material/'
import { getToast } from '../../auth/helpers/ToastAlert'
import { checkProductQty } from '../../../api/product'
import { scrollStyle } from '../../../utils/constants'
import { useAuthCtx } from '../../../context/AuthCtx'
import { useEffect, useState } from 'react'
import BasketTemplet from './BasketTemplet'
import { useLocation } from 'react-router'
import { keys, sortBy } from 'underscore'
import { calcItemPrice } from './utils'
import { qtyMsgs } from '../../hooks'
import { CustomDivider } from '..'
import Footer from './Footer'
import Row from './Row'

// Үндсэн компонэнт
export default function OrderItems({ qtysErr, setQtysErr }) {
    const { basket } = useAuthCtx()
    const basketItems = sortBy(basket?.items, 'id')||[]
    // basketItems өөрчлөгдөх үед нийлбэр үнийн дүн, тоо хэмжээг тооцоолох
    const totals = basketItems?.reduce((acc, curr) => {
        if (!acc['totalPrice']) { acc['totalPrice'] = 0 }
        if (!acc['totalCount']) { acc['totalCount'] = 0 }
        acc['totalPrice'] = acc['totalPrice'] + calcItemPrice(curr)
        acc['totalCount'] = acc['totalCount'] + curr?.qty
        return acc
    }, {})
    const { totalPrice, totalCount } = totals ? totals : {}
    // save temporarily
    useEffect(() => {
        if (totalPrice) { window.localStorage.setItem('tp', totalPrice) }
        if (totalCount) { window.localStorage.setItem('tc', totalCount) }
    }, [totalPrice, totalCount])
    // basketItems qtys
    const prepQtys = () => basketItems?.reduce((acc, cur) => {
        acc[cur?.qtyId] = cur?.qty
        return acc
    }, {})

    // барааны үлдэгдэл хүрэлцэхгүй эсвэл бараа дууссан эсэх мэдээлэл
    const [qd, setQd] = useState(null)
    const handleQtysErr = (data) => {
        const noQty = keys(data)?.find(k => data[k] === null)
        const lessQty = keys(data)?.find(k => data[k] === false)
        if (noQty) { setQtysErr(qtyMsgs.noQty) }
        if (lessQty) { setQtysErr(qtyMsgs.lessQty) }
        else { setQtysErr(null) }
    }
    useEffect(() => { 
        if (basketItems?.length) {
            const ids = prepQtys()
            checkProductQty(ids)
                .then(res => {
                    handleQtysErr(res?.data)                    
                    setQd(res.data)
                }).catch(e => { console.log("BULK err: ", e); setQtysErr(false); setQd(null) })
        }
    }, [])

    const loc = useLocation()
    const fromTemplet = loc?.state?.fromTemplet

    // basket templet saved
    const [bt, setBt] = useState(null)
    const btOff = () => { setBt(null) }
    
    return (
        <>
            <CustomDivider text={'Барааны тоо, ширхэг'} />
            { getToast(Boolean(bt), btOff, bt, 'success', 1500) }
            {
                (basketItems && totalCount && totalPrice)
                    ?   <Box>
                            <TableContainer sx={{ width: '100%', ...scrollStyle, display: 'flex', justifyContent: 'center' }}>
                                <Table stickyHeader size='small' sx={{ width: '90%' }}>
                                    <TableHead>
                                        <TableRow sx={{ '& > th': { py: '2px', px: '6px', pl: '4px', textAlign: 'right', fontSize: '14px', fontWeight: 'bold', fontStyle: 'italic', color: 'primary.light' } }}>
                                            <TableCell width='30px'>№</TableCell>
                                            <TableCell sx={{ textAlign: 'left !important' }}>Барааны нэр</TableCell>
                                            <TableCell width='130px'>Үндсэн үнэ</TableCell>
                                            <TableCell width='130px'>Хямдралтай үнэ</TableCell>
                                            <TableCell width='130px'>Бөөний үнэ</TableCell>
                                            <TableCell width='95px'>Тоо, ширхэг</TableCell>
                                            <TableCell width='120px'>Нийт үнэ</TableCell>
                                            <TableCell width='30px' />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            basketItems.map( (b, idx) => {
                                                    return <Row key={`${idx}`} item={b} idx={idx} qd={qd} qtysErr={qtysErr} setQtysErr={setQtysErr} />
                                                } 
                                            )
                                        }
                                    </TableBody>
                                    <Footer totalCount={totalCount} totalPrice={totalPrice} />
                                </Table>
                            </TableContainer>
                            { !fromTemplet && <BasketTemplet basket={basket} setBt={setBt} /> }
                        </Box>
                    :   <div></div>
            }
        </>
    )
}