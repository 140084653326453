import { scrollStyle } from '../../../../../../../../utils/constants'
import { Box, Typography } from '@mui/material'
import Product from './Product'


export default function Gift({ products }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', borderRadius: 1, boxShadow: 5 }}>
                <Box
                    sx={{
                        display: 'flex', flexDirection: 'row', gap: 1, p: 2, pb: 1,
                        maxWidth: '400px', overflowX: 'auto', ...scrollStyle,
                        '&::-webkit-scrollbar': { height: 8 },
                        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light', borderRadius: 1 },
                    }}
                >
                    { products?.map(product => ( <Product key={product.id} product={product} /> )) }
                </Box>
                <Typography sx={{ color: 'primary.main', mb: 1 }}>бэлгэнд аваарай!</Typography>
            </Box>
        </Box>
    )
}
