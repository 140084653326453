import AxiosWithToken from "../global/axiosBase"
import { keys } from "underscore"

// order
export const addNewOrder = (orderInfo) => {
    return AxiosWithToken.post('pharmacy/order/', orderInfo)
}
export const getOrder = ({ queryKey }) => {
    const [_, orderId, verbose] = queryKey
    const url = verbose ? `order/${orderId}/?verbose=true` : `order/${orderId}/`
    return AxiosWithToken.get(url)
}

export const getOrders = ({ queryKey }) => {
    const [_, filters, ordering, pageSize, page] = queryKey
    let qParam = 'order/?'
    if (filters?.length) {
        for (let f of filters) {
            if (qParam.includes('=')) { qParam += '&' }
            qParam +=`${f.fKey}${f.operator}${ f.operator !== '=' ? '=' : '' }${f.fValue}`
        }
    }
    if (ordering) { qParam += qParam.endsWith('/') ? `?${ordering}` : `&${ordering}` }
    if (page) {
        let pgs = `page=${page}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }
    //console.log("QP: ", qParam)
    return AxiosWithToken.get(keys(filters)?.length || ordering || pageSize ? qParam : 'order/')
}

export const getOrders2 = ({ queryKey }) => {
    const [_, filters, pageSize, page] = queryKey
    let qParam = 'order/'
    if (keys(filters)?.length) {
        for (const f of filters) {
            const fq = f?.k + f?.o + f?.v
            qParam += qParam?.endsWith('/') ? `?${fq}` : qParam.endsWith('?') ? fq : `&${fq}`
        }
    }
    if (page) {
        let pgs = `page=${page}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : qParam.endsWith('?') ? pgs : `&${pgs}`
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : qParam.endsWith('?') ? pgs : `&${pgs}`
    }
    return AxiosWithToken.get(qParam)
}

export const getOrderItems = (orderId) => {
    return AxiosWithToken.get(`pharmacy/orders/${orderId}/items/`)
}

// zzn
export const getAimag = () => {
    return AxiosWithToken.get('aimag_hot/')
}
export const getSum = ({ queryKey }) => {
    const [_, aimagId] = queryKey
    return AxiosWithToken.get(`sum_duureg/`, { params: { aimag: aimagId ? aimagId : null } })
}
export const getBag = ({ queryKey }) => {
    const [_, sumId] = queryKey
    return AxiosWithToken.get('bag_horoo/', { params: { sum: sumId ? sumId : null } })
}

// order fields
export const getOrderFieldsInfo = () => {
    return AxiosWithToken.get('order_fields_info/')
}
export const updateOrderFieldsInfo = (data) => {
    return AxiosWithToken.patch('order_fields_info/', data)
}

// change pin
export const updatePin = (pin) => {
    return AxiosWithToken.post('auth/pin/', pin)
}
// check pin
export const checkPin = (pin) => {
    return AxiosWithToken.post('auth/check_pin/', pin)
}

// change order status
export const changeStatus = (data) => {
    return AxiosWithToken.patch('change_status/', data)
}
// change order status
export const changeProcess = (data) => {
    return AxiosWithToken.patch('change_process/', data)
}
// change order payType
export const changePayType = (data) => {
    return AxiosWithToken.patch('change_paytype/', data)
}
// accept order
export const acceptOrder = (data) => {
    return AxiosWithToken.patch('pharmacy/accept_order/', data)
}
// get order note
export const getOrderNote = (orderId) => {
    return AxiosWithToken.get(`order_note/?orderId=${orderId}`)
}

// promo order
// create order
export const createPromoOrder = (data) => { return AxiosWithToken.post('pharmacy/promo_order/', data) }
export const checkPromoOrderPayment = (invoiceId) => { return AxiosWithToken.patch('pharmacy/promo_order/cp/', { invoiceId }) }

