import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { updateUserInfo } from '../../../../../api/auth'
import ReadOnlyField from '../ReadOnlyField'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({
    name: Yup.string().max(150, 'Нэр хэт урт байна!').nullable(),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар шаардлагатай!')
})

export default function Userinfo({ name, email, phone }) {    
    const [msg, setMsg] = useState(null)
    const msgOff = () => { setMsg(null) }

    const { handleSubmit, getFieldProps, errors, touched } = useFormik({
        initialValues: { name, phone },
        onSubmit: (values) => {
            updateUserInfo(values)
                .then(_ => { setMsg({ m: 'Амжилттай!', s: 'success' }) })
                .catch(e => { 
                    setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                    console.log("ERR: ", e)
                })
        },
        validationSchema,
        validateOnChange: false
    })

    const getErrors = (name) => {
        const err = errors[name] && touched[name]
        return {
            error: err,
            helperText: err ? errors[name] : ''
        }
    }

    return (
        <Box>
            {getToast(Boolean(msg), msgOff, msg?.m, msg?.s, 1500)}
            <Typography sx={{ fontSize: '15px', color: '#666', mb: 2, mt: 2 }}>Хэрэглэгчийн мэдээлэл</Typography>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <TextField
                        label='Нэр'
                        name='name'
                        {...getFieldProps('name')}
                        {...getErrors('name')}
                        size='small'
                    />
                </Grid>
                <Grid item xs={4}><ReadOnlyField label='Имэйл' value={email} /></Grid>
                <Grid item xs={3}>
                    <TextField
                        label='Утасны дугаар'
                        name='phone'
                        {...getFieldProps('phone')}
                        {...getErrors('phone')}
                        size='small'
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button 
                        size='small' 
                        variant='contained' 
                        sx={{ p: '9px' }}
                        onClick={handleSubmit}
                    >Хадгалах</Button>
                </Grid>
            </Grid>
        </Box>
    )
}
