import { TableBody, TableCell, TableRow } from '@mui/material'

import { toPrice } from '../../../../utils/functions'

export default function VerifyTable({ payType, totalPrice, totalCount }) {
    const adr = window.localStorage.getItem('adr')||'Очиж авах'
    const tp = payType === 'C' ? totalPrice : window.localStorage.getItem('tp')||''
    const tc = payType === 'C' ? totalCount : window.localStorage.getItem('tc')||''

    const pt = payType === 'L' ? 'Зээлээр' : payType === 'C' ? 'Бэлнээр' : ' '

    return (
        <TableBody sx={{ '& > tr > td': { m: 0, paddingTop: '4px', paddingBottom: '4px', fontSize: '15px' }, pt: 1, pb: 3 }}>
            <TableRow>
                <TableCell sx={{ color: 'secondary.light', width: '150px' }}>Хүргэлтийн хаяг</TableCell>
                <TableCell align='right' sx={{ color: 'primary.main' }}>{adr}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ color: 'secondary.light', width: '150px' }}>Төлбөрийн хэлбэр</TableCell>
                <TableCell align='right' sx={{ color: 'primary.main' }}>{pt}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ color: 'secondary.light', width: '150px' }}>Нийт үнэ</TableCell>
                <TableCell align='right' sx={{ color: 'primary.main' }}>{tp ? toPrice(tp) : ''}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ color: 'secondary.light', width: '150px' }}>Нийт, тоо ширхэг</TableCell>
                <TableCell align='right' sx={{ color: 'primary.main' }}>{tc}</TableCell>
            </TableRow>
        </TableBody>
    )
}
