import { useCountInput } from '../../../../../../hooks'
import { Box, Button, TextField } from '@mui/material'

export default function CountInput({ count, setCount, handleClick }) {
    const { err, value, handleValue, handleAdd, handleEnter } = useCountInput({ setCount, handleClick, count })
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0, justifyContent: 'center', alignItems: 'center' }}>
            <TextField
                label='Тоо, ширхэг'
                sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
                    "& input[type=number]": { MozAppearance: "textfield" },
                    width: '170px'
                }}
                autoFocus
                value={ value }
                onChange={ handleValue }
                type='number'
                inputProps={{ style: { textAlign: 'center', padding: 0, fontSize: '22px' } }}
                onKeyDown={ handleEnter }
                variant='standard'
                size='small'
                error={Boolean(err)}
                helperText={Boolean(err) ? err : ' '}
                InputLabelProps={{ sx: { fontSize: '22px' } }}
            />
            <Button
                variant='contained'
                onClick={ handleAdd }
                sx={{ 
                    ml: 1, mb: 2, width: '160px', 
                    p: 1,
                    borderRadius: 0,
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': { backgroundColor: 'primary.main' }
                }}
            >
                Сагсанд нэмэх
            </Button>
        </Box>
    )
}