import { secondaryLight } from '../../../../../../utils/constants'
import { TableCell, TableRow } from '@mui/material'
import { AdrCell, BoolCell } from './Cells'
import { format } from 'date-fns'
import { cTypes } from '../..'


export function Row({ b, idx, setSel }) {
    const d = b?.createdOn ? format(new Date(b.createdOn), 'yyyy-MM-dd HH:mm:ss') : '-'
    return (
        <TableRow
            sx={{
                '&:hover': { backgroundColor: '#f3f3f3' },
                '& > td': { 
                    borderBottom: `1px solid ${secondaryLight}`, p: 0, pl: '4px', pr: '4px',
                    //'&:hover': { backgroundColor: '#f5f5f5 !important', cursor: 'pointer' }
                }
            }}
        >
            <TableCell align='center' onClick={() => setSel(b)} sx={{ transition: 'all 0.3s', '&:hover': { backgroundColor: secondaryLight, color: 'white', cursor: 'pointer' } }}>{idx + 1}</TableCell>
            <TableCell>{b?.aemail}</TableCell>
            <TableCell>{b?.aphone}</TableCell>
            <TableCell align='center'>{b?.user}</TableCell>
            <TableCell>{b?.name}</TableCell>
            <TableCell align='center'>{b?.rd}</TableCell>
            <TableCell>{cTypes[b?.cType]}</TableCell>
            <AdrCell value={b?.address} />
            <BoolCell checked={b?.license?.length > 0} />
            <TableCell align='center'>{b?.licenseExp}</TableCell>
            <TableCell>{b?.macsUrl||'-'}</TableCell>
            <BoolCell checked={b?.isReviewed} />
            <TableCell>{d}</TableCell>
        </TableRow>
    )
}
