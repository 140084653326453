import AxiosWithToken from "../global/axiosBase"

// seller order
function isPhone(s) {
    return /^\d{8}$/.test(s) || /^\d{6}$/.test(s)
}
function isCompanyReg(s) {
    return /^\d{7}$/.test(s)
}
function isCitizenReg(s) {
    return /^[\u0410-\u042F\u0430-\u044F]{2}\d{8}$/.test(s)
}

// search customer
export const searchCustomer = ({ queryKey }) => {
    const [ _, seval, page, pageSize ] = queryKey
    let url = `seller/customer/?page=${page}&page_size=${pageSize}`
    if (seval) url += `&name__icontains=${seval}`
    //console.log("U: ", url)
    return AxiosWithToken.get(url)
    /* if (isPhone(seval)) {
        //console.log("p: ", `${url}?phone=${seval}`)
        return AxiosWithToken.get(`${url}?phone=${seval}`)
    }
    else if (isCompanyReg(seval)||isCitizenReg(seval)) {
        //console.log("r: ", `${url}?rn=${seval}`)
        return AxiosWithToken.get(`${url}?rn=${seval}`)
    }
    else {
        //console.log("n: ", `${url}?name__icontains=${seval}`)
        return AxiosWithToken.get(`${url}?name__icontains=${seval}`)
    } */
}

export const searchProduct = ({ queryKey }) => {
    const [ _, seval, page, pageSize ] = queryKey
    let url = `seller/product/?page=${page}&page_size=${pageSize}`
    if (seval) url += `&name=${seval}`
    //console.log("p: ", url)
    return AxiosWithToken.get(url)
    /* if (!isNaN(seval)) {
        console.log("first: ", `${url}?barcode=${seval}`)
        return AxiosWithToken.get(`${url}?barcode=${seval}`)
    }
    else {
        console.log("second: ", `${url}?name=${seval}`)
        return AxiosWithToken.get(`${url}?name=${seval}`)
    } */
}

export const createSellerOrder = (data) => {
    return AxiosWithToken.post('seller/order/', data)
}