import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router'
import { Add } from '@mui/icons-material'
import Product from './Product'
import EndDate from './EndDate'
import Gift from './Gift'

export default function ProductPromo({ promo }) {
    const navi = useNavigate()
    const handleClick = () => { navi('/promo_order', { state: { promo } }) }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography sx={{ color: 'primary.main', ml: 1, mb: 1, color: 'secondary.main', fontWeight: 500, fontSize: '20px', textAlign: 'center' }}>{promo?.name||'-'}</Typography>
            {promo?.desc && <Typography sx={{ display: 'flex', justifyContent: 'center', p: 2, my: 2 }}>{promo?.desc}</Typography>}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Product product={promo?.bundle?.[0]||null} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Add sx={{ fontSize: '30px', fontWeight: 700, mx: 2 }} />
                </Box>
                <Gift products={promo?.gift} />
            </Box>
            {promo?.end_date && <EndDate endDate={promo?.end_date} />}
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', my: 2 }}>
                <Button variant='contained' size='small' sx={{ mr: 1 }} onClick={handleClick}>Захиалах</Button>
            </Box>
        </Box>
    )
}
