import { Box, Table, TableCell, TableRow, Typography } from "@mui/material"
import { primaryLight } from "../../../../../../../utils/constants"
import OC from "./Bodies/OC"

export default function getNotifBody (type, body) {
    //console.log("A: ", type, body)
    //const {}
    switch (type) {
        case 'OC':
            return <OC body={body} />
        case 'OO':
            return <Box>
                    <Table sx={{ '& > tr > td': { color: primaryLight, p: 0, pl: 1, border: 'none' } }}>
                        <TableRow sx={{ p: 1 }}>
                            <TableCell sx={{ width: '150px' }}>Захиалгын дугаар:</TableCell>
                            <TableCell sx={{ fontWeight: 'bold', color: '#843333 !important' }}>{body?.data?.orderNo}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Нийлүүлэгч</TableCell>
                            <TableCell>{body?.data?.supplier}</TableCell>
                        </TableRow>
                    </Table>
                </Box>
        case 'SU':
            return <Typography fontSize='14px' sx={{ color: primaryLight, padding: 0 }} dangerouslySetInnerHTML={{ __html: body}} />
        default:
            return ''
    }
}