// order constants
export const PAYMENT_TYPES = {
    C: 'Бэлнээр',
    L: 'Зээлээр',
    T: 'Дансаар'
}

export const PROCESS_TYPES = {
    N: 'Шинэ',
    M: 'Бэлтгэж эхэлсэн', 
    P: 'Бэлэн болсон',
    O: 'Түгээлтэнд гарсан',
    A: 'Хүлээн авсан',
    R: 'Буцаагдсан',
    C: 'Хаалттай'
}

export const STATUS_TYPES = {
    W: 'Төлбөр хүлээгдэж буй',
    P: 'Төлбөр төлөгдсөн',
    S: 'Цуцлагдсан',
    C: 'Биелсэн'
}

export const PROMO_TYPES = {
    1: 'Багцын урамшуулал',
    2: 'Худалдан авалтын урамшуулал',
    3: 'Барааны урамшуулал'
}

export const COM_TYPES = {
    "P": 'Эмийн сан',
    'S': 'Нийлүүлэгч'
}

export const PROCESS_COLORS = [
    { name: 'N', color: '#2357bc' },
    { name: 'M', color: '#4c489b' },
    { name: 'P', color: '#733b97' },
    { name: 'O', color: '#2fbbb3' },
    { name: 'A', color: '#07b151' },
    { name: 'C', color: '#fbb40f' },
    { name: 'R', color: '#d52127' }
]