import { List, Typography } from "@mui/material"
import CustomerItem from "./customer"

export default function CustomerList({ customers, selectCustomer, selected }) {
    const selectedName = customers.find(c => c.id === selected)?.name
    return (
        <List>
            {selectedName && <Typography fontSize='23px' sx={{ textDecoration: 'underline' }}>Сонгосон харилцагч: {selectedName}</Typography>}
            {customers?.map((customer, index) => (
                <CustomerItem key={customer?.id} props={customer} handleSelect={selectCustomer} />
            ))}
        </List>
    )
}