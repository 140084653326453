import { Dialog, Paper, Slide } from '@mui/material'
import CheckRegistration from './CheckRegistration'
import RegistrationForm from '../RegistrationForm'
import RegisterLocation from '../RegisterLocation'
import { getToast } from '../helpers/ToastAlert'
import RegisterLicense from '../RegisterLicense'
import RegisterAddress from '../RegisterAddress'
import Container from '@mui/material/Container'
import CreatePwd from '../PwdReset/CreatePwd'
import { forwardRef, useState } from 'react'
import { isEmpty } from 'underscore'
import Box from '@mui/material/Box'

export const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

// Main component
export default function SignIn() {
	const [email, setEmail] = useState('')
	const [p, setP] = useState(null)
	
	const [badEmail, setBadEmail] = useState(null)
	const [noPwd, setNoPwd] = useState(null)
	
	const [incomplete, setIncomplete] = useState(null)
	const [registerAddress, setRegisterAddress] = useState(null)
	const [registerLocation, setRegisterLocation] = useState(null)
	const [branches, setBranches] = useState(null)	
	const [registerLicense, setRegisterLicense] = useState(null)

	const [alert, setAlert] = useState(null)
	const alertOff = () => { setAlert(null) }
	const alertSuccess = () => { setAlert({ m: 'Амжилттай!', s: 'success' }) }

	const restart = () => {
		setNoPwd(null)
		setIncomplete(null)
		setRegisterAddress(null)
		setRegisterLicense(null)
		setRegisterLocation(null)
	}
	
	const toLogin = badEmail === false && noPwd === false

	const open = noPwd||incomplete||registerAddress||registerLicense||registerLocation

	const [tk, setTk] = useState(null)

	return (
		<Container component="main" maxWidth="xs" sx={{ mt: 25 }}>
			<Box
				sx={{ marginTop: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, mb: 4 }}
				component={Paper}
				elevation={24}
			>
				<CheckRegistration
					email={email}
					setEmail={setEmail}
					setBadEmail={setBadEmail}
					setNoPwd={setNoPwd}
					toLogin={toLogin}
					setIncomplete={setIncomplete}
					setRegisterAddress={setRegisterAddress}
					setRegisterLocation={setRegisterLocation}
					setBranches={setBranches}
					setRegisterLicense={setRegisterLicense}
					setAlert={setAlert}
					restart={restart}
					setTk={setTk}
					setP={setP}
				/>
			</Box>
			{getToast(!isEmpty(alert), alertOff, alert?.m, alert?.s, 2500 )}
			<Dialog 
				open={open}
				onClose={restart}
				TransitionComponent={Transition}
				maxWidth='md'
			>
				{noPwd && <CreatePwd email={email} alertSuccess={alertSuccess} handleClose={restart} />}
				{incomplete && <RegistrationForm alertSuccess={alertSuccess} handleClose={restart} email={email} />}
				{registerAddress && <RegisterAddress alertSuccess={alertSuccess} handleClose={restart} />}
				{registerLocation && <RegisterLocation setAlert={setAlert} handleClose={restart} branches={branches} tk={tk} />}
				{registerLicense && <RegisterLicense alertSuccess={alertSuccess} handleClose={restart} email={email} p={p} setAlert={setAlert} />}
			</Dialog>
		</Container>
	)
}