import { Box, Button, Dialog, DialogContent, IconButton, InputAdornment, TextField } from "@mui/material"
import { Visibility } from "@mui/icons-material"
import { useEffect, useState } from "react"

export default function AddPassword({ handleClosePwdDialog, handleSend, attachPwd, processFormData }) {
    const [isShow, setIsShow] = useState(false)
    const handleShow = () => { setIsShow(true) }
    const handleHide = () => { setIsShow(false) }
    const [pwd, setPwd] = useState('')
    const handleChange = (e) => {
        const v = e.target.value
        setPwd(v)
        attachPwd(v)
    }
    useEffect(() => { processFormData() }, [])
    const handleKeyDown = (e) => { if (e.key === 'Enter') { handleSend() } }

    return (
        <Dialog open={true} onClose={handleClosePwdDialog}>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                    <TextField
                        label='Нууц үг'
                        name='password'
                        size='small'
                        type={ isShow ? 'text' : 'password' }
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                            <IconButton 
                                                sx={{ m: 0, cursor: 'default' }} 
                                                onMouseEnter={handleShow}
                                                onMouseLeave={handleHide}
                                            >
                                                <Visibility />
                                            </IconButton>
                                        </InputAdornment>
                        }}
                        value={pwd}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                    />
                    <Button
                        size='small'
                        variant='contained'
                        sx={{ p: '4px', width: '150px' }}
                        onClick={handleSend}
                    >Бүртгүүлэх</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
