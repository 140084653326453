import { Box, Container, Divider, IconButton, Typography } from '@mui/material'
import { Facebook } from '@mui/icons-material'


const NavTop = () => {
    const handleClick = () => {
        window.open('https://www.facebook.com/infosystems.mn', '_blank')
    }

    return (
        <Box sx={{ width: '100%', backgroundColor: 'secondary.light' }}>
            <Container maxWidth='xl'>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pt: '2px' }}>
                    <Typography sx={{ fontSize: '14px' }}>Тавтай морил!</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mr: 2, alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '14px', mr: 1 }}>Холбоо барих: </Typography>
                            <Typography sx={{ fontSize: '14px' }}>70116399</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton sx={{ m: 0, p: 0 }} onClick={handleClick }>
                                <Facebook sx={{ mr: 1, fontSize: '18px', color: 'white' }} />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default NavTop