import Tabs from '@mui/material/Tabs'
import BranchMgrs from './BranchMgrs'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Branches from './Branches'
import * as React from 'react'

const tabs = [
    { label: 'Салбарууд', value: 1 },
    //{ label: 'Салбарын менежер', value: 2 },
]
export default function Branch() {
    const [value, setValue] = React.useState(1)
    const handleChange = (event, newValue) => { setValue(newValue) }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange}>
                    {tabs.map(t => <Tab key={`k${t.value}`} value={t.value} label={t.label} sx={{ borderBottom: 1 }} />)}
                </Tabs>
            </Box>
            { value === 1 ? <Branches /> : <BranchMgrs /> }
        </Box>
    )
}
