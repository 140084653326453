import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { primaryLight, secondaryLight } from '../../../../../utils/constants'
import { DateCell } from './Cells'


export default function OTable({ rows, cols, title }) {
    return (
        <Table sx={{ width: '50%', height: 'fit-content' }} size='small'>
            <TableHead>
                <TableCell colSpan={5} sx={{p: 0 }}>
                    <Typography 
                        sx={{ 
                            p: 2, pl: 0, fontSize: '16px', lineHeight: '20px',
                            fontWeight: 'bold', color: primaryLight 
                        }}
                    >{title}</Typography>
                </TableCell>
                <TableRow
                    sx={{
                        '& > th': { 
                            backgroundColor: secondaryLight, borderRight: '1px solid white', 
                            color: 'white', textAlign: 'center'
                        } 
                    }}
                >
                    { cols.map((c, idx) => <TableCell key={`key_${idx}`}>{c}</TableCell>) }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rows?.map((b, idx) => (
                        <TableRow 
                            sx={{
                                '&:hover': { backgroundColor: '#f3f3f3' },
                                '& > td': { 
                                    borderBottom: `1px solid ${secondaryLight}`, textAlign: 'center', 
                                    '&:hover': { backgroundColor: '#f5f5f5 !important' }
                                }
                            }}
                        >
                            <TableCell width='40px'>{idx + 1}</TableCell>
                            <TableCell>{b?.e}</TableCell>
                            <TableCell>{b?.p}</TableCell>
                            <TableCell>{b?.o}</TableCell>
                            <DateCell value={b?.cA} />
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )
}
