import { Height, South } from "@mui/icons-material";
import { Grid, IconButton, TableCell, Typography } from "@mui/material";

export default function HeadCell({ field, fieldName, handleOrder }) {
  return (
    <TableCell align={field?.align} width={field?.width} >
      <Grid container direction='row' sx={{ justifyContent: "center", alignItems: "center" }}>
        <Grid item>
          <Typography fontSize="13px" fontWeight="bold" >
            {field?.label}
          </Typography>
        </Grid>
        {fieldName === 'debt' || fieldName === 'orders_cnt' || fieldName === 'orders_sum' || fieldName === 'credit' || fieldName === 'created' ? (
          <Grid item>
            <IconButton
              onClick={() => handleOrder(fieldName)}
              sx={{ p: 0, textAlign: 'center' }}
            >
              {
                field?.order === ''
                  ? <Height sx={{ fontSize: '18px', color: 'white' }} />
                  : <South sx={{ fontSize: '18px', color: 'white', transform: field?.order === fieldName ? 'none' : 'rotateX(180deg)' }} />
              }
            </IconButton>
          </Grid>
        ) : ('')}

      </Grid>
    </TableCell>
  );
}
