import { TableCell, TableRow } from "@mui/material"
import { ITEM_PROCESS } from "../../../../utils"
import OrderItems from "./OrderItems"
import { useState } from "react"

const getDateTime = (dateTime) => {
    const parts = dateTime ? dateTime.split(' ') : null
    const t = parts?.length > 1 ? parts[1] : null
    const tps = t ? t.split(':') : null
    return tps?.length > 1 ? `${tps[0]} цаг ${tps[1]} минут` : null
}
let idxOf = 0
export default function ShipDetailRow({ order, isShipmentEnded, orderRowSpans, hover, setHover, branchOrderCnt }) {
    // orderer №
    const rowSpan = orderRowSpans[order.id]
    if (rowSpan) { idxOf = 0 } else { idxOf++ }

    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false) }

    return (
        <>
            <TableRow 
                sx={{ 
                    '& > td': {
                        p: '2px 5px',
                        textAlign: 'center',
                        bgcolor: hover === order.branchId ? '#f1f1f1' : 'initial',
                    },
                }}
                onMouseEnter={() => { setHover(order.branchId) }}
                onMouseLeave={() => { setHover(null) }}
            >
                {
                    rowSpan &&
                    <TableCell 
                        sx={{ 
                            borderRight: 1, 
                            borderRightColor: '#e0e0e0',
                            bgcolor: hover === order.branchId ? '#f1f1f1' : 'initial'
                        }}
                        rowSpan={rowSpan}
                    >{Object.keys(branchOrderCnt).indexOf(order.branchId.toString())+1}</TableCell>
                }
                {
                    rowSpan && 
                    <TableCell
                        sx={{ 
                            borderRight: 1, 
                            borderRightColor: '#e0e0e0'
                        }}
                        rowSpan={rowSpan}
                    >{`${order.user} (${order.branch})`}</TableCell>
                }
                <TableCell>{idxOf+1}</TableCell>
                <TableCell
                    onClick={() => setOpen(true)} 
                    sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline', 
                        '&:hover': { bgcolor: '#e6e6e6' }
                    }}
                >{order.orderNo}</TableCell>
                <TableCell>{ITEM_PROCESS[order.process]||'-'}</TableCell>
                <TableCell>{getDateTime(order.givenOn)||'-'}</TableCell>
                <TableCell>{order.note||'-'}</TableCell>
            </TableRow>
            {
                open &&
                <OrderItems
                    items={order.items} 
                    disabled={order.process === 'A'||isShipmentEnded}
                    handleClose={handleClose}
                />
            }
        </>
    )
}
