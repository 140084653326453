import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Close, ShoppingCart } from '@mui/icons-material'
import { useAuthCtx } from '../../../../context/AuthCtx'
import { toPrice } from '../../../../utils/functions'
import { useAddToCart } from '../../../hooks'
import { getImageUrl } from '../../../utils'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import { useState } from 'react'


const width = '120px'

function Msg ({msg}) {
    return <Typography sx={{ fontSize: '13px' }}>{msg}</Typography>
}

export default function ProductBox({ row, setResults, setValue }) {
    const { setAddAlert } = useAuthCtx()
    const navigate = useNavigate()
    const goToDetail = () => {
        navigate(`/products/${row?.id}`)
        setResults([])
        setValue('')
    }

    const [isCartShow, setIsCartShow] = useState(false)

    const [ handleAddItem, alertMsg, alertOff, isSuccess, isIncluded, msgClr ] = useAddToCart({ product: row })
    const handleClick = (e) => {
        e.stopPropagation()
        handleAddItem()
    }
    useEffect(() => { if (alertMsg) { setAddAlert({ alertMsg, msgClr, alertOff, fromSearch: true }) } }, [alertMsg])

    return (
        <Box 
            sx={{
                boxShadow: 3,
                position: 'relative',
                border: '1px solid', 
                borderColor: 'transparent', 
                '&:hover': { cursor: 'pointer', borderColor: 'secondary.light' },
                transition: 'border 0.15s ease-in-out'
            }}
            onClick={ goToDetail }
            onMouseEnter={() => { setIsCartShow(true) }}
            onMouseLeave={() => { setIsCartShow(false) }}
        >
            {
                row &&
                <Tooltip 
                    title={
                        isIncluded 
                            ?   <Msg msg='Сагснаас хасах' />
                            :   <Msg msg='Сагсанд нэмэх' />
                    } 
                    placement='right'
                >
                    <IconButton 
                        sx={{ 
                            position: 'absolute', 
                            top: -8, left: -8, p: '5px', 
                            backgroundColor: isIncluded ? 'secondary.light' : 'primary.light', 
                            '&:hover': { backgroundColor:  isIncluded ? 'secondary.light' : 'primary.light' }, 
                            visibility: isCartShow ? 'visible' : 'hidden',
                            border: '1px solid',
                            borderColor: 'secondary.light',
                            boxShadow: 3,
                            transition: 'visibility 0.3s linear 0'
                        }}
                        onClick={ handleClick }
                    >
                        { Boolean(alertMsg) ? <Close sx={{ color: 'white' }} /> : <ShoppingCart sx={{ color: 'white' }} /> }
                    </IconButton>
                </Tooltip>
            }
            <img src={row?.image ? getImageUrl(row.image, 150) : require(`../noimage_small.jpg`)} style={{ width, height: width }} />
            <Box sx={{ minWidth: width, width }}>
                <Typography textAlign='center' fontSize='13.5px' fontWeight='bold'>{row.name}</Typography>
            </Box>
            <Typography textAlign='center' fontSize='13.5px'>{toPrice(row.price)}</Typography>
        </Box>
    )
}
