import { primaryLight, secondaryLight } from '../../../../../../utils/constants'
import { TableCell, TableRow, Tooltip } from '@mui/material'
import { PROMO_TYPES } from '../../../../../constants'
import { FilterAlt } from '@mui/icons-material'
import CharFilterCell from './CharFilterCell'
import { useEffect, useState } from 'react'
import DropCell from './DropCell'

export default function Filter({ filters, setFilters, hhRef }) {
    const [top, setTop] = useState(hhRef?.current?.getBoundingClientRect()?.height)
    useEffect(() => {
        const newTop = hhRef?.current?.getBoundingClientRect()?.height
        if (newTop) { setTop(newTop) }
    }, [hhRef])

    return (
        <TableRow
            sx={{
                '& > td': {
                    m: 0, 
                    p: 0, 
                    px: '2px',
                    textAlign: 'center',
                    borderBottom: '1px solid gray', 
                    zIndex: 1050, top,
                    borderBottomColor: primaryLight, 
                    position: 'sticky', 
                    backgroundColor: 'white',
                    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
                }
            }}
        >
            <TableCell>
                <Tooltip title='Захиалгын шүүлтүүр' placement='right'><FilterAlt sx={{ color: secondaryLight }} /></Tooltip>
            </TableCell>
            <CharFilterCell setFilters={setFilters} field='name' />
            <CharFilterCell setFilters={setFilters} field='code' />
            <DropCell field={'promo_type'} choices={PROMO_TYPES} filters={filters} setFilters={setFilters} />
            <TableCell />
            <TableCell />
            <TableCell />
        </TableRow>
    )
}
