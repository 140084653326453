import { toPrice } from '../../../../../utils/functions'
import { Box, Typography } from '@mui/material'


export default function Price({ price, sale_price }) {
    const sp = (!isNaN(sale_price) && parseFloat(sale_price) > 0.00) ? parseFloat(sale_price) : null
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-between', 
                p: '2px',
                background: 'rgb(102,0,0)',
                background: 'linear-gradient(90deg, rgba(102,0,0,1) 30%, rgba(255,255,255,1) 100%)'
            }}
        >
            {
                sp && <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', borderRight: '1px solid white', p: 1 }}>
                            <Typography fontSize='13.5px' textAlign='center' sx={{ color: 'white' }}>Бөөний үнэ</Typography>
                            <Typography fontSize='20px' textAlign='center' sx={{ color: 'white', fontWeight: 700 }}>{toPrice(sp)}</Typography>
                        </Box>
            }
            <Box sx={{ display: 'flex', flexDirection: sale_price ? 'column' : 'row', p: 1, alignItems: sale_price ? 'initial' : 'center', mr: 2 }}>
                <Typography fontSize='13.5px' textAlign='right' sx={{ color: !sp ? 'white' : 'black', mr: 1 }}>{`${sp ? 'Үндсэн үнэ: ' : 'Үнэ: '}`}</Typography>
                <Typography fontSize='20px' textAlign='center' sx={{ color: !sp ? 'white' : 'primary', fontWeight: 700 }}>
                    {toPrice(price)}
                </Typography>
            </Box>
        </Box>
    )
}