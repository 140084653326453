import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'

export default function Popup ({ orderer, status, orders }) {
    return (
        <Box 
            sx={{
                position: 'absolute',
                bottom: 50,
                right: -200,
                borderRadius: '5px',
                border: '1px solid #ccc',
                backgroundColor: 'white',
                boxShadow: '0 2px 10px #000',
                width: '200px',
                p: '5px',
            }}
            
        >
            <Typography sx={{ fontSize: '13px', mb: '5px', fontWeight: 'bold' }} noWrap>{orderer||'-'}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Table sx={{ width: '200px', mb: '5px' }}>
                    <TableBody sx={{ '& > tr > td': { p: '2px', textAlign: 'left', fontSize: '12px' } }}>
                        <TableRow>
                            <TableCell>Төлөв:</TableCell>
                            <TableCell sx={{ px: '0px !important' }}>{status}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Захиалга:</TableCell>
                            <TableCell sx={{ px: '0px !important' }}>{orders?.join(', ')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </Box>
    )
}