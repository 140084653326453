import { addCategory, getCategoriesFlatten, removeCategory, updateCategory, updateCategoryBulk } from '../../../../api/category'
import { Box, CssBaseline, IconButton, TextField, Tooltip } from "@mui/material"
import { getToast } from "../../../auth/helpers/ToastAlert"
import SaveTwoTone from '@mui/icons-material/SaveTwoTone'
import { CustomDragPreview } from "./CustomDragPreview"
import { Tree } from "@minoru/react-dnd-treeview"
import { useEffect, useState } from "react"
import SampleData from "./sample_data.json"
import { CustomNode } from "./CustomNode"
import { useQuery } from "react-query"
import styles from "./App.module.css"

export const categoryItemWidth = '450px'

export default function Categories() {
	const { data: categoryData, refetch } = useQuery('get_categories', getCategoriesFlatten)
	const categories = categoryData?.data ? categoryData?.data : []
	const [newMainCategoryName, setNewMainCategoryName] = useState('')

	const [msg, setMsg] = useState(null)
	const msgOff = () => { setMsg(null) }
	const addNewMainCategory = () => {
		addCategory({ 'name': newMainCategoryName, 'parent': null })
			.then(_ => {
				refetch()
				setNewMainCategoryName('')
				setMsg({ m: 'Амжилттай!', s: 'success' })
			})
			.catch(
				e => {
					if (e?.response?.data?.non_field_errors?.[0]?.startsWith('The fields name, supplier')) {
						setMsg({ m: 'Ангиллын нэр давхцаж байна!', s: 'warning' })
					} else { setMsg({ m: 'Алдаа гарлаа!', s: 'warning' }) }
				}
			)
	}

	const handleKeyDown = (e) => { if (e.code === 'Enter') { addNewMainCategory() } }

	// drag & drop
	const [treeData, setTreeData] = useState(SampleData);
	const handleDrop = (newTree) => {
		handleEditBulk(newTree)
		setTreeData(newTree)
	}
	const handleTextChange = (id, value, type) => {
		if (type === 'edit') { handleEdit(id, value) } 
		else { handleDelete(id) }
		const newTree = treeData.map((node) => {
			if (node.id === id) {
				return {
					...node,
					text: value
				}
			}
			return node
		})
		setTreeData(newTree)
	}
	const handleEdit = (id, value) => {
		updateCategory(id, { name: value })
			.then(_ => {
				setMsg({ m: 'Амжилттай!', s: 'success' })
			})
			.catch(_ => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) })
	}
	const handleDelete = (id) => {
		removeCategory(id)
			.then(res => {
				setMsg({ m: 'Амжилттай!', s: 'success' });
				refetch();
			})
			.catch(e => setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }))
	}
	const handleEditBulk = (datas) => {
		let temps = [];
		for (let i = 0; i < datas.length; i++) {
			let changed = null;
			if (datas[i].parent === 0) {
				changed = {
					id: datas[i].id,
					parent: null,
					droppable: true,
					text: datas[i].name,
				}
			} else {
				changed = {
					id: datas[i].id,
					parent: datas[i].parent,
					droppable: true,
					text: datas[i].name,
				}
			}
			if (changed != null)
				temps.push(changed);
		}
		updateCategoryBulk(temps)
			.then(_ => {
				setMsg({ m: 'Амжилттай!', s: 'success' });
				refetch();
			})
			.catch((error) => {
				console.log(error);
				setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
			})
	}
	const getChilds1 = (arrayOfObjects) => {
		console.log(arrayOfObjects);
		let dd = [];
		arrayOfObjects.map(obj => {
			let changed = null;
			if (obj.parent != null) {
				changed = {
					id: obj.id,
					parent: obj.parent,
					droppable: true,
					text: obj.name,
				}
			} else {
				changed = {
					id: obj.id,
					parent: 0,
					droppable: true,
					text: obj.name
				}
			}
			if (changed != null) {
				dd.push(changed);
			}
		})
		setTreeData(dd);
	}
	useEffect(() => {
		if (categories.length > 0) {
			getChilds1(categories);
		}
	}, [categories]);

	return (
		<>
			{getToast(Boolean(msg), msgOff, msg?.m, msg?.s, 1500)}
			<Box sx={{ position: 'relative', width: categoryItemWidth, mb: 1, ml: 5, mt: 3 }}>
				<TextField
					size='small'
					label='Үндсэн ангилал нэмэх'
					value={newMainCategoryName}
					onChange={(e) => setNewMainCategoryName(e.target.value)}
					sx={{ width: '100%' }}
					onKeyDown={(e) => handleKeyDown(e)}
					inputProps={{ style: { fontSize: '13px', padding: '4px' } }}
					InputLabelProps={{ style: { fontSize: '13px', top: -5 } }}
				/>
				<IconButton onClick={addNewMainCategory} sx={{ position: 'absolute', p: '5px', mr: 2, top: 0, right: 0 }}>
					<Tooltip title='Хадгалах' placement='top-start' arrow>
						<SaveTwoTone sx={{ color: 'secondary.main', fontSize: '20px' }} />
					</Tooltip>
				</IconButton>
			</Box>
			<>
				<CssBaseline />
				<div className={styles.app}>
					<Tree
						tree={treeData}
						rootId={0}
						render={(node, { depth, isOpen, onToggle }) => (
							<CustomNode
								node={node}
								depth={depth}
								isOpen={isOpen}
								onToggle={onToggle}
								onTextChange={handleTextChange}
								handleDelete={handleDelete}
							/>
						)}
						dragPreviewRender={(monitorProps) => (
							<CustomDragPreview monitorProps={monitorProps} />
						)}
						onDrop={handleDrop}
						classes={{
							root: styles.treeRoot,
							draggingSource: styles.draggingSource,
							dropTarget: styles.dropTarget
						}}
						sort={false}
					/>
				</div>
			</>
		</>
	)
}

// for categories
const getChildIds = (arrayOfObjects, arr) => {
	arrayOfObjects.forEach(obj => {
		arr.push(obj.id)
		if (obj?.children?.length > 0) {
			getChildIds(obj.children, arr)
		}
	})
	return arr
}
const getChilds = (arrayOfObjects) => {
	return arrayOfObjects.map(obj => {
		let changed = {
			...obj,
			childs: getChildIds(obj.children, []),
			children: getChilds(obj.children)
		}
		return changed
	})
}