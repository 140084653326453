import { Box, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isEmpty, isObject, keys } from 'underscore'
import { rq } from '../../../../../../api/company'
import PasswordField from '../../../../../helpers/fields/PasswordField'
import { paperStyle } from '../../../../../../utils/constants'
import { getToast } from '../../../../../auth/helpers/ToastAlert'


const validationSchema = Yup.object({
    usr: Yup.string().max(255, 'username хэт урт байна!').required('usr оруулна уу!'),
    pwd: Yup.string().max(255, 'password хэт урт байна!').required('pwd оруулна уу!'),
    invCode: Yup.string().max(255, 'invoice_code хэт урт байна!').required('invCode оруулна уу!')
})

export default function Cred({ cur }) {
    const [msg, setMsg] = useState(null)
    const [warning, setWarning] = useState(false)
    const msgOff = () => { setMsg(null); setWarning(false) }
    const [wait, setWait] = useState(null)
    const waitOff = () => { setWait(null) }
    
    const onSubmit = (vals) => {
        rq(vals)
            .then(res => {
                const m = res?.data
                if (m === 'cre') { setMsg('Qpay-г амжилттай холболоо!') }
                if (m === 'upd') { setMsg('Мэдээлэл амжилттай шинэчлэгдлээ!') }
                setWarning(false)
            })
            .catch(e => {
                const m = e?.response?.data
                const s = e?.response?.status
                if (s === 400 || s === 429) {
                    if (m === 'up') { setMsg('username эсвэл password буруу байна!') }
                    if (m === 'i') { setMsg('invoice_code буруу байна!') }
                    if (s === 429) { 
                        setMsg('Түр хүлээгээд дахин оролдоно уу!')
                        if (m && isObject(m)) {
                            const sec = m?.detail?.split('in ')[1]?.split(' seconds')[0]
                            if (!isNaN(+sec)) { setWait(+sec); setTimeout(() => { waitOff() }, +sec * 1000) }
                        }
                    }
                    setWarning(true)
                }
            })
    }

    const { handleSubmit, getFieldProps, errors, touched } = useFormik({
        initialValues: cur,
        onSubmit,
        validateOnChange: false,
        validationSchema
    })

    const getErrors = (name) => {
        const isErr = keys(errors)?.includes(name) && keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    const dis = Boolean(msg)||Boolean(wait)
    const btnDsb = Boolean(msg)||Boolean(wait)||isEmpty(touched)

    //console.log("T: ", touched)

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, ...paperStyle, p: 3  }}>
            { getToast(Boolean(msg), msgOff, msg, warning ? 'warning' : 'success', 2500) }
            <Box sx={{ minWidth: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignContent: 'flex-start', mt: 2, mb: 2 }}>
                <TextField
                    label='username'
                    name='usr'
                    {...getFieldProps('usr')}
                    {...getErrors('usr')}
                    size='small'
                    disabled={dis}
                    />
                <PasswordField
                    label='password'
                    name='pwd'
                    {...getFieldProps('pwd')}
                    {...getErrors('pwd')}
                    size='small'
                    autoComplete='new-password'
                    disabled={dis}
                    />
                <TextField
                    label='invoice_code'
                    name='invCode'
                    {...getFieldProps('invCode')}
                    {...getErrors('invCode')}
                    size='small'
                    autoComplete='off'
                    disabled={dis}
                />
                <Button 
                    size='small' variant='contained' 
                    disabled={btnDsb} onClick={handleSubmit} 
                    sx={{ p:'7px', cursor: 'pointer' }}
                >Хадгалах</Button>
            </Box>
        </Box>
    )
}
