import { Box, Typography } from '@mui/material'


export default function EndDate({ endDate }) {
    return (
        <Box sx={{  display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: 1, boxShadow: 5, p: 2, width: '500px', mb: 5 }}>
            <Typography sx={{ color: 'primary.main', ml: 1 }}>Урамшуулал дуусах хугацаа:</Typography>
            <Typography sx={{ color: 'primary.main', ml: 1, color: 'secondary.main', fontWeight: 500, fontSize: '20px' }}>{endDate?.split(' ')?.[0]}</Typography>
        </Box>
    )
}
