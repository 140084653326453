import { Box, Typography } from '@mui/material'
import LocationLabel from '..'

export default function Orderer({ orderer, ordersLen }) {
    return (
        <LocationLabel>
            <Box sx={{ display: 'flex', flexDirection: 'row', px: 1 }}>
                <Typography sx={{ fontSize: '13px', mr: '5px' }} noWrap>{orderer}</Typography>
                <Typography sx={{ fontSize: '13px', fontWeight: 600, color: 'secondary.light' }}>{`(${ordersLen})`}</Typography>
            </Box>
        </LocationLabel>
    )
}