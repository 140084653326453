import { APILoadingStatus, APIProvider, Map, useApiLoadingStatus } from '@vis.gl/react-google-maps'
import { Box, Button, TextField, Typography } from '@mui/material'
import { getAddressByCoords } from '../../../../api/google_map'
import { mapOptions, maxBounds } from '../../../utils'
import { getToast } from '../../helpers/ToastAlert'
import { useEffect, useState } from 'react'
import { isEmpty } from 'underscore'
import loc from './loc.png'
import * as Yup from 'yup'

const matchTypes = ['street_address', 'route', 'administrative_area_level_2', 'administrative_area_level_1', 'country']
const latSchema = Yup.number().min(-90, 'Байршлын координат буруу!').max(90, 'Байршлын координат буруу!')
const lngSchema = Yup.number().min(-180, 'Байршлын координат буруу!').max(180, 'Байршлын координат буруу!')

const boundRestriction = {
    restriction: {
        latLngBounds: maxBounds,
        strictBounds: true
    },
}

export default function GetLocation ({ addressInfo, setAddressInfo, setIsApiFailed, setIsGettingAddress }) {
    const [center, setCenter] = useState({ lat: 47.920761, lng: 106.917275 })
    // handle address or coords
    const [locAddress, setLocAddress] = useState()
    const handleGetAddress = () => {
        setIsGettingAddress(true)
        getAddressByCoords(center)
            .then(res => {
                const address2 = res?.data?.results?.find(r => matchTypes.find(w => r?.types?.includes(w)))?.formatted_address
                setLocAddress(address2)
                const { lat, lng } = center
                setAddressInfo({ lat: lat?.toFixed(6), lng: lng?.toFixed(6), address2 })
                setIsGettingAddress(false)
            })
            .catch(e => {
                console.log("ERR: ", e)
                setIsGettingAddress(false)
            })
    }

    const apiStatus = useApiLoadingStatus()
    const [alert, setAlert] = useState(null)    
    useEffect(() => {
        if ([
            APILoadingStatus.FAILED, 
            APILoadingStatus.AUTH_FAILURE].includes(apiStatus))
            { setIsApiFailed(true) }
        if (addressInfo?.lat && addressInfo?.lng) {
            const lat = parseFloat(addressInfo?.lat)
            const lng = parseFloat(addressInfo?.lng)
            if (latSchema.isValidSync(lat) && lngSchema.isValidSync(lng)) {
                setCenter({ lat, lng })
                if (addressInfo?.address2) { setLocAddress(addressInfo?.address2) }
            } else { setAlert({m: 'Байршлын координат буруу!', s: 'warning'}); console.log("WRONG") }
        }
    }, [])

    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} region='MN'>
            <Box>
                <Typography fontSize={'13px'} sx={{ color: 'primary.light', mb: 1, textAlign: 'center' }}>Газрын зургийг чирж хаяг дээрээ очсны дараа 'Байршил сонгох' дээр дарна.</Typography>
                {getToast(Boolean(!isEmpty(alert)), () => setAlert(null), alert?.m, alert?.s)}
                <Box sx={{ width: '100%', height: '350px', border: 1, borderColor: '#c4c4c4', borderRadius: 1 }}>
                    <Map
                        reuseMaps={true}
                        defaultCenter={center}
                        onZoomChanged={(e) => {
                            const center = e?.detail?.center
                            if (center) { setCenter(center)}
                        }}
                        onBoundsChanged={(e) => {
                            const center = e?.detail?.center
                            if (center) { setCenter(center)}
                        }}
                        defaultZoom={13}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        minZoom={6}
                        maxZoom={18}
                        mapId={process.env.REACT_APP_GOOGLE_MAP_ID2}
                        options={{ ...mapOptions, ...boundRestriction }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',  
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 10,
                            }}
                        >
                            <img src={loc} alt='loc.png' style={{ width: '40px', height: '40px' }} />
                        </div>
                        <Button
                            sx={{
                                position: 'absolute',
                                top: '2px',
                                right: '50%',
                                transform: 'translate(50%, 0)',
                                zIndex: 10,
                                color: 'black',
                                boxShadow: '0 0 5px 0 rgba(0,0,0,0.2)',
                                p: '2px 6px',
                                border: '2px solid rgba(0,0,0,0.1)',
                                fontWeight: 'bolder',
                                cursor: 'pointer',
                                backgroundColor: 'white',
                                '&:hover': { color: 'green', backgroundColor: 'white' }
                            }}
                            onClick={handleGetAddress}
                            size='small'
                        >Байршил сонгох</Button>
                    </Map>
                </Box>
                <TextField
                    label='Хаягийн дэлгэрэнгүй'
                    multiline
                    rows={2}
                    size='small'
                    inputProps={{ style: { fontSize: '15px', maxHeight: '150px', overflowY: 'hidden' } }}
                    InputLabelProps={{ style: { fontSize: '15px' }, shrink: Boolean(locAddress) }}
                    InputProps={{ readOnly: true }}
                    value={locAddress}
                    sx={{ width: '100%', mb: 1, mt: 2 }}
                />
            </Box>
        </APIProvider>
    )
}

