import DeleteBtn from '../../../../BaskTemps/DeleteBtn'
import CustomTextField from '../CustomTextField'
import { Grid } from '@mui/material'
import { keys } from 'underscore'


export default function Manager({ getFieldProps, errors, setFieldValue, isUpdate }) {
    const getErrors = (name) => {
        const isErr = keys(errors)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }

    const handleRemoveMngr = () => {
        if (isUpdate) { 
            setFieldValue('manager.name', '') 
            setFieldValue('manager.email', '') 
            setFieldValue('manager.phone', '') 
        }
    }
    
    return (
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <CustomTextField
                        label='Нэр'
                        name='manager.name'
                        {...getFieldProps('manager.name')}
                        {...getErrors('name')}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CustomTextField
                        label='Имэйл хаяг'
                        name='manager.email'
                        {...getFieldProps('manager.email')}
                        {...getErrors('email')}
                    />
                </Grid>
                <Grid item xs={isUpdate ? 3 : 4}>
                    <CustomTextField
                        label='Утасны дугаар'
                        name='manager.phone'
                        {...getFieldProps('manager.phone')}
                        {...getErrors('phone')}
                    />
                </Grid>
                {isUpdate && <Grid item xs={1} sx={{ mt: '-8px', }}>
                    <DeleteBtn onYes={handleRemoveMngr} />
                </Grid>}
            </Grid>
    )
}
