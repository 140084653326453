import { Tab, Table, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { keys } from 'underscore'
import { drawerWidth, scrollStyle, secondaryLight } from '../../../../utils/constants'
import TBody from './TBody'
const cols = [
    "№", "Имэйл", "Утас", "Нийлүүлэгчийн ID",
    "Компаний нэр", "Компаний РД",
    "Төрөл", "Хаяг", "Тусгай зөвшөөрөл", "ТЗ дуусах хугацаа",
    "MACS url", "Баталгаажсан?", "Огноо"
]
export const cTypes = {
    S: 'Нийлүүлэгч',
    P: 'Эмийн сан'
}
const barHeight = 64

const tabs = {
    0: { name: 'Хүсэлт илгээсэн', path: '/confirmation/main' },
    1: { name: 'Бүртгэлтэй', path: '/confirmation/main/list' }
}

export default function Companies() {
    const wih = window.innerHeight
    const wiw = window.innerWidth
    const maxHeight = wih - 16 - barHeight
    const maxWidth = wiw - drawerWidth

    // tab
    const [tab, setTab] = useState(0)
    const navi = useNavigate()
    const handleTab = (e, idx) => { setTab(idx); navi(tabs[idx].path) }


    return (
        <>
            <Tabs value={tab} onChange={handleTab} sx={{ "& .MuiTab-root": { px: 1, py: 0, paddingTop: 0, paddingBottom: 0 } }}>
                {keys(tabs).map(k => <Tab key={`${k}`} label={tabs[k].name} value={k} />)}
            </Tabs>
            <TableContainer sx={{ maxHeight, width: '100%', maxWidth, ...scrollStyle, mt: 1 }}>
                <Table aria-label="simple table" stickyHeader sx={{ pb: 10, width: '100%' }} size='small'>
                    <TableHead>
                        <TableRow
                            sx={{
                                width: '100%',
                                '& > th': {
                                    backgroundColor: secondaryLight, borderRight: '1px solid white',
                                    color: 'white', textAlign: 'center'
                                }
                            }}
                        >
                            {cols.map((c, idx) => <TableCell key={`key_${idx}`}>{c}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TBody colSpan={cols.length} />
                    {/* <Outlet /> */}
                </Table>
            </TableContainer>
        </>
    )
}