import { getCategories } from '../../../../../../../../../../api/category'
import { getChilds } from '../../../../../../../../../home/Main/utils'
import CategoryItem from './CategoryList/CategoryItem'
import { useAdminCtx } from '../../../../../../../..'
import { useProductListCtx } from '../../../../..'
import { useEffect, useMemo } from 'react'
import CategoryList from './CategoryList'
import { useQuery } from 'react-query'
import { Box } from '@mui/material'

const NoCategory = ({ onSelect }) => <CategoryItem menuItem={{ id: 'Бусад', name: 'Бусад' }} onSelect={onSelect} />

export default function Category({ setFilters, toClose, setSelCatName }) {
    // fetch categories and save to context
    const { catData, setCatData } = useProductListCtx()
    const { alertWarning } = useAdminCtx()
    const { data, isFetched, error } = useQuery('get_categories', getCategories, { onError: (e) => {}, enabled: catData?.length === 0, retry: false })
    useEffect(() => { if (error) { alertWarning(); toClose() } }, [error])
    const fetchedCats = useMemo(() => { return data?.data?.length ? getChilds(data?.data) : [] }, [data])
    const categories = catData?.length ? catData : fetchedCats
    useEffect(() => {
        if (isFetched && fetchedCats?.length) { setCatData(fetchedCats) }
    }, [isFetched, fetchedCats])
    //console.log("cat: ", categories, isFetched, isFetching)

    const handleSel = (category) => {
        const isNull = category?.id === 'Бусад'
        setFilters(prev => ([
            ...prev.filter(p => p.k !== 'category'),
            { k: 'category', v: isNull ? 'true': category.id, o: isNull ? '__isnull=' : '=' }
        ]))
        setSelCatName(category.name)
        toClose()
    }

    if (!isFetched||categories?.length === 0) { return null }

    return (
        <Box
            sx={{
                backgroundColor: '#fff', width: '250px',
                borderRadius: 2, backgroundColor: 'white',
                m: 1, mt: 0, p: 2,
            }}
        >
            <CategoryList menus={categories} onSelect={handleSel} />
            <NoCategory onSelect={handleSel} />
        </Box>
    )
}
