import { Box, Typography } from '@mui/material'

import Item from './Item/Item'

export default function Items({ notifs, updateNotifs, setCnt }) {
    return (
        <Box
            sx={{ 
                width: '350px', maxHeight: '400px', 
                overflowY: 'auto', backgroundColor: 'white', 
                boxShadow: 8, p: '4px', pl: 1, pr: 1, zIndex: 1600,
                '&::-webkit-scrollbar': { width: 6 },
                '&::-webkit-scrollbar-track': { backgroundColor: '#e1e1e1' },
                '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.light' },
                '&::-webkit-scrollbar-thumb:hover': { backgroundColor: 'secondary.main' },
            }}
        >
            {
                notifs?.length  
                    ?   <Box>
                            {
                                notifs?.map((notif, index) => {
                                    return <Item key={`key_${index}`} notif={notif} updateNotifs={updateNotifs} setCnt={setCnt} />
                                })
                            }
                        </Box>
                    :   <Box 
                            sx={{ 
                                display: 'flex', 
                                justifyContent: 'center', 
                                borderBottom: '1px solid', 
                                borderBottomColor: 'secondary.light',
                                backgroundColor: '#f1f1f1'
                            }}
                        >
                            <Typography
                                width='100%'
                                textAlign='center'
                                fontSize='14px'
                                color='primary.main'
                                p={1}
                                sx={{ mb: 1 }}
                            >
                                Одоогоор мэдэгдэл байхгүй байна!
                            </Typography>
                        </Box> 
            }
        </Box>
        
    )
}
