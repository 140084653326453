import { Dialog, DialogContent, Link, TableCell } from '@mui/material'
import { secondaryLight } from '../../../../../../../utils/constants'
import { getOrderNote } from '../../../../../../../api/order'
import { useEffect, useState } from 'react'

export default function NoteCell({ hasNote, orderId }) {
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [note, setNote] = useState(null)
    useEffect(() => {
        if (open && !note) {
            getOrderNote(orderId)
                .then(res => { setNote(res.data?.note) })
                .catch(err => { console.log("ERR: ", err) })
        }
    }, [open])

    if (!hasNote) { return <TableCell>-</TableCell>}

    return (
        <TableCell align='center'>
            <Link
                href='#' onClick={handleOpen}
                sx={{ fontSize: '13px', fontWeight: 'bold', color: secondaryLight }}
            >Тайлбар</Link>
            <Dialog open={Boolean(note && open)} onClose={handleClose}>
                <DialogContent>
                    {note}
                </DialogContent>
            </Dialog>
        </TableCell>
    )
}
