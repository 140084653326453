import AxiosWithToken from "../global/axiosBase"

// subscribe
export const subPush = (data) => {
    return AxiosWithToken.post('sub_push/', { ...data })
}

// notif
// get notifs
export const getNotifs = () => {
    return AxiosWithToken.get('notifs/')
}
// remove notif
export const removeNotif = (nId) => {
    return AxiosWithToken.delete('notifs/', { nId })
}