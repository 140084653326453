import { Checkbox, TableCell, Tooltip } from "@mui/material"
import { usePromoCtx } from "../.."

export const ChoiceCell = ({ current, choices }) => <TableCell>{choices?.find(c => c?.value === current)?.label||'-'}</TableCell>

export function ActivateCell({ id, isActive }) {
    const { activate } = usePromoCtx()
    const handleChange = () => { activate(id, !isActive) }

    return (
        <TableCell>
            <Tooltip title={isActive ? 'Идэвхгүй болгох': 'Идэвхжүүлэх'} placement="right">
                <Checkbox
                    checked={isActive}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    size='small'
                />
            </Tooltip>
        </TableCell>
    )
}

export function MarkCell({ id, isMarked }) {
    const { toMark } = usePromoCtx()
    const handleChange = () => { toMark(id, !isMarked) }

    return (
        <TableCell>
            <Tooltip title={isMarked ? 'Онцлох биш болгох': 'Онцлох'} placement="right">
                <Checkbox
                    checked={isMarked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    size='small'
                />
            </Tooltip>
        </TableCell>
    )
}
