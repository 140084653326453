import { Box, Container, Typography } from '@mui/material'
import Footer from './Footer'
import Header from './Header'

export default function PrivacyPolicy() {
    return (
        <Box>
            <Header />
            <Container sx={{ mt: 13, pb: 3 }} maxWidth='md'>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography variant='body2'>pharma.mn сайтын нууцлалын бодлого</Typography>
                    <Typography variant='body2'>Хүчин төгөлдөр болсон огноо: 2024.9.10</Typography>
                    <Typography variant='body2'>Аж ахуйн нэгжийн нэр: Инфо-Системс ХХК</Typography>
                </Box>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '18px', textAlign: 'center' }}>Нууцлалын бодлого</Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>1. Танилцуулга</Typography>
                <Typography sx={{ fontSize: '15px' }}>Энэхүү Нууцлалын бодлого нь Инфо-Системс ХХК-ийн удирддаг Pharmo.mn програмд ​​хамаарна. Энэ нь бид хэрэглэгчийн хувийн болон нууц мэдээллийг хэрхэн цуглуулж, ашиглаж, хуваалцдаг талаар тайлбарладаг. Бидний зорилго бол манай үйлчилгээнд нэвтрэхдээ ил тод байдал, хэрэглэгчийн нууцлалыг хангах явдал юм. Хэрэв танд асуух зүйл байвал pharmo2023@gmail.com хаягаар бидэнтэй холбогдоно уу.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>2. Бидний цуглуулдаг өгөгдөл</Typography>
                <Typography sx={{ fontSize: '14x' }}>Pharmo.mn нь дараах төрлийн хувийн болон эмзэг хэрэглэгчийн мэдээллийг цуглуулдаг.
                </Typography>
                <ol>
                    <li>Хувийн мэдээлэл: Нэр, имэйл хаяг, утасны дугаар, хүргэх хаяг.</li>
                    <li>Дансны өгөгдөл: Имэйл хаяг, нууц үг (шифрлэгдсэн).</li>
                    <li>Захиалгын мэдээлэл: Захиалсан бүтээгдэхүүн, захиалгын түүх, сонголт.</li>
                    <li>Байршлын суурь өгөгдөл: Хэрэв та зөвшөөрөл өгвөл бид байршилд суурилсан үйлчилгээ, тухайлбал хүргэлтийг хянах, ойролцоох эмийн сангийн үйлчилгээг санал болгохын тулд байршлын мэдээллийг далд хэлбэрээр цуглуулдаг.</li>
                    <li>Зөвшөөрөлтэйгөөр бид нийцтэй байдал, аюулгүй байдал болон ажиллагааг сайжруулахын тулд таны төхөөрөмж дээр суулгасан програмуудын мэдээллийг цуглуулдаг.</li>
                </ol>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>3.  Хэрэглэгчийн мэдээллийг хэрхэн ашиглах вэ?</Typography>
                <Typography>Pharmo.mn цуглуулсан мэдээллийг дараах зорилгоор ашигладаг.</Typography>
                <ol>
                    <li>Захиалга боловсруулах, хүргэх.</li>
                    <li>Байршилд суурилсан үйлчилгээ үзүүлэх (жишээ нь, түгээлтийг хянах).</li>
                    <li>Суулгасан програмын мэдээлэлд хандах замаар програмын нийцтэй байдал, аюулгүй байдлыг баталгаажуулна.</li>
                    <li>Таны захиалга, шинэчлэлт, саналын талаар тантай холбоо барина.</li>
                    <li>Хүргэлтийн үйлчилгээ үзүүлэгч эсвэл хуульд заасан нөхцөлөөс бусад тохиолдолд бид таны хувийн болон нууц мэдээллийг гуравдагч этгээдэд зарахгүй, хуваалцахгүй.</li>
                </ol>
                <Typography>Байршлын мэдээлэл цуглуулах зорилго:</Typography>
                <ol>
                    <li>
                    Pharmo систем нь түгээлтийн явцын хурдан шуурхай, үр дүнтэй байдлыг хангах зорилгоор байршлын мэдээллийг цуглуулдаг. Энэ өгөгдөл нь зөвхөн нийлүүлэгч талын ажилтнууд болох борлуулагч болон түгээгчийн ажлыг хянах, захиалгын явцын өөрчлөлтийг цаг тухайд нь хүлээн авах боломжийг хангахад зайлшгүй шаардлагатай. Бодит цагийн (realtime) байршлын мэдээллийг хуваалцах замаар бид түгээлтийн үйл явцын уялдаа холбоо, ил тод, түргэн шуурхай байдлыг сайжруулахыг зорьж байна.
                    </li>
                </ol>
                <Typography>Байршлын мэдээллийг дараах байдлаар цуглуулж, хуваалцана:</Typography>
                <ol>
                    <li>
                    Түгээлтийн хувьд бид байршлын мэдээллийг апп нээлттэй байх (foreground) үед болон апп хаагдсан (background) үед зөвхөн түгээлтийн ажилтны одоогийн байршлыг бодит цаг хугацаанд (realtime) хянах боломжийг нийлүүлэгч талд олгох зорилгоор цуглуулна.
                    </li>
                    <li>Захиалагч талын хувьд өөрсдийн тухайн өдрийн түгээлтэнд гарсан захиалгын байршлыг буюу хаана явааг харах боломжийг ологохоос гадна аливаа учирсан саатлыг нэн даруй шийдвэрлэхэд тусална.</li>
                    <li>Байршлын өгөгдлийг зөвхөн түгээлтийг хянахад зориулж ашигладаг бөгөөд хуульд зааснаас бусад тохиолдолд гуравдагч этгээдтэй хуваалцахгүй. Байршлын өгөгдлийг найдвартай хадгалж, түгээлтийн үйл ажиллагааг хэвийн хангахад зориулж ашиглана. Түгээлт дууссаны дараа тухайн түгээгчийн байршлын өгөгдлийг дамжуулахгүй.</li>
                </ol>
                <Typography>Байршлын өгөгдлийн хэрэглэгчийн хяналт:</Typography>
                <ol>
                    <li>Нийлүүлэгч талын ажилтан буюу Түгээгч байршлын мэдээлэл цуглуулахыг зөвшөөрөх эсэхээ өөрийн төхөөрөмжөөс тохируулах боломжтой ба байршлын зөвшөөрлийг хүссэн үедээ төхөөрөмжийнхөө тохиргоогоор дамжуулан идэвхгүй болгох боломжтой. Гэсэн хэдий ч байршлын үйлчилгээг идэвхгүй болгох нь тухайн аппликейшнд түгээлтийн хяналт, шинэчлэлтийг бодит цаг хугацаанд хянах боломжийг хязгаарлаж болзошгүй.</li>
                    <li>Бид хэрэглэгчийн хувийн нууцыг хамгаалж, байршлын мэдээллийг зөвшөөрөлгүй нэвтрэхээс хамгаалахын тулд аюулгүй байдлын арга хэмжээг хэрэгжүүлэх үүрэгтэй. Бид зөвхөн дээр дурьдсан нөхцөл, зорилгоор байршлын мэдээллийг ашиглах бөгөөд холбогдох бүх мэдээллийг хуулийн дагуу хамгаална.</li>
                </ol>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>4. Мэдээлэл хуваалцах</Typography>
                <Typography>Pharmo.mn нь хэрэглэгчийн мэдээллийг дараах хүмүүстэй хуваалцаж болно.</Typography>
                <ul>
                    <li>Үйлчилгээ үзүүлэгчид: Итгэмжлэгдсэн түншүүд нь хүргэлтийн үйлчилгээнд дуртай бөгөөд захиалгыг биелүүлэхийн тулд таны өгөгдөлд хандах шаардлагатай байдаг.</li>
                    <li>Хуулийн шаардлага: Хууль, хууль эрх зүйн процесс эсвэл засгийн газрын эрх бүхий байгууллагаас шаардлагатай бол өгөгдлийг хуваалцаж болно.</li>
                </ul>
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>5. Мэдээлэл хадгалах, устгах</Typography>
                <Typography>Үйлчилгээ үзүүлэх, хууль ёсны үүргээ биелүүлэхэд шаардлагатай бол бид таны хувийн мэдээллийг хадгалдаг. Та дараах эрхтэй:</Typography>
                <ol>
                    <li>Өгөгдөлдөө хандахыг хүсэх.</li>
                    <li>Буруу өгөгдлийг залруулах хүсэлт гаргах.</li>
                    <li>Хуулиар хадгалахыг шаарддагаас бусад тохиолдолд шаардлагагүй болсон тохиолдолд өгөгдлийг устгах хүсэлт гаргана уу.</li>
                </ol>
                <Typography>Та эдгээр эрхээ contact@infosystems.mn болон pharmo2023@gmail.com хаягаар бидэнтэй холбогдож ашиглаж болно.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>6. Аюулгүй байдал</Typography>
                <Typography>Бид хувийн болон нууц мэдээллийг зөвшөөрөлгүй нэвтрэх, зөрчлөөс хамгаалахын тулд салбарын стандартын аюулгүй байдлын туршлагыг хэрэгжүүлдэг. Таны нэвтрэх мэдээлэл шифрлэгдсэн бөгөөд бид өгөгдлийг сервер дээрээ найдвартай хадгалах арга хэмжээ авдаг.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>7. Нууцлалын бодлогын шинэчлэл</Typography>
                <Typography>Энэхүү Нууцлалын бодлогыг үе үе шинэчилж болно. Хэрэглэгчдэд томоохон өөрчлөлтүүдийн талаар имэйлээр эсвэл апп дотор мэдэгдэх болно. Шинэчилсэн удирдамжийг апп болон веб дотроос үзэх боломжтой.</Typography>
                <br />
                <Typography sx={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'left' }}>8. Холбоо барих мэдээлэл</Typography>
                <Typography>Хэрэв танд энэхүү Нууцлалын бодлоготой холбоотой асуулт, санаа зовоосон зүйл байвал бидэнтэй холбогдоно уу:</Typography>
                <ul>
                    <li>Инфо-Системс ХХК</li>
                    <li>Имэйл: contact@infosystems.mn, pharmo2023@gmail.com</li>
                    <li>Утас: 70116399, 70126399, 91916549</li>
                </ul>
            </Container>
            <Footer />
        </Box>
    )
}
