import { PROCESS_TYPES } from "../../../../../../../../../constants"
import ChangeDelmanCell from "./Cells/ChangeDelmanCell"
import ChangeStatusCell from "./Cells/ChangeStatusCell"
import { TableCell, TableRow } from "@mui/material"

export default function StopOrder({ idx, order, setStopOrders, setMsg, alertWarning, refetch, delmans, ordersLen, handleStopOrdersClose }) {
    const { id, orderNo, createdOn, process, delmanId } = order
    return (
        <TableRow>
            <TableCell>{`${idx+1}`}</TableCell>
            <TableCell>{orderNo||''}</TableCell>
            <TableCell sx={{ textWrap: 'nowrap', fontWeight: 600 }}>
                {createdOn?.split(' ')?.[0]||''}
            </TableCell>
            <TableCell sx={{ textWrap: 'nowrap' }}>
                {PROCESS_TYPES?.[process]||''}
            </TableCell>
            <ChangeDelmanCell
                delmans={delmans} 
                orderId={id} 
                stopDelmanId={delmanId}
                setMsg={setMsg}
                alertWarning={alertWarning}
                refetch={refetch}
                />
            {!delmanId && <ChangeStatusCell
                orderId={id}
                setStopOrders={setStopOrders}
                setMsg={setMsg}
                alertWarning={alertWarning}
                refetch={refetch}
                ordersLen={ordersLen}
                handleStopOrdersClose={handleStopOrdersClose}
            />}
        </TableRow>
    )
}
