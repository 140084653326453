import VerifyTable from '../VerifyTable'
import { Box } from '@mui/material'
import CheckPin from './CheckPin'

import Qr from './Qr'

export default function VerifyBox({ createOrder, boxOff, payType, inv }) {
    const { qrTxt, totalPrice, totalCount } = inv ? inv : {}
    
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', p: 4 }}>
            <VerifyTable payType={payType} totalPrice={totalPrice} totalCount={totalCount} />
            {
                payType === 'C'
                    ?   <Qr qr={qrTxt} totalPrice={totalPrice} totalCount={totalCount} boxOff={boxOff} />
                    :   <CheckPin createOrder={createOrder} boxOff={boxOff} />
            }
        </Box>
    )
}