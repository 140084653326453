import AxiosWithToken from "../global/axiosBase"

export const addStaff = (staffInfo) => {
    return AxiosWithToken.post('staff/', staffInfo)
}

export const addCustomer = (cust) => {
    return AxiosWithToken.post('customer/register/', cust)
}

export const updateStaff = (staffId, staffInfo) => {
    return AxiosWithToken.patch(`staff/${staffId}/`, staffInfo)
}

export const removeStaff = (staffId) => {
    return AxiosWithToken.delete(`staff/${staffId}/`)
}

export const getStaffs = (staffId = null) => {
    const url = staffId ? `staff/${staffId}/` : 'staff/'
    return AxiosWithToken.get(url)
}

export const getDelmanNames = () => {
    return AxiosWithToken.get(`staff/?role=D`)
}

// for booking manager of supplier
// get staffs
export const getOrderStaffs = () => { // get delmans and packers and sellers
    return AxiosWithToken.get(`staff/?role=D&role=P&role=S`)
}

// select delman
export const selectDelman = ({ orderIds, delmanId }) => {
    return AxiosWithToken.patch('select_delman/', { orderIds, delmanId })
}

// select packer
export const selectPacker = ({ orderIds, packerId }) => {
    return AxiosWithToken.patch('select_packer/', { orderIds, packerId })
}

// get orders to deliver
export const getOrdersToDeliver = () => {
    return AxiosWithToken.get(`order/?process=P&address__isnull=false`)
}

// for Packer
// change order process
export const changeOrderProcess = ({ oId, process }) => {
    return AxiosWithToken.patch('packer/', { oId, process })
}