import { Dialog, DialogContent } from '@mui/material'
import CountInput from './countInput'
import { useState } from 'react'

export default function AddToCart({ open, setOpen, product, checkQty }) {
    const [count, setCount] = useState(1)
    const handleAdd = () => { checkQty(product, count) }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogContent>
                <CountInput count={count} setCount={setCount} handleClick={handleAdd} />
            </DialogContent>
        </Dialog>
    )
}