import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { getShipment } from '../../../../../../api/shipment'
import ExpenseCell from './Cells/ExpenseCell'
import { getStaffName } from '../../utils'
import ShipDetail from './ShipDetail'
import { sortBy } from 'underscore'
import { useState } from 'react'

function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    let result = ''
    if (hours > 0) {
        result += `${hours} цаг`
    }
    if (minutes > 0) {
        if (hours > 0) result += ', '
        result += `${minutes} минут`
    }
    return result || '-'
}

export default function ShipmentRow({ idx, shipment, delmanNames, isDelman, alertWarning, setMsg, expanded, setExpanded, refetchShipmentHistory }) {
    const [ship, setShip] = useState(shipment)
    const duration = ship.duration ? formatDuration(ship.duration) : '-'
    const fetchShip = () => {
        getShipment(ship.id)
            .then(res => { setShip(res?.data) })
            .catch(err => { alertWarning(); console.log("ERR: ", err) })
    }

    const shipOrders = ship.inItems?.length > 0 ? sortBy(ship.inItems, 'branchId') :[]
    const shipUniqStops = ship.inItems?.length > 0 ? ship.inItems?.reduce((acc, cur) => acc.includes(cur.branchId) ? acc : [...acc, cur.branchId], []) : []
    const stopsCnt = shipUniqStops.length
    //if (ship.id === 37) console.log("row: ", shipUniqStops)

    return (
        <>
            <TableRow sx={{ '&:hover': { backgroundColor: '#f1f1f1' }, '& > td': { p: '3px', textAlign: 'center' } }}>
                <TableCell width='30px'>{!isDelman ? idx+1 : ''}</TableCell>
                <TableCell width='30px'>
                    {
                        !isDelman &&
                            <IconButton 
                                onClick={() => expanded !== ship.id ? setExpanded(ship.id) : setExpanded(null)}
                                sx={{ p: '2px' }}
                            >
                                {expanded !== ship.id ? <ArrowDownward sx={{ fontSize: '18px' }} /> : <ArrowUpward sx={{ fontSize: '18px' }} />}
                            </IconButton>
                    }
                </TableCell>
                {<TableCell>{getStaffName(delmanNames, ship?.delman)?.name||'Түгээгч'}</TableCell>}
                <TableCell>{ship.createdOn||'-'}</TableCell>
                <TableCell>{ship.startTime||'-'}</TableCell>
                <TableCell>{ship.endTime||'-'}</TableCell>
                <TableCell>{duration}</TableCell>
                <TableCell>{stopsCnt||'-'}</TableCell>
                <TableCell>{ship.progress||'-'}</TableCell>
                <TableCell>{(ship.progress > 0 && ship.stopsCnt > 0) ? `${Math.round((ship.progress * 100) / stopsCnt)} %` : '-'}</TableCell>
                <TableCell>{ship.inItems?.length||'-'}</TableCell>
                <ExpenseCell expense={ship.expense} />
            </TableRow>
            {
                expanded === ship.id &&
                    <ShipDetail
                        detail={shipOrders}
                        setUpdMsg={setMsg}
                        fetchShip={fetchShip}
                        delmanNames={delmanNames}
                        refetchShipmentHistory={refetchShipmentHistory}
                    />
            }
        </>
    )
}
