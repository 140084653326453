import AxiosWithToken from "../global/axiosBase"
import { getPageParams } from "./utils"

// from home page
const getQueryParams = (filters, page, pageSize, qParam) => {
    if (filters?.category) {
        qParam += qParam.endsWith('/') ? '?' : '&'
        if (filters.category === 'Бусад') {
            qParam += `category__isnull=true`
        } else {
            qParam += `category=${filters.category}`
        }
    }
    if (filters?.mnfr?.length) {
        qParam += qParam.endsWith('/') ? '?' : '&'
        qParam += `mnfr=${JSON.stringify(filters.mnfr)}`
    }
    if (filters?.vndr?.length) {
        qParam += qParam.endsWith('/') ? '?' : '&'
        qParam += `vndr=${JSON.stringify(filters.vndr)}`
    }
    qParam = getPageParams(page, pageSize, qParam)
    return qParam
}

export const getProds = ({ queryKey }) => {
    const [ _, filters, page, pageSize ] = queryKey
    let qParam = getQueryParams(filters, page, pageSize, 'products/')
    return AxiosWithToken.get(qParam)
}

export const getProductById = (productId) => {
    return AxiosWithToken.get(`products/${productId}/`)
}

// get vendors, mnfrs and categories
export const getProductFilters = () => {
    return AxiosWithToken.get('product/filters/')
}

export const getNewestProds = () => {
    return AxiosWithToken.get('products/?ordering=-created_at')
}

export const getDiscountProds = () => {
    return AxiosWithToken.get('products/?discount__gt=0')
}

export const getInDemandProds = () => {
    return AxiosWithToken.get('supplier_indemand_products/')
}