import { Box, Typography } from '@mui/material'


export default function Name({ name }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Typography sx={{ color: 'secondary.main', fontWeight: 500, fontSize: '20px' }}>{name}</Typography>
        </Box>
    )
}
