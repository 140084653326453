import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import { useEffect } from 'react'

export default function SelectAddress({ branches, order, setOrder }) {
	const handleBranchId = (branchId) => { setOrder(prev => ({ ...prev, branchId })) }

	// ганц салбартай бол тухайн салбарыг дефолтоор сонгох
	useEffect(() => { if (branches && branches?.length === 1) { handleBranchId(branches[0].id) } }, [branches])

	// олон салбартай бол сонгох
	const selectBranch = (b) => { if (branches?.length > 1) { handleBranchId(b?.id) } }

  	return (
		<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
			{
				branches.length > 0
					? 	<List sx={{ maxWidth: '600px' }}>
							{ branches?.map(
								(b, idx) => <ListItem 
												key={`${idx}`}
												onClick={() => selectBranch(b)}
												sx={{
													'&:hover': { bgcolor: '#efefef', cursor: 'pointer' },
													borderBottom: '1px solid #efefef', p: '2px'
												}}
											>
												<ListItemIcon sx={{ m: 0, p: 0 }}>
													{ b.id === order?.branchId ? <RadioButtonChecked sx={{ color: 'secondary.main', fontSize: '16px' }} /> : <RadioButtonUnchecked sx={{ fontSize: '16px' }} /> }
												</ListItemIcon>
												<ListItemText
													primary={<Typography fontSize='14px' color='primary.main' fontWeight='bold'>{b.name||'-'}</Typography>}
													secondary={b?.address?.address||''}
												/>
											</ListItem>
							) }
							</List>
				: 	null
			}
		</Box>
	)
}
