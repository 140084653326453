import { Close } from '@mui/icons-material'
import { Box, InputAdornment, MenuItem, TableCell, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import { keys } from 'underscore'
import { primaryLight } from '../../../../../utils/constants'
import DropMenu from '../../../../helpers/DropMenu'

export default function DropCell({ field, choices, filters, setFilters }) {
    const value = filters?.find(f => f.k?.startsWith(field))?.v
    const val2 = value === 'true' ? 'Очиж авах' : null
    const handleSelect = (newVal) => {
        setFilters(prev => {
            const f = { k: field, v: newVal, o: '=' }
            if (prev?.length) {
                if (prev.map(p => p.k).includes(field)) {
                    return prev.map(p => p?.k === field ? f : p)
                } else { return [...prev, f] }
            }
            return [f]
        })
    }
    const clearSelect = () => { setFilters(prev => prev?.filter(p => !p.k.startsWith(field))) }

    // hover
    const [hover, setHover] = useState(false)

    return (
        <TableCell>
            <DropMenu
                title={
                    <Box sx={{ display: 'flex', flexDirection: 'row', p: '4px' }}>
                        <Tooltip title={<Typography fontSize={'14px'}>{val2 ?? choices?.[value]}</Typography>} placement='top' open={Boolean(value && hover)}>
                            <TextField
                                onMouseEnter={() => { setHover(true) }}
                                onMouseLeave={() => { setHover(false) }}
                                placeholder={val2 ?? choices?.[value] ?? 'Сонгох'}
                                InputProps={{
                                    endAdornment: (<InputAdornment>
                                        <Close
                                            sx={{ fontSize: '18px', color: primaryLight, '&:hover': { cursor: 'pointer' }, display: Boolean(value) ? 'block' : 'none' }}
                                            onClick={clearSelect}
                                        />
                                    </InputAdornment>)
                                }}
                                inputProps={{ style: { padding: '2px 5px', cursor: 'pointer' } }}
                                sx={{
                                    '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                                    '&:hover fieldset': { borderColor: primaryLight },
                                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '14px', opacity: 1, ml: '5px' },
                                    '& .MuiInputBase-input': { 'caretColor': 'transparent' }
                                }}
                                size='small'
                            />
                        </Tooltip>
                    </Box>}
                detail={<Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                    {keys(choices).map(k =>
                        <MenuItem key={k} sx={{ fontSize: '14px', color: primaryLight }} onClick={() => handleSelect(k)}>
                            {choices[k]}
                        </MenuItem>)}
                </Box>}
                left={4}
                top={32}
            />
        </TableCell>
    )
}
