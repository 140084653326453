import { Box, CircularProgress, TableBody, TableCell } from '@mui/material'
import { getConfirmed1, getToConfim1 } from '../../../../../api/confirm'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import TablePages from '../../../../common/TablePages'
import { useLocation } from 'react-router'
import { useQuery } from 'react-query'
import { sortBy } from 'underscore'
import { useState } from 'react'
import Filter from '../Filter'
import { Row } from './Row'
import Edit from './Edit'

export default function TBody({ colSpan }) {
    // msg
    const [okMsg, setOkMsg] = useState(null)
    const okOff = () => { setOkMsg(null) }
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    const [orgs, setOrgs] = useState(null)
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const loc = useLocation()
    const getData = (res) => {
        setOrgs(res?.data?.results?.length > 0 ? sortBy(res.data.results, 'aemail') : [])
        setCount(res?.data?.count || 0)
    }
    // const { isFetching, refetch } = useQuery(
    //     ['orgs', page, pageSize, loc?.pathname], loc?.pathname === '/confirmation/main' ? getToConfim : getConfirmed,
    //     {
    //         enabled: true,
    //         onSuccess: (res) => { getData(res) },
    //         onError: (e) => { setErr('Серверийн алдаа!') }
    //     }
    // )

    const [filters, setFilters] = useState([]);
    const { isFetching, refetch } = useQuery(
        ['orgs', page, pageSize, loc?.pathname, filters], loc?.pathname === '/confirmation/main' ? getToConfim1 : getConfirmed1,
        {
            enabled: true,
            onSuccess: (res) => { getData(res) },
            onError: (e) => { setErr('Түр хүлээгээд дахин оролдоно уу!') }
        }
    )
    //console.log("C: ", count)

    // sel
    const [sel, setSel] = useState(null)
    const handleClose = () => { setSel(null) }

    // const { data } = useQuery(['get_promos', filters, pageSize, page], getPromotions)

    return (
        <>
            <Filter filters={filters} setFilters={setFilters} />
            {
                isFetching
                    ? <TableCell colSpan={12} sx={{ p: 10 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
                    </TableCell>
                    : <TableBody>
                        {
                            orgs?.map((b, idx) => <Row key={`key_${idx}`} b={b} idx={idx} setSel={setSel} />)
                        }
                    </TableBody>
            }
            {getToast(Boolean(err), errOff, err, 'warning', 1500)}
            {getToast(Boolean(okMsg), okOff, okMsg, 'success', 1500)}
            {
                sel &&
                <Edit
                    sel={sel}
                    handleClose={handleClose}
                    refetch={refetch}
                    setErr={setErr}
                    setOkMsg={setOkMsg}
                />
            }
            {
                <TablePages
                    count={count}
                    page={page}
                    setPage={setPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    colSpan={colSpan}
                />
            }
        </>
    )
}
