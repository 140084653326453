import { Box, Button, Typography } from '@mui/material'
import { checkSQpay } from '../../../../api/company'
import { useEffect, useState } from 'react'
import { CustomDivider } from '..'
import { keys } from 'underscore'

export const payTypes = { C: 'Бэлнээр', L: 'Зээлээр' }

export default function Payment({ payType, setOrder, isCash }) {
    const handleSetType = (e) => {
        const selected = e?.target?.value
        if (selected) { setOrder(prev => ({ ...prev, payType: selected })) }
    }
    const w = payType === 'C' ? '/одоо төлөх/' : payType === 'L' ? '/дараа төлөх/' : '.'

    const [noQpay, setNoQpay] = useState(null)
    useEffect(() => {
        checkSQpay()
            .then(r => {
                if (r?.status === 204) { setNoQpay(true) }
                if (r?.status === 200) { setNoQpay(null) }
            })
            .catch(_ => {})
    }, [])

    return (
        <>
            <CustomDivider text={'Төлбөрийн хэлбэр'} />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', p: 2 }}>
                {
                    keys(payTypes).map(k => 
                        {
                            return <Box sx={{ ml: 2 }}>
                                        <Button 
                                            variant={ payType === k ? 'contained' : 'outlined' } 
                                            value={k} size='small' sx={{ mr: 1 }} 
                                            onClick={ handleSetType }
                                            disabled={ (noQpay && k === 'C') || (isCash && k === 'L')}
                                        >{payTypes[k]}</Button>
                                        { payType === k && <Typography fontSize='14px' textAlign='center' color={ w === '.' ? 'white' : '#a9a9a9'}>{w}</Typography>}
                                    </Box>
                        }
                    )
                }
            </Box>
        </>
    )
}