import { TableCell, TableRow, Tooltip } from '@mui/material'
import { PROMO_TYPES, TARGET_TYPES } from '../../utils'
import { FilterAlt } from '@mui/icons-material'
import { usePromoCtx } from '../..'
import DropCell from './DropCell'
import NameCell from './NameCell'


const IS_ACTIVE = { 1: 'Идэвхтэй', 0: 'Идэвхгүй' }
const IS_MARKED = { 1: 'Онцолсон', 0: 'Онцлоогүй' }

export default function FilterRow({ top }) {
    const { filters, setFilters } = usePromoCtx()
    const hasFilter = filters?.length > 0

    return (
        <TableRow
            sx={{
                '& > td': {
                    m: 0, p: 0, borderBottom: '1px solid gray',
                    borderBottomColor: 'primary.light', backgroundColor: 'white',
                },
                boxShadow: hasFilter ? '2px 2px 4px 2px rgba(0, 0, 0, 0.2)' : 'none',
                position: 'sticky', top: top||'initial', zIndex: 1
            }}
        >
            <TableCell align='center' sx={{ p: '2px' }}>
                <Tooltip title='Шүүж хайх мөр' placement='right'>
                    <FilterAlt sx={{ color: 'secondary.light', fontSize: '20px', margin: 0 }} />
                </Tooltip>
            </TableCell>
            <NameCell />
            <TableCell />
            <DropCell field='is_active' choices={IS_ACTIVE} filters={filters} setFilters={setFilters} />
            <DropCell field='is_marked' choices={IS_MARKED} filters={filters} setFilters={setFilters} />
            <DropCell field='promo_type' choices={PROMO_TYPES} filters={filters} setFilters={setFilters} />
            <DropCell field='target_type' choices={TARGET_TYPES} filters={filters} setFilters={setFilters} />
            <TableCell colSpan={5} />
        </TableRow>
    )
}
