import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { scrollStyle, secondaryLight } from '../../../../../utils/constants'
import { useEffect, useState } from 'react'
import ShipmentRow from './ShipmentRow'

const cols = [ '№', '', 'Түгээгч', 'Хүргэх байршлын тоо', 'Нийт захиалгын тоо', 'Эхэлсэн цаг', 'Дууссан цаг', 'Явц', 'Зарлага', 'Цуцлах']
export default function CurrentShipment({ ships, isDelman, delmanNames, alertWarning, msg, setMsg, isFetching, refetchShipments }) {
    const [expanded, setExpanded] = useState(isDelman||null)
    useEffect(() => { refetchShipments() }, [])
    return (
        <TableContainer 
            sx={{
                p: 1,
                mt: 2,
                width: '1000px',
                boxShadow: 3,
                maxHeight: '80%',
                ...scrollStyle,
                '&::-webkit-scrollbar': { width: 8 },
            }}
        >
            <Table>
                <TableHead>
                    <TableRow sx={{ '& > th': { color: 'white', textAlign: 'center', backgroundColor: secondaryLight, borderRight: '1px solid white', p: '2px 4px' } }}>
                        {cols.map(c => <TableCell key={c}>{(isDelman && c === '№') ? '': c}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        isFetching
                            ?  
                                <TableRow>
                                    <TableCell colSpan={cols.length} sx={{ textAlign: 'center', height: '200px' }}>
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            :   ships?.length > 0
                                    ?   ships?.map((s, idx) =>
                                                    <ShipmentRow 
                                                        key={s.id} 
                                                        idx={idx}
                                                        shipment={s}
                                                        delmanNames={delmanNames}
                                                        isDelman={isDelman}
                                                        alertWarning={alertWarning}
                                                        msg={msg}
                                                        setMsg={setMsg}
                                                        expanded={expanded}
                                                        setExpanded={setExpanded}
                                                    />
                                                )
                                    :   <TableRow sx={{ m: 2 }}>
                                            <TableCell colSpan={cols?.length} sx={{ textAlign: 'center' }}>Одоогоор түгээлт байхгүй!</TableCell>
                                        </TableRow>
                    }

                </TableBody>
            </Table>
        </TableContainer>
    )
}