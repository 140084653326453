import { Avatar, Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import { emailYup, passCreateYup } from '../../../utils/constants'
import PasswordField from '../../helpers/fields/PasswordField'
import { getRegistrationOTP } from '../../../api/auth'
import ClickableLink from '../helpers/ClickableLink'
import { getToast } from '../helpers/ToastAlert'
import { Person } from '@mui/icons-material'
import getNameErr from '../helpers/getError'
import { useNavigate } from 'react-router'
import SignUpVerify from './SignUpVerify'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'


const phoneValMsg = 'Утасны дугаар буруу байна!'
const validationSchema = Yup.object({
  ...emailYup,
  phone: Yup.string().min(8, phoneValMsg).max(8, phoneValMsg).required('Утасны дугаар оруулна уу!').test('isNumeric', phoneValMsg, value => isFinite(value)),
  ...passCreateYup
})

export default function SignUp() {
    const navi = useNavigate()
    const goToSignIn = () => { navi('/signin') }

    const [alert, setAlert] = useState(null)
    const alertOff = () => { setAlert(null) }
    
    // verify otp input
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false) }
    
    // get OTP for registration
    const onSubmit = ({ email, phone }, { setErrors }) => {
        getRegistrationOTP({ email, phone }).then(r => {
            if (r?.status === 200) { setOpen(true) }
        }).catch(e => {
            const phone = e?.response?.data?.phone?.[0]
            const email = e?.response?.data?.email?.[0]
            const existErr = e?.response?.data?.non_field_errors?.[0]
            if (phone) { setErrors({ phone }) }
            else if (email) { setErrors({ email }) }
            else if (existErr?.includes('имэйл хаяг')) { setErrors({ email: existErr }) }
            else if (existErr?.includes('утасны дугаар')) { setErrors({ phone: existErr }) }
            else { setAlert({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }
        })
    }

    const { handleSubmit, getFieldProps, errors, touched, setErrors, values } = useFormik({
        initialValues: {
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        onSubmit,
        validationSchema,
        validateOnChange: false,
    })

    const getErrorsByName = (name) => {
        return getNameErr(errors, touched, name)
    }

    // enter shift
    const idMap = { f1: 'f2', f2: 'f3', f3: 'f4' }
    const handleEnter = (event) => {
        const curElmId = event.target.id
        if (event.key === 'Enter') {
            event.preventDefault()
            if (curElmId === 'f4') {
                handleSubmit()
            } else {
                const elm = document.getElementById(idMap[curElmId])
                elm.focus()
            }
        }
    }

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 20, mb: 10 }}>
            {getToast(Boolean(alert?.m), alertOff, alert?.m, alert?.s, 2000)}
            {
                open && 
                <SignUpVerify 
                    values={values}
                    setErrors={setErrors}
                    open={open}
                    handleClose={handleClose}
                    goToSignIn={goToSignIn}
                />
            }
            <Box
                sx={{
                    marginTop: 15,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3, mb: 4
                }}
                component={Paper}
                elevation={24}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.light' }}>
                    <Person />
                </Avatar>
                <Typography component="h2" variant="h6" sx={{ mb: 5}}>
                    Бүртгүүлэх
                </Typography>
                <Box>
                    <TextField
                        size='small'
                        fullWidth
                        label="Имэйл хаяг"
                        {...getErrorsByName('email')}
                        {...getFieldProps('email')}
                        id='f1'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        size='small'
                        fullWidth
                        label="Утасны дугаар"
                        id='f2'
                        {...getErrorsByName('phone')}
                        {...getFieldProps('phone')}
                        onKeyDown={handleEnter}
                    />
                    <PasswordField
                        size='small'
                        fullWidth
                        label="Нууц үг"
                        id='f3'
                        {...getErrorsByName('password1')}
                        {...getFieldProps('password1')}
                        onKeyDown={handleEnter}
                    />
                    <PasswordField
                        size='small'
                        fullWidth
                        label="Нууц үг давтах"
                        id='f4'
                        {...getErrorsByName('password2')}
                        {...getFieldProps('password2')}
                        onKeyDown={handleEnter}
                    />
                    <Button onClick={ handleSubmit } fullWidth variant="contained" sx={{ mt: 1, mb: 1, p: '7px' }} size='small'>
                        Баталгаажуулах код авах
                    </Button>
                    <Grid container>
                        <Grid item><ClickableLink label={'Нэвтрэх'} onClick={goToSignIn} /></Grid>
                    </Grid>
                </Box>
                <Box>
                    <ClickableLink label={'Бүртгүүлэх заавар үзэх'} onClick={() => {window.open('https://youtu.be/eGQscHWFzy4', '_blank')}} />
                </Box>
            </Box>
      </Container>
    )
}
