import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'


const choices = {
    name: 'Нэрээр',
    barcode: 'Баркодоор',
    intName: 'Ерөнхий нэршлээр',
}
export default function SearchField({ searchKey, setSearchKey, searchWidth, disabled }) {
    const handleChange = (e) => { setSearchKey(e.target.value) }

    return (
        <RadioGroup
            row
            sx={{ display: 'flex', justifyContent: 'right', mt: '4px', width: searchWidth }}
            value={searchKey}
            onChange={handleChange}
        >
            {Object.keys(choices).map(k => {
                return <FormControlLabel 
                            value={k} 
                            control={
                                <Radio 
                                    sx={{ 
                                        p: '2px', ml: 1, color: 'white', 
                                        '& .MuiSvgIcon-root': { fontSize: 14 },
                                        '&.Mui-checked': { color: 'white' }
                                    }}
                                />
                            }
                            disabled={disabled}
                            label={<Typography fontSize={13}>{choices[k]}</Typography>} 
                        />
            })}
        </RadioGroup>
    )
}
