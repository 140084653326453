import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { getShipment } from '../../../../../../api/shipment'
import ExpenseCell from './Cells/ExpenseCell'
import CancelShip from './Cells/CancelShip'
import { getStaffName } from '../../utils'
import ShipDetail from './ShipDetail'
import { sortBy } from 'underscore'
import { useState } from 'react'
//import StartCell from './Cells/StartCell'
//import EndCell from './Cells/EndCell'

export default function ShipmentRow({ idx, shipment, delmanNames, isDelman, alertWarning, setMsg, expanded, setExpanded }) {
    const [ship, setShip] = useState(shipment)

    const fetchShip = () => {
        getShipment(ship.id)
            .then(res => { setShip(res?.data) })
            .catch(err => { alertWarning(); console.log("ERR: ", err) })
    }

    const shipOrders = ship.inItems?.length > 0 ? sortBy(ship.inItems, 'branchId') :[]

    return (
        <>
            <TableRow sx={{ '&:hover': { backgroundColor: '#f1f1f1' }, '& > td': { p: '3px', textAlign: 'center' } }}>
                <TableCell width='30px'>{!isDelman ? idx+1 : ''}</TableCell>
                <TableCell width='30px'>
                    {
                        !isDelman &&
                            <IconButton 
                                onClick={() => expanded !== ship.id ? setExpanded(ship.id) : setExpanded(null)}
                                sx={{ p: '2px' }}
                            >
                                {expanded !== ship.id ? <ArrowDownward sx={{ fontSize: '18px' }} /> : <ArrowUpward sx={{ fontSize: '18px' }} />}
                            </IconButton>
                    }
                </TableCell>
                {<TableCell>{getStaffName(delmanNames, ship?.delman)?.name||'Түгээгч'}</TableCell>}
                <TableCell>{ship.stopsCnt||'-'}</TableCell>
                <TableCell>{ship.ordersCnt||'-'}</TableCell>
                <TableCell>{ship.startTime||'-'}</TableCell>
                <TableCell>{ship.endTime||'-'}</TableCell>
                {/* {
                    isDelman 
                        ?   <StartCell startTime={ship.startTime} shipmentId={ship.id} setMsg={setMsg} />
                        :   <TableCell>{ship.startTime||'-'}</TableCell>
                }
                {
                    isDelman
                        ?   <EndCell endTime={ship.endTime} shipmentId={ship.id} setMsg={setMsg} />
                        :   <TableCell>{ship.endTime||'-'}</TableCell>
                } */}
                <TableCell>{(ship.progress > 0 && ship.ordersCnt > 0) ? `${Math.round((ship.progress / ship.ordersCnt) * 100)} %` : '-'}</TableCell>
                <ExpenseCell expense={ship.expense} />
                <CancelShip ship={ship} />
            </TableRow>
            {
                expanded === ship.id &&
                    <ShipDetail
                        detail={shipOrders}
                        setUpdMsg={setMsg}
                        fetchShip={fetchShip}
                        isShipmentEnded={Boolean(shipment?.endTime)}
                    />
            }
        </>
    )
}
