import { Box } from '@mui/material'

import { scrollStyle } from '../../../../utils/constants'

const ThumbImages = ({ imgs, selectMain }) => {
    return (
        <Box 
            sx={{
                width: '100%',
                height: '140px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                overflowX: 'auto',
                overflowY: 'hidden',
                ...scrollStyle,
                '&::-webkit-scrollbar': { height: 8 },
            }}
        >
            {
                imgs?.length
                    ?   imgs.map(
                            (img, i) => 
                            <img
                                key={`img-${i}`}
                                src={img}
                                style={{ width: '120px', height: '120px', padding: '3px', margin: '3px', border: '1px solid grey' }}
                                onClick={() => selectMain(i)}
                            />
                        )
                    :   null
            }
        </Box>
    )
}

export default ThumbImages