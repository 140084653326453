import { Box, Dialog, DialogContent, IconButton } from '@mui/material'
import { scrollStyle } from '../../../../../../utils/constants'
import { Close } from '@mui/icons-material'
import PurchasePromo from './PurchasePromo'
import ProductPromo from './ProductPromo'
import BundlePromo from './BundlePromo'

export default function Promo({ promo, setSelectedPromo }) {
    const pt = promo?.promo_type
    if (!Boolean(promo)) return null
    return (
        <Dialog
            open={Boolean(promo)}
            onClose={() => setSelectedPromo(null)}
            maxWidth='md'
            fullWidth
        >
            <DialogContent sx={{ p: 1, ...scrollStyle, '&::-webkit-scrollbar': { width: 8 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={() => setSelectedPromo(null)}><Close /></IconButton>
                </Box>
                {pt === 1 && <BundlePromo promo={promo} />}
                {pt === 2 && <PurchasePromo promo={promo} />}
                {pt === 3 && <ProductPromo promo={promo} isProduct={true} />}
            </DialogContent>
        </Dialog>
    )
}
