import { Box, Button, InputAdornment, MenuItem, TableCell, TextField, Typography } from '@mui/material'
import DropMenu from '../../../../../../helpers/DropMenu'
import { Close } from '@mui/icons-material'
import { useState } from 'react'

const lookupChoice = [
    { label: '=', value: '' },
    { label: '>=', value: '__gte' },
    { label: '<=', value: '__lte' },
]

export default function NumberCell({ field, filters, setFilters, left }) {
    const prevVal = filters.find(f => lookupChoice.map(l => `${field}${l.value}`).includes(f?.k))
    const [lookup, setLookup] = useState('')
    const [value, setValue] = useState('')
    const fv = { k: `${field}${lookup}`, v: value }
    const handleChange = (e) => {
        const v = e.target.value
        if (v && !isNaN(v)) { setValue(v) } else { handleClear() }
    }

    const handleFilter = () => {
        if (value) { setFilters(prev => {
            if (prev.find(p => p.k.includes(field))) {
                return prev.map(p => p.k.includes(field) ? fv : p)
            } else { return [...prev, fv] }
        }) }
    }

    const handleEnter = (e) => {
        if (e.code === 'Enter' && value) { handleFilter() }
    }

    const handleClear = () => {
        setValue('')
        setFilters(prev => prev.filter(p => !lookupChoice.map(l => `${field}${l.value}`).includes(p?.k)))
    }

    return (
        <TableCell sx={{ p: 0, width: '110px' }}>
            <DropMenu
                title={<TextField
                            placeholder={value||prevVal?.v||'Шүүх'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <Close
                                            sx={{ 
                                                visibility: Boolean(value || prevVal) ? 'visible' : 'hidden',
                                                fontSize: '18px', color: 'primary.light',
                                                '&:hover': { cursor: 'pointer' },
                                            }}
                                            onClick={handleClear}
                                        />
                                    </InputAdornment>
                                )
                            }}
                            inputProps={{ style: { padding: '2px 5px', fontSize: '14px', cursor: 'pointer' } }}
                            sx={{
                                '& fieldset': { borderColor: 'primary.light', borderRadius: 0 },
                                '&:hover fieldset': { borderColor: 'primary.light' },
                                '& .MuiInputBase-input::placeholder': { color: 'primary.light', fontSize: '14px', opacity: 1, pl: 1 },
                                '& .MuiInputBase-input': { 'caretColor': 'transparent' }
                            }}
                            size='small'
                        />}
                detail={<Box sx={{ backgroundColor: 'white', boxShadow: 8, display: 'flex', flexDirection: 'row', padding: '16px 32px' }}>
                            <DropMenu
                                title={
                                    <Typography
                                        fontSize='14px'
                                        sx={{ 
                                            display: 'flex', mr: 1, border: 1, p: '2px',
                                            borderColor: 'primary.light', color: 'primary.light',
                                            padding: '1.5px 5px', width: '30px'
                                        }}
                                    >{lookupChoice.find(l => l.value === lookup)?.label||'='}</Typography>
                                }
                                detail={
                                    <Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                                        {lookupChoice.map(
                                            l => <MenuItem key={l.value} sx={{ fontSize: '14px', color: 'primary.light' }} onClick={() => setLookup(l.value)}>
                                                    {l.label}
                                                </MenuItem>
                                        )}
                                    </Box>
                                }
                                top={25}
                                left={0}
                            />
                            <TextField
                                value={value}
                                onChange={handleChange}
                                inputProps={{ style: { padding: '3px 16px', fontSize: '14px', cursor: 'pointer' } }}
                                sx={{
                                    width: '100px',
                                    '& fieldset': { borderColor: 'primary.light', borderRadius: 0 },
                                    '&:hover fieldset': { borderColor: 'primary.light' },
                                }}
                                size='small'
                                onKeyDown={handleEnter}
                            />
                            <Button
                                sx={{ p: 0, m: 0, ml: 1, borderRadius: 0 }}
                                onClick={handleFilter}
                                variant='contained'
                                size='small'
                            >Шүүх</Button>
                        </Box>}
                top={25}
                left={left??4}
            />
            
        </TableCell>
    )
}
