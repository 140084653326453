import { primaryLight } from '../../../../../../../../../utils/constants'
import { InputAdornment, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'


export default function OrderIdTitle({ prevVal, doClear }) {
    return (
        <TextField
            placeholder={prevVal?.fValue||'Хайх'}
            InputProps={{
                endAdornment: (<InputAdornment>
                                    <Close
                                        sx={{
                                            display: Boolean(prevVal?.fValue) ? 'block' : 'none',
                                            fontSize: '15px', color: primaryLight, 
                                            '&:hover': { cursor: 'pointer' }
                                        }}
                                        onClick={doClear}
                                    />
                                </InputAdornment>)
            }}
            inputProps={{ style: { padding: '2px 5px', cursor: 'pointer' } }}
            sx={{
                '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                '&:hover fieldset': { borderColor: primaryLight },
                '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '13px', opacity: 1, ml: '5px' },
                '& .MuiInputBase-input': { 'caretColor': 'transparent' }
            }}
            size='small'
        />
    )
}
