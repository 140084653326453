import { useContext, useState, useEffect, createContext } from 'react'
import { getThemeName, isTokenValid } from '../utils/functions'
import { QueryClientProvider, QueryClient } from 'react-query'
import { SUPPLIER_ROLES } from '../utils/constants'
import { getSuppliers } from '../api/company'
import { BcProvider } from './BroadcastCtx'
import { getBasket } from '../api/basket'
import ThemeCtx from './ThemeCtx'
import axios from 'axios'
import App from '../App'

const subscribeUser = async () => {
    const registration = await navigator?.serviceWorker?.ready
    const subscription = await registration?.pushManager?.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_APP_VAPID_KEY
    })

    // Send subscription to the server
    const a = `${process.env.REACT_APP_MAIN_API}`
    const p = `${process.env.REACT_APP_API_PORT}`
    const u = p ? `${a}:${p}` : `${a}`
    const url = `${u}/api/v1/sub_push/`

    if (!process.env.REACT_APP_MAIN_API?.includes('pharmo.mn')) {
        console.log("SUB: ", subscription.endpoint, url)
    }

    const tk = localStorage.getItem("access_token")
    if (subscription) {
        axios.post(
                url,
                {
                    'endpoint': subscription.endpoint,
                    'auth': btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))),
                    'p256dh': btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh'))))
                },
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${tk}` }}
            ).then(res => {
                console.log("SUCsub: ", res)
            }).catch(e => {
                console.log("ERRsub: ", e)
            })
    }
}

const AuthCtx = createContext()
export const useAuthCtx = () => useContext(AuthCtx)
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 2
        },
    }
})

const AuthCtxProvider = () => {
    //console.log = function(){}
    console.error = function(){}
    console.dirxml = function(){}
    console.assert = function(){}
    console.debug = function(){}
    console.warn = function(){}
    console.info = function(){}
    console.trace = function(){}

    const { email, supplier, role, user_id } = isTokenValid()
    const theSupplier = SUPPLIER_ROLES.includes(role) ? role === 'A' ? user_id : supplier : null
    const [supplierId, setSupplierId] = useState(email ? theSupplier ? theSupplier : supplier : null)
    const [suppliers, setSuppliers] = useState([])
    
    // set basket depends on selected supplier
    const [basket, setBasket] = useState(null)
    const fetchBasket = () => {
        if (supplierId) {
            getBasket()
                .then(res => {
                    const newBask = res?.data
                    if (newBask) { setBasket(newBask) } else { setBasket(null) }
                })
                .catch(e => { console.log("ERRs: ", e) })
        }
    }

    const clearUserItems = () => { setBasket(null) }

    useEffect(() => {
        if (email) {
            subscribeUser()
            getSuppliers()
                .then(r => { setSuppliers(r?.data) })
                .catch(e => { console.log("SERR: ", e) })
        }
    }, [email])
    // fetch basket again when user change supplier
    useEffect(() => { if (supplierId) { fetchBasket() } }, [supplierId])
    useEffect(() => {
        // listen token change
        window.addEventListener('accessTokenChange', (e) => {
            if (e?.detail?.access) {
                const { email, supplier, role, user_id } = isTokenValid(e.detail.access)
                const theSupplier = SUPPLIER_ROLES.includes(role) ? role === 'A' ? user_id : supplier : null
                setSupplierId(email ? theSupplier ? theSupplier : supplier : null)
            }
        })
        return () => { window.removeEventListener('accessTokenChange', () => {}) }
    }, [])

    // theme
    const [selectedTheme, setSelectedTheme] = useState(getThemeName())
    const [navBtm, setNavBtm] = useState(null)
    
    // for fixed pagination
    const [pgTop, setPgTop] = useState(null)

    // alerts
    // set alert for add item to basket
    const [addAlert, setAddAlert] = useState(null)
    const [alert, setAlert] = useState(false)
    const handleAddAlertOff = () => { addAlert?.alertOff(); setAlert(false) }
    
    return (
        <AuthCtx.Provider
            value={{
                selectedTheme,
                setSelectedTheme,
                clearUserItems,
                // basket
                fetchBasket,
                basket,
                setBasket,
                supplierId,
                setSupplierId,
                suppliers,
                //
                navBtm,
                setNavBtm,
                // fixed pagination
                pgTop,
                setPgTop,
                // alert
                alert,
                setAlert,
                addAlert,
                setAddAlert,
                handleAddAlertOff,
            }}
        >
            <BcProvider>
                <QueryClientProvider client={queryClient}>
                    <ThemeCtx>
                        <App />
                    </ThemeCtx>
                </QueryClientProvider>
            </BcProvider>
        </AuthCtx.Provider>
    )
}

export default AuthCtxProvider