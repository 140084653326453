import CategoryItem from './CategoryList/CategoryItem'
import { Box, Button } from '@mui/material'
import CategoryList from './CategoryList'


const NoCategory = ({ onSelect }) => <CategoryItem menuItem={{ id: 'Бусад', name: 'Бусад' }} onSelect={onSelect} />

export default function Category({ categories, selectedCategory, setSelectedCategory }) {
    return (
        <Box
            sx={{
                bgColor: '#fff', width: '250px',
                borderRadius: 2, backgroundColor: 'white',
                m: 1, mt: 0, p: 1, boxShadow: 5, textAlign: 'center'
            }}
        >
            <Button
                variant="contained"
                size='small'
                sx={{
                    mb: 1, backgroundColor: 'secondary.light', width: '100%',
                    '&:hover': { backgroundColor: 'secondary.main' }, p: 0
                }}
                onClick={() => setSelectedCategory(null)}
            >БҮХ АНГИЛАЛ</Button>
            <CategoryList menus={categories} selected={selectedCategory} onSelect={setSelectedCategory} />
            <NoCategory onSelect={setSelectedCategory} />
        </Box>
)
}
