import { createPromoOrder } from '../../../../api/order'
import { CustomDivider, usePromoOrderCtx } from '..'
import { Box, Button, Dialog } from '@mui/material'
import { useEffect } from 'react'
import VerifyBox from './VerifyBox'
import { useState } from 'react'
import Note from './Note'

export default function Verify({ payType, createOrder, adrErr, qtyErr }) {
    // pin code verification
    const { order, setMsg } = usePromoOrderCtx()
    const [ pinBox, setPinBox ] = useState(false)
    const boxOff = () => { setPinBox(false) }
    const boxOn = () => { setPinBox(true) }

    // qpay invoice
    const [inv, setInv] = useState(null)
    //console.log("INV: ", inv)
    const [initQr, setInitQr] = useState(false)
    useEffect(() => { if (inv?.qrTxt && !initQr) { setMsg(null) } }, [inv, initQr])

    const createPromoOrderByQpay = () => {
        if (order?.payType === 'C') {
            if (inv?.qrTxt) { boxOn() }
            else {
                setInitQr(true)
                createPromoOrder(order)
                    .then(res => {
                        setInv(res?.data)
                        setMsg(null)
                        boxOn()
                    })
                    .catch(err => {
                        console.log("ERR: ", err)
                        setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                    })
                    .finally(() => { setInitQr(false) })
            }
        }
    }

    const handleClick = () => {
        //console.log("QT: ", qtyErr)
        if (adrErr) { setMsg({ m: 'Хүргэлтийн хаяг сонгоно уу!', s: 'warning' }) }
        else if (qtyErr) { setMsg({ m: qtyErr, s: 'warning' }) }
        else if (payType === null) { setMsg({ m: 'Төлбөрийн хэлбэр сонгоно уу!', s: 'warning' }); }
        else {
            if (payType === 'C') {
                createPromoOrderByQpay()
            } else {
                setMsg(null);
                boxOn()
            }
        }
    }

    return (
        <>
            <CustomDivider text={'Баталгаажуулах'} />
            <Note />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mb: 8 }}>
                <Button 
                    size='small' variant='contained' sx={{ width: '200px', mt: 3 }}
                    onClick={ handleClick } 
                    disabled={initQr}
                >Баталгаажуулах</Button>
                <Dialog open={pinBox} onClose={boxOff}>
                    <VerifyBox 
                        createOrder={createOrder}
                        boxOff={boxOff} 
                        payType={payType}
                        inv={inv}
                    />
                </Dialog>
            </Box>
        </>
    )
}
