import { selectDelman } from '../../../../../../../../../../../../api/staff'
import { Box, Dialog, MenuItem, TableCell } from '@mui/material'
import { useState } from 'react'

export default function ChangeDelmanCell({ delmans, orderId, setMsg, alertWarning, refetch, stopDelmanId }) {
    const currentDelman = stopDelmanId ? delmans?.find(d => d.id === stopDelmanId)?.name : 'Сонгох'
    const [open, setOpen] = useState(false)
    const handleOpen = (e) => { setOpen(true); e.stopPropagation() }
    const handleClose = () => { setOpen(false) }

    const handleChange = (delmanId) => {
        selectDelman({ orderIds: [orderId], delmanId })
            .then(_ => {
                refetch()
                setMsg({ m: 'Амжилттай!', s: 'success' })
            })
            .catch(error => { 
                const isShipStarted = error?.response?.data?.order?.[0] === "order on the way!"
                if (isShipStarted) { setMsg({ m: 'Түгээлтэнд гарсан захиалга байна!', s: 'warning' }) }
                else { alertWarning() }
                console.log("ERR: ", error)
            })
    }

    const delmanList = stopDelmanId ? delmans?.filter(d => d.delmanId !== stopDelmanId) : [...delmans]
    
    return (
        <>
            <TableCell onClick={handleOpen} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>{currentDelman}</TableCell>
            <Dialog open={open} onClose={handleClose} >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0 2px 10px #000',
                        borderRadius: '5px',
                        bgcolor: 'white',
                        width: 'fit-content',
                        p: '2px'
                    }}
                >
                    {
                        delmanList?.map(d => (
                            <MenuItem 
                                key={d.id}
                                onClick={() => handleChange(d.id)}
                                sx={{ 
                                    bgcolor: 'white', 
                                    color: 'primary.light', 
                                    fontSize: '14px',
                                    '&:hover': { bgcolor: 'primary.light', color: 'white' },
                                    transition: 'ease-in 0.15s', borderRadius: '5px'
        
                                }}
                            >{d.name||''}</MenuItem>
                        ))
                    }
                    {
                        stopDelmanId &&
                            <MenuItem 
                                onClick={() => handleChange(null)}
                                sx={{ 
                                    bgcolor: 'white', 
                                    color: 'secondary.light', 
                                    fontSize: '14px',
                                    '&:hover': { bgcolor: 'primary.light', color: 'white' },
                                    transition: 'ease-in 0.15s', borderRadius: '5px'

                                }}
                            >Цуцлах</MenuItem>
                    }
                </Box>
            </Dialog>
        </>
    )
}
