import { Box, Button, Typography } from '@mui/material'
import { isTokenValid } from '../../../utils/functions'
import { paperStyle } from '../../../utils/constants'
import { useNavigate } from 'react-router'
import Logout from '../../auth/Logout'


export default function Top() {
    const { email, company_name } = isTokenValid()
    const navi = useNavigate()

    return (
        <Box 
            sx={{ 
                display: 'flex', flexDirection: 'row',
                ...paperStyle, justifyContent: 'space-between',
                alignItems: 'center', mb: 1, p: 1, pl: 4, pr: 4, mt: 0
            }}
        >
            <Typography>Сайна байна уу! <span style={{ fontWeight: 'bold' }}>{company_name||email}</span></Typography>
            <Button 
                sx={{ 
                    justifyContent: 'center', flexGrow: 1, p: 0, borderBottom: '1px solid transparent',
                    '&:hover': { borderBottomColor: 'white', borderRadius: 0 }, fontWeight: 'bold'
                }}
                onClick={() => { navi('/') } }
            >Нүүр хуудас руу очих</Button>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}><Logout /></Box>
        </Box>
    )
}
