import { Box, Divider, ListItemButton, ListItemText, Typography } from "@mui/material"
import { useState } from "react"
import { useSellerCtx } from "../../.."
import AddToCart from "./addtocart"
import ExtraInfo from "./extra"

export default function ProductItem({ props }) {
    const { checkQty } = useSellerCtx()
    const { id, name, in_stock } = props

    const [open, setOpen] = useState(false)
    
    return (
        <>
            <ListItemButton key={id} sx={{ my: '5px' }} onClick={() => setOpen(true)}>
                <ListItemText
                    primary={
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                            <Typography fontSize='25px'>{name}</Typography>
                            {
                             in_stock === 0 && 
                                <Typography
                                    fontSize='22px'
                                    sx={{ 
                                        background: 'orange', 
                                        color: 'white',
                                        borderRadius: '5px',
                                        width: 'fit-content',
                                        p: '1px 8px'
                                    }}
                                >Үлдэгдэлгүй</Typography>
                            }
                        </Box>
                    }
                    secondary={<ExtraInfo props={props} />}
                />
            </ListItemButton>
            <Divider component="li" />
            <AddToCart open={open} setOpen={setOpen} product={props} checkQty={checkQty} />
        </>
    )
}