import { compressImgs } from '../../../../utils/functions'
import { InputAdornment, TextField } from '@mui/material'
import { Link } from '@mui/icons-material'
import { useState } from 'react'


const allowedFileTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/webp']
const allowedFileSize = 5 // 5mb
const allowedFileNameLength = 150
const allowedImgCnt = 5
const checkFiles = (files, setErr) => {
    const types = files?.map(f => f.type)
    if (types.includes('pdf') && files?.length > 1) {
        setErr('PDF төрлийн файл бол зөвхөн нэгийг хавсаргах боломжтой!')
        return false
    } else if (types.find(t => !allowedFileTypes.includes(t))) {
        setErr(`Зөвшөөрөгдөх файлын төрлүүд: JPG/JPEG, PDF, WEBP`)
        return false
    } else if (files?.length > allowedImgCnt) {
        setErr(`${allowedImgCnt}-с ихгүй зураг хавсаргах боломжтой!`)
        return false
    }
    return true
}

export default function FileInputLabel({ files, setFiles, handleKeyDown = null }) {
    const [fileErr, setFileErr] = useState(null)
    const fileInputClick = () => {
        setFiles([])
        setFileErr(null)
        document.getElementById('fileInput').click()
    }
    const handleFileSelection = (e) => {
        const ufs = Array.from(e.target.files)
        if (ufs?.length >= 1) {
            if (checkFiles(ufs, setFileErr) === true) {
                let pdfs = []
                let imgs = []
                for (let u of ufs) {
                    if (u instanceof File) {
                        const name = u?.name
                        const ft = u.type
                        if (u.size / 1024 / 1024 > allowedFileSize) { setFileErr(`${name} нэртэй файлын хэмжээ хэт их байна. ${allowedFileSize} MB-с ихгүй байх ёстой!`) }
                        else if (name?.length > allowedFileNameLength) { setFileErr(`${name} нэртэй файлын нэр хэт урт. ${allowedFileNameLength} тэмдэгтээс ихгүй байх ёстой!`) }
                        else {
                            if (ft === 'application/pdf') { pdfs.push(u) }
                            else { imgs.push(u) }
                        }
                    }
                }
                if (imgs?.length) { compressImgs(files, imgs, setFiles) }
                else if (pdfs?.length) { setFiles(pdfs) }
            }
        } else { setFileErr('Файл хавсаргана уу!') }
    }
    const fileNames = files?.length > 0 ? files.map(f => f.name)?.join(', ') : ''
    //console.log("F: ", fileErr, files, fileNames)
    
    return (
        <>
            <input
                accept="*/*"
                id="fileInput"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileSelection}
            />
            <TextField
                label='Тусгай зөвшөөрөл'
                size='small'
                sx={{ 
                    '&  input:hover': { cursor: 'cell' },
                    width: '100%',
                    '& input::placeholder': {
                        color: fileErr ? '#d32f2f' : '#1b2e3c',
                        fontSize: '15px',
                        opacity: 1
                    }
                }}
                InputProps={{
                    readOnly: true,
                    startAdornment: (
                        <InputAdornment>
                            <Link 
                                sx={{ 
                                    mr: 1, '&:hover': { cursor: 'cell' }, 
                                    border: 'none', color: files ? '#1b2e3c' : 'inherit' 
                                }}
                            />
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{ shrink: true, style: { fontSize: '15px' } }}
                onClick={fileInputClick}
                onKeyDown={handleKeyDown}
                placeholder={'Файл хавсаргах'}
                error={Boolean(fileErr)}
                helperText={Boolean(fileErr) ? fileErr : fileNames ? fileNames : ''}
            />
        </>
    )
}