import { createContext, useContext, useEffect, useState } from 'react'
import { showNewOrderNotif, showOrderDeliveryNotif } from './utils'

// BroadcastChannel
const BcCtx = createContext(null)

export const BcProvider = ({ children }) => {
	const [msg, setMsg] = useState(null)

    const [notif, setNotif] = useState(null)
    const showNotif = (notif) => {
        if (notif?.type === 'OC') { showNewOrderNotif(notif) }
        if (notif?.type === 'OO') { showOrderDeliveryNotif(notif); console.log("RECEIVE") }
    }
    useEffect(() => {
        if (notif) {
            showNotif(notif)
            setNotif(null)
        }
    }, [notif])

	useEffect(() => {
        const pharmoChannel = new BroadcastChannel('pharmo-channel');
		// show system notif
		pharmoChannel.onmessage = (e) => {
            const data = e?.data
            setMsg(e?.data)
            if (data?.type === 'notif') {
                setNotif(data?.body)
            }
		}
		return () => { pharmoChannel.close() }
	}, [])

	return (<BcCtx.Provider value={{ msg, setMsg }}>{children}</BcCtx.Provider>)
}

export const useBcCtx = () => useContext(BcCtx)
