import { getToast } from '../../../../../auth/helpers/ToastAlert'
import { scrollStyle } from '../../../../../../utils/constants'
import { useAuthCtx } from '../../../../../../context/AuthCtx'
import { toPrice } from '../../../../../../utils/functions'
import { clearBasket } from '../../../../../../api/basket'
import { Box, Button, Typography } from '@mui/material'
import BasketItem from './BasketItem'
import { useState } from 'react'

export default function Basket({ basket, goToOrder }) {
    const { fetchBasket } = useAuthCtx()
    const items = basket.items
    const totalPrice = items.reduce((acc, cur) => acc + (cur.price * cur.qty), 0)

    const [msg, setMsg] = useState(null)
    const handleClearBasket = () => {
        clearBasket(basket.id)
            .then(_ => { fetchBasket() })
            .catch(err => { console.log("ERR: ", err); setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) })
    }

    return (
        <Box sx={{ borderRadius: 1, backgroundColor: '#f1f1f1' }}>
            {getToast(Boolean(msg), () => setMsg(null), msg?.m, msg?.s, 2000)}
            <Box>
                <Box
                    sx={{
                        maxHeight: '350px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        borderBottom: '1px solid',
                        borderBottomColor: 'primary.light',
                        ...scrollStyle,
                        "&::-webkit-scrollbar": { width: 6 }
                    }}
                >
                    { items.map((item, idx) => <BasketItem key={item.id} item={item} idx={idx} />) }
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%', pr: 1, pl: 1, mt: 1 }}>
                    <Typography fontSize='15px' color='secondary.light' fontWeight='bold'>Нийт дүн: </Typography>
                    <Typography fontSize='15px' color='secondary.light' fontWeight='bold' sx={{ pr: 4 }}>{toPrice(totalPrice)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                        size='small'
                        onClick={() => { goToOrder() }}
                        sx={{ borderRadius: 0 }}
                        variant='contained'
                    >
                        Захиалга үүсгэх
                    </Button>
                    <Typography 
                        sx={{ 
                            color: 'secondary.light', 
                            textDecoration: 'underline', 
                            cursor: 'pointer',
                            fontSize: '13px',
                            my: 1, 
                        }}
                        onClick={handleClearBasket}
                    >Сагс хоослох</Typography>
                </Box>
            </Box>
        </Box>
    )
}
