import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { isEmpty, keys } from "underscore"
import { getLogs } from "../../../../../api/company"
import { getStaffs } from '../../../../../api/staff'
import { scrollStyle } from "../../../../../utils/constants"
import TablePages from "../../../../common/TablePages"
import HeadCell from "./Cells/HeadCell"
import FilterRow from "./Filter"
import Row from "./Row"

// const CustomerListCtx = createContext()
// export const useCustomerListCtx = () => useContext(CustomerListCtx)
export default function ScaffLog() {
    const [columns, setColumns] = useState({
        action: { label: "Үйлдэл", order: "", align: "left" },
        note: { label: "Тайлбар", order: "", align: "left" },
        name: { label: "Ажилтны нэр", order: "", align: "center" },
        role: { label: "Ажилтны төрөл", order: "", align: "center" },
        ip: { label: "IP хаяг", order: "", align: "center" },
        at: { label: "Огноо", order: "", align: "center" },
    })
    // ordering
    const handleOrder = (fieldName) => {
        const c = columns?.[fieldName]
        const v =
            c?.order === ""
                ? fieldName
                : c?.order === fieldName
                    ? `-${fieldName}`
                    : ""
        setColumns({
            ...columns,
            [fieldName]: { ...c, order: v },
        })
    }
    const orderParam = keys(columns)?.reduce((acc, k) => {
        const cur = columns?.[k]?.order;
        if (acc !== "ordering=" && cur !== "") {
            acc += ","
        }
        if (cur !== "") {
            acc += `${cur}`
        }
        return acc
    }, "ordering=")

    // pagination
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [filtering, setFiltering] = useState(null)

    const [partners, setPartners] = useState([])
    const [count, setCount] = useState(0)

    const [staffs, setStaffs] = useState([])

    const getData = (res) => { setPartners(res?.data?.results || []); setCount(res?.data?.count || 0) }
    // fetch data

    const fetchStaffs = () => {
        getStaffs()
            .then(r => {
                setStaffs(r?.data);
            })
            .catch(e => { console.log("E: ", e) })
    }
    useEffect(() => { fetchStaffs() }, [])

    const { data } = useQuery(["get_logs", page, pageSize, orderParam, filtering], getLogs, { onSuccess: getData })
    useEffect(() => {
        if (!isEmpty(data?.data)) {

            let temp = [];
            for (let i = 0; i < data?.data?.results.length; i++) {
                let obj = {
                    "action": data?.data?.results[i].action,
                    "at": data?.data?.results[i].at,
                    "id": data?.data?.results[i].id,
                    "ip": data?.data?.results[i].ip,
                    "note": data?.data?.results[i].note,
                    // "staff": data?.data?.results[i].staff,
                }
                for (let j = 0; j < staffs.length; j++) {
                    if (data?.data?.results[i].staff === staffs[j].id) {
                        obj.name = staffs[j].name;
                        obj.email = staffs[j].email;
                        obj.role = staffs[j].role;
                    }
                }
                temp.push(obj);
            }
            setPartners(temp);

        } else {
            setPartners([]);
        }
    }, [data?.data, staffs]);
    const hhRef = useRef(null)
    //console.log("P: ", data)

    return (
        <>
            <TableContainer
                sx={{
                    mt: 1, 
                    ...scrollStyle,
                    "&::-webkit-scrollbar": { width: 8, height: 8 },
                }}
            >
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow
                            sx={{
                                "& > th": {
                                    fontSize: "12px",
                                    backgroundColor: "secondary.light",
                                    color: "white",
                                    borderRight: "1px solid white",
                                    p: "4px 4px",
                                    textAlign: "center",
                                },
                            }}
                            ref={hhRef}
                        >
                            <TableCell><Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>№</Typography></TableCell>
                            {
                                keys(columns)?.map((c) => (
                                    <HeadCell
                                        key={c}
                                        field={columns?.[c]}
                                        fieldName={c}
                                        handleOrder={handleOrder}
                                    />
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <FilterRow filtering={filtering} setFiltering={setFiltering} staffs={staffs} />
                    <TableBody>
                        {
                            partners?.map((row, idx) => (
                                <Row
                                    key={`${idx}`}
                                    row={row}
                                    setPartners={setPartners}
                                    idx={idx}
                                    page={page}
                                    pageSize={pageSize}
                                />
                            ))
                        }
                    </TableBody>
                    <TablePages
                        count={count}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        colSpan={keys(columns)?.length + 1}
                    />
                </Table>
            </TableContainer>
        </>
    )
}