import { FilterAlt } from '@mui/icons-material'
import { TableCell, TableRow, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { primaryLight, secondaryLight } from '../../../../../utils/constants'
import { COM_TYPES } from '../../../../constants'
import DropCell from '../DropCell'
import NameCell from '../NameCell'

export default function Filter({ filters, setFilters, hhRef }) {
    console.log(filters);
    const [top, setTop] = useState(hhRef?.current?.getBoundingClientRect()?.height)
    useEffect(() => {
        const newTop = hhRef?.current?.getBoundingClientRect()?.height
        if (newTop) { setTop(newTop) }
    }, [hhRef])

    return (
        <TableRow
            sx={{
                '& > td': {
                    m: 0, p: 0, borderBottom: '1px solid gray', zIndex: 1050, top, borderBottomColor: primaryLight, position: 'sticky', backgroundColor: 'white', boxShadow: '0 2px 4px 0 gba(0, 0, 0, 0.2)'
                }
            }}
        >
            <TableCell align='center'>
                <Tooltip title='Компаны шүүлтүүр' placement='right'><FilterAlt sx={{ color: secondaryLight }} /></Tooltip>
            </TableCell>
            <NameCell text='Имэйлээр хайх' field={'email'} filters={filters} setFilters={setFilters} />
            <NameCell text='Утсаар хайх' field={'phone'} filters={filters} setFilters={setFilters} />
            <TableCell></TableCell>
            <NameCell text='Нэрээр хайх' field={'name'} filters={filters} setFilters={setFilters} />
            <NameCell text='РД-аар хайх' field={'rd'} filters={filters} setFilters={setFilters} />
            <DropCell field={'cType'} choices={COM_TYPES} filters={filters} setFilters={setFilters} />
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}
