import { IconButton, Box, Typography, ListItemText } from '@mui/material'
import { PHARMA_ROLES, SUPPLIER_ROLES } from '../../../../utils/constants'
import { isTokenValid } from '../../../../utils/functions'
import { AccountCircle } from '@mui/icons-material'
import DropMenu from '../../../helpers/DropMenu'
import { useNavigate } from 'react-router'
import SignedInMenu from './SignedInMenu'

export default function User() {
    const { role, company_name } = isTokenValid()
    const isSupplier = SUPPLIER_ROLES.includes(role)

    const navi = useNavigate()
    const toOrders = () => { isSupplier ? navi('/admin/orders') : navi('/profile/orders') }

    return (
            <DropMenu
                title={
                    <IconButton>
                        <Box 
                            sx={{ 
                                display: 'flex', justifyContent: 'center', 
                                alignItems: 'center', flexDirection: 'column' 
                            }}
                            onClick={ toOrders }
                        >
                            <AccountCircle sx={{ color: "white", '&:hover': { color: '#e2e2e2', }, fontSize: 30 }} />
                            <ListItemText
                                sx={{ color: 'white', fontSize: '14px' }} 
                                primary={
                                    <Typography fontSize='13px' noWrap sx={{ width: '60px' }}>
                                        { company_name ?  company_name : isSupplier ? 'Админ' : 'Хэрэглэгч' }
                                    </Typography>
                                }
                            />
                        </Box>
                    </IconButton>
                }
                detail={
                    <Box sx={{ p: '4px', backgroundColor: 'white', boxShadow: 8 }}>
                       <SignedInMenu isSupplier={isSupplier} role={role} />
                    </Box>
                }
                top={70}
                left={-30}
            />
    )
}
