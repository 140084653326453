import { useMap } from '@vis.gl/react-google-maps'
import { getDelmanColor } from '../../../../utils'
import { useEffect, useState } from 'react'

export default function DeliveryRoutes({ routes, checked }) {
    const map = useMap()
    const [lines, setLines] = useState([])

    const clearLines = () => { if (lines?.length > 0) { lines?.map(l => l.setMap(null)) } }
    useEffect(() => {
        if (!checked.includes(2)) {
            clearLines()
            setLines([])
        }
    }, [checked])

    useEffect(() => {
        if (map && checked?.includes(2)) {
            const controlDiv = document.createElement('div')
            controlDiv.style.margin = '10px'
            for (let route of routes) {
                const line = new window.google.maps.Polyline({
                    path: route?.route,
                    geodesic: true,
                    strokeColor: getDelmanColor(route.delmanId),
                    strokeOpacity: 1.0,
                    strokeWeight: 6
                })
                line.setMap(map)
                setLines(prev => [...prev, line])
            }
        }
        return () => { clearLines() }
    }, [map, routes])

    return null
}
