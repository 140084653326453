import { Button, Checkbox, DialogActions, FormControlLabel, Grid, Typography } from '@mui/material'
import { noSeeMarkPromo } from '../../../../../../../api/promo'
import { useState } from 'react'

export default function ActionBtns({ promos, index, setIndex, refetch }) {
    const [checked, setChecked] = useState(false)
    
    const handleNotSeeAgain = (event) => {
        if (event.target.checked) {
            noSeeMarkPromo(promos[index].id).then(() => { refetch(); setChecked(false) }).catch(() => {})
        }
    }

    const handleNext = () => {
        let dd = index + 1
        if (dd <= promos.length - 1) {
            setIndex(dd)
        }
    }
    const handlePrevious = () => {
        let dd = index - 1
        if (dd >= 0) {
            setIndex(dd)
        }
    }

    return (
        <DialogActions>
            <Grid container sx={{ marginLeft: 2, marginRight: 2 }} direction='row' justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <FormControlLabel
                        label={<Typography>Дахин харахгүй</Typography>}
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleNotSeeAgain}
                            />
                        }
                    />
                </Grid>
                {promos.length > 1 ? (
                    <Grid item>
                        <Button
                            onClick={handlePrevious}
                            size='small'
                            disabled={index === 0}
                        >Өмнөх</Button>
                        <Button 
                            onClick={handleNext}
                            size='small'
                            disabled={index === promos?.length - 1}
                        >Дараах</Button>
                    </Grid>
                ) : ''}
            </Grid>
        </DialogActions>
    )
}
