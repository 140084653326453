import { getSettings, updateSettings } from '../../../../../api/company.js'
import { getToast } from '../../../../auth/helpers/ToastAlert.js'
import { isTokenValid } from '../../../../../utils/functions.js'
import { paperStyle } from '../../../../../utils/constants.js'
import ShowAllSwitch from './ShowAllSwitch.js/index.js'
import { Box, Paper, Typography } from '@mui/material'
import { useEffect } from 'react'
import { keys } from 'underscore'
import { useState } from 'react'
import * as React from 'react'

const sMap = {
    showQty: 'Зөвхөн үлдэгдэлтэй барааг харуулах',
    showCat: 'Барааны ангилал харуулах',
    showMnfr: 'Үйлдвэрлэгчийн шүүлтүүр харуулах',
    showVndr: 'Нийлүүлэгчийн шүүлтүүр харуулах'
}

export default function Setups() {
    const { user_id } = isTokenValid()
    const [state, setState] = useState(null)
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    useEffect(() => {
        getSettings(user_id)
            .then(res => { setState(res?.data) })
            .catch(e => { console.log("err: ", e) })
    }, [])

    const handleChange = (event) => {
        const newVal = { [event.target.name]: event.target.checked }
		updateSettings(user_id, newVal).then(_ => {
			setState({ ...state, ...newVal })
		}).catch(e => { setErr('Түр хүлээгээд дахин оролдоно уу!'); console.log("ERR: ", e) })
    }

    return (
        <Paper sx={{ overflow: 'hidden', ...paperStyle, p: 3 }}>
            <Box sx={{ ml: 1 }}>
                <Typography fontSize='20px' sx={{ mb: 1 }}>Тохиргоонууд</Typography>
            </Box>
            <Box sx={{ ml: 2 }}>
                <Typography fontSize='18px'>Нүүр хуудсанд:</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                    { keys(sMap).map(k => <ShowAllSwitch key={k} title={sMap[k]} fieldName={k} state={state} handleChange={handleChange} />) }
                </Box>
            </Box>
            { getToast(Boolean(err), errOff, err, 'warning') }
        </Paper>
    )
}
