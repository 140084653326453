import { activatePromo, deletePromo, getPromo, getPromoCols, getPromos, markPromo } from '../../../../../api/promo'
import { createContext, useCallback, useContext, useState } from 'react'
import { Table, TableBody, TableContainer } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import TablePages from '../../../../common/TablePages'
import { useQuery } from 'react-query'
import { useAdminCtx } from '../../..'
import AddPromo from './AddPromo'
import Head from './Head'
import Row from './Row'

const PromoCtx = createContext()
export const usePromoCtx = () => useContext(PromoCtx)

const excCols = ['bundle_price', 'is_per', 'updated_at', 'has_gift']
export default function PromoTable() {
    const { alertWarning, alertSuccess, setMsg } = useAdminCtx()
    // filters
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [filters, setFilters] = useState([])
    const { data: colsData } = useQuery(['get_promo_cols'], getPromoCols, { onError: alertWarning })
    const headCols = colsData?.data?.filter(col => !excCols.includes(col.field_name))||[]
    //console.log("C: ", colsData)
    const colSpan = headCols?.length ? headCols.length + 2 : null
    const [promos, setPromos] = useState([])
    const [count, setCount] = useState(0)
    const getDt = (data) => { setPromos(data?.data?.results||[]); setCount(data?.data?.count||0) }
    const { refetch, isFetching } = useQuery(['get_promos', filters, page, pageSize], getPromos, { onError: alertWarning, onSuccess: (data) => getDt(data) })
    //console.log("prm: ", isFetching)

    const activate = useCallback((promoId) => {
        activatePromo(promoId)
            .then(res => {
                setPromos(prev => prev.map(p => p.id === promoId ? { ...p, is_active: res?.data?.is_active } : p))
                alertSuccess()
            })
            .catch(err => { alertWarning(); console.log("ERR: ", err) } )
    }, [])
    const toMark = useCallback((promoId) => {
        markPromo(promoId)
            .then(res => {
                setPromos(prev => prev.map(p => p.id === promoId ? { ...p, is_marked: res?.data?.is_marked } : p))
                alertSuccess()
            })
            .catch(err => {
                alertWarning();
                const limitErr = err?.response?.data?.limit
                if (limitErr) { setMsg({ m: limitErr, s: 'warning' }) }
                console.log("ERR: ", err)
            } )
    }, [])
    const remove = useCallback((promoId) => {
        deletePromo(promoId)
            .then(() => { refetch(); alertSuccess() })
            .catch(() => alertWarning())
    }, [])

    const [promo, setPromo] = useState(null)
    const getPromoToEdit = (promoId) => {
        getPromo(promoId)
            .then(res => setPromo(res?.data))
            .catch(() => alertWarning())
    }

    return (
        <PromoCtx.Provider value={{ activate, toMark, filters, setFilters }}>
            <TableContainer sx={{ height: '100%', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
                <Table stickyHeader aria-label="simple table">
                    <Head cols={headCols} />
                    <TableBody>
                        {
                            promos?.map(
                                (row, idx) => 
                                    <Row
                                        key={`${row?.id}`}
                                        idx={idx}
                                        row={row}
                                        cols={colsData?.data||[]}
                                        remove={remove}
                                        getPromoToEdit={getPromoToEdit}
                                    />
                                )
                        }
                    </TableBody>
                    <TablePages
                        count={count}
                        page={page}
                        setPage={setPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        colSpan={colSpan}
                        children2={
                            <AddPromo
                                refetch={refetch}
                                cols={colsData?.data||[]}
                                promo={promo}
                                setPromo={setPromo}
                            />
                        }
                    />
                </Table>
            </TableContainer>
        </PromoCtx.Provider>
    )
}
