import { useRef, useState } from 'react'
import ImageMagnifier from './ImageMagnifier'
import { Box } from '@mui/material'

const mouseBoxWith = 150
const mouseBoxHeight = 150

const MainImage = ({ img }) => {
	const boxRef = useRef(null)
	const imageBox = boxRef?.current ? boxRef?.current?.getBoundingClientRect() : {}
	const startBox = {
	left: imageBox.left + mouseBoxWith / 2,
		top: imageBox.top + mouseBoxHeight / 2,
		right: imageBox.right - mouseBoxWith / 2,
		bottom: imageBox.bottom - mouseBoxHeight / 2
	}

	const [mouseBoxLeft, setMouseBoxLeft] = useState(0)
	const [mouseBoxTop, setMouseBoxTop] = useState(0)
  
	const [isHover, setIsHover] = useState(false)
	const hoverOff = () => {
		setIsHover(false)
		setMouseBoxLeft(0)
		setMouseBoxTop(0)
	}
	const [isMagnifierHover, setIsMagnifierHover] = useState(false)
	const [mouseXY, setMouseXY] = useState(null)
  
	const handleMove = (e) => {
		const { clientX, clientY } = e
		if (isEntered(startBox, e)) {
			const diffLeft = clientX - startBox.left
			const diffTop = clientY - startBox.top
			setMouseBoxLeft(diffLeft)
			setMouseBoxTop(diffTop)
			setMouseXY({ x: clientX - imageBox.left, y: clientY - imageBox.top })
		}
	}

  	const isEntered = (box, e) => {
		const { clientX, clientY } = e
		const { left, top, right, bottom } = box
		return (clientX <= right && clientX >= left && clientY >= top && clientY <= bottom) ? true : false
	}

	return (
		<Box 
			ref={boxRef}
			sx={{ 
				width: '450px',
				height: '450px',
				textAlign: 'center',
				position: 'relative',
				m: '0 auto',
				marginBottom: 2,
				border: '1px solid',
				borderColor: '#e0e0e0'
			}}
			onMouseMove={handleMove}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={hoverOff}
		>
			<img src={img??require('./noimage_small.jpg')} style={{ width: '100%', height: '100%' }}/>
			<>
			{
				img
					? 	<>
							<Box
								sx={{
									position: 'absolute',
									width: `${mouseBoxWith}px`,
									height:  `${mouseBoxHeight}px`,
									border: 1,
									left: mouseBoxLeft,
									top: mouseBoxTop,
									opacity: isHover && !isMagnifierHover && mouseBoxLeft > 0 && mouseBoxTop > 0 ? '100%' : '0%'
								}}
							/>
							<ImageMagnifier
								img={img}
								mouseXY={mouseXY}
								setIsMagnifierHover={setIsMagnifierHover}
								style={{
									transition: 'all 0.2s linear',
									display: (isHover && !isMagnifierHover) ? 'block' : 'none',
									zIndex: 1000,
									position: 'absolute',
									left: '450px', top: -1,
									border: '1px solid',
									borderColor: '#e0e0e0'
								}}
							/>
						</>
					: 	null
			}
			</>
		</Box>
	)
}

export default MainImage