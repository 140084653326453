import { selectDelman } from '../../../../../../../api/staff'
import { Box, MenuItem } from '@mui/material'
import { useAllocationCtx } from '..'

export default function ContextMenuBox() {
    const { delmans, cm, setCm, selected, setSelected, toSelect, setToSelect, setMsg, alertWarning, refetchAllStops } = useAllocationCtx()
    const handleSelectDelman = (id) => {
        if (!toSelect||selected?.length === 0) {
            setMsg({ m: 'Байршлууд сонгоно уу!', s: 'warning' })
            setCm(null)
            return
        }
        const orderIds = selected.reduce((acc, cur) => ([ ...acc, ...cur.orderIds ]), [])
        selectDelman({ orderIds, delmanId: id })
            .then(_ => {
                refetchAllStops()
                setMsg({ m: 'Амжилттай!', s: 'success' })
                setSelected([])
                setToSelect(false)
            })
            .catch(error => { 
                const isShipStarted = error?.response?.data?.order?.[0] === "order on the way!"
                if (isShipStarted) { setMsg({ m: 'Түгээлт эхэлсэн байна!', s: 'warning' }) }
                else { alertWarning() }
                console.log("ERR: ", error)
            })
            .finally(() => setCm(null))
    }

    return (
        <Box
            sx={{ 
                position: 'absolute',
                top: cm.y,
                left: cm.x,
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 2px 10px #000',
                borderRadius: '5px',
                bgcolor: 'white',
                p: '2px'
            }}
        >
            {
                delmans?.map(d => (
                    <MenuItem 
                        key={d.id}
                        onClick={() => handleSelectDelman(d.id)}
                        sx={{ 
                            bgcolor: 'white', color: 'primary.light', fontSize: '14px',
                            '&:hover': { bgcolor: 'primary.light', color: 'white' },
                            transition: 'ease-in 0.15s', borderRadius: '5px'

                        }}
                    >{d.name||''}</MenuItem>
                ))
            }
        </Box>
    )
}
