import { useAddToCart } from '../../../../hooks'
import { Box, Typography } from '@mui/material'
import { btnStyle } from '.'


const AddToCart = ({ product, isHover }) => {
    const [ handleAddItem, alertMsg, alertOff, isSuccess, isIncluded, _ ] = useAddToCart({ product })

    const cartStyle = { ...btnStyle, left: isHover ? -20 : -7 }
    
    return (
        <Box
            sx={{ 
                display: 'flex',
                transition: 'all 0.2s linear',
                position: 'relative',
                opacity: isHover ? '100%' : '0%',
            }}
        >
            <Typography
                onClick={ handleAddItem }
                sx={{ ...cartStyle, p: '2px 4px' }}
            >{'Сагс' + `${ isIncluded ? 'наас хасах' : 'анд нэмэх' }`}</Typography>
        </Box>
    )
}

export default AddToCart