import { Close } from '@mui/icons-material';
import { InputAdornment, TableCell, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export default function CharFilterCell({ setFilters, filters, field, staffs }) {
  const [value, setValue] = useState('');
  const searchTimeoutRef = useRef(null);
  useEffect(() => {
    if (value) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current)
        searchTimeoutRef.current = null
      }
      searchTimeoutRef.current = setTimeout(() => {
        setFilters(prev => {
          const f = { k: field, v: value, o: '=' }
          if (prev?.length) {
            if (prev.map(p => p.k).includes(field)) {
              return prev.map(p => p?.k === field ? f : p)
            } else { return [...prev, f] }
          }
          return [f]
        })
      }, 650)
    } else {
      if (filters != null) {
        setFilters(prev => prev.filter(f => f.k !== field))
      }
    }
    return () => { clearTimeout(searchTimeoutRef.current) }
  }, [value])

  const clearName = () => { setValue(''); setFilters(prev => prev.filter(f => f.k !== field)) }

  return (
    <TableCell>
      <TextField
        placeholder='Хайх'
        value={value}
        onChange={(e) => { setValue(e.target.value) }}
        InputProps={{
          endAdornment: (<InputAdornment sx={{ backgroundColor: 'transparent' }}>
            <Close
              sx={{
                fontSize: '15px', color: 'primary.light',
                backgroundColor: 'transparent',
                '&:hover': { cursor: 'pointer' },
                display: value ? 'block' : 'none',
              }}
              onClick={clearName}
            />
          </InputAdornment>)
        }}
        inputProps={{ style: { padding: '2px 8px' } }}
        sx={{
          '& fieldset': { borderRadius: 0, borderColor: 'primary.light' },
          '& .MuiInputBase-input::placeholder': {
            color: 'primary.light', textAlign: 'center',
            fontSize: '13px', opacity: 1
          },
          border: 'none', width: '100%'
        }}
        size='small'
      />
    </TableCell>
  )
}
