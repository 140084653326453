// fns

export const capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1).toLowerCase()

export const popCurrent = (choices, curKey) => Object.fromEntries(Object.entries(choices).filter(([key]) => parseInt(key) !== curKey))

export const arrToObj = (arr, keyKey, valueKey) => arr?.reduce((acc, cur) => {
	const k = cur[keyKey]
	if (!acc[k]) { acc[k] = cur[valueKey] }
	return acc
}, {})

export const isNonActive = (order) => ['Биелсэн', 'Цуцлагдсан'].includes(order?.status)