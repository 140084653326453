import { LocationOn } from '@mui/icons-material'
import { SvgIcon } from '@mui/material'

export default function LocationIcon({ isSelected, color, delmanId, dids }) {
    return (
        <>
        {
            dids?.length > 1
                ?   <SvgIcon
                        sx={{
                            '& path': {
                                fill: `url(#gradient_${delmanId||1})`,
                            },
                            fontSize: '60px',
                            border: isSelected ? 1 : 'none',
                            borderColor: isSelected ? color : 'none'
                        }}
                    >
                        <defs>
                            <linearGradient id={`gradient_${delmanId||1}`} x1="0%" y1="0%" x2="100%" y2="100%">
                                <stop offset="10%" style={{ stopColor: `${color}`, stopOpacity: 1 }} />
                                <stop offset="90%" style={{ stopColor: `#000`, stopOpacity: 1 }} />
                            </linearGradient>
                        </defs>
                        <LocationOn
                            sx={{ 
                                fontSize: '60px',
                                filter: 'drop-shadow(1px 2px 1px rgba(0,0,0,0.4))',
                            }} 
                        />
                    </SvgIcon>
                :   <LocationOn
                        sx={{
                            color,
                            fontSize: '60px',
                            filter: 'drop-shadow(1px 2px 1px rgba(0,0,0,0.4))',
                            border: isSelected ? 1 : 'none',
                            borderColor: isSelected ? color : 'none'
                        }}
                    />
        }
            
        </>
    )
}
