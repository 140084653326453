import { getDelmansColor } from '../../../../utils'
import Delman from './Delman'

export default function Delmans({ delmanLocs }) {
    const dc = getDelmansColor(delmanLocs)||[ ...delmanLocs ]
    return (
        <>
            {
                dc?.map(dl => (
                    <Delman
                        key={dl.delmanId}
                        data={dl}
                    />
                ))
            }
        </>
    )
}
