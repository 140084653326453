import Navbar from './components/navbar'
import { Toolbar } from '@mui/material'

export default function OutletBar({ children }) {
    return (
        <div
            style={{
                backgroundColor: '#f1f1f1',
                width: '100%',
                margin: 0, padding: 0,
                minHeight: '100vh',
                position: 'relative'
            }}
        >
            <Navbar />
            <Toolbar />
            {children}
        </div>
    )
}
