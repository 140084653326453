import { getUserSettings } from '../../../../api/user'
import DeleteUserAccount from './DeleteUserAccount'
import IsBasketClear from './IsBasketClear'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import ChangePin from './ChangePin'
import ChangePwd from './PwdChange'

export default function Setting() {
    const [settingsData, setSettingsData] = useState(null)
    useEffect(() => {
        getUserSettings()
            .then(res => { setSettingsData(res?.data) })
            .catch(err => { console.log("ERR: ", err) })
    }, [])
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', ml: 2 }}>
            <IsBasketClear settingsData={settingsData} />
            <ChangePin />
            <ChangePwd />
            <DeleteUserAccount />
        </Box>
    )
}
