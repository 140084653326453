import { Box, Pagination, Skeleton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

export default function Paginate({ count, totalPages, page, setPage, isFetching, sf }) {        
    const [scrollPos, setScrollPos] = useState(0)

    useEffect(() => {
        const handleScroll = () => { setScrollPos(window.scrollY) }
        window.addEventListener('scroll', handleScroll, { passive: true })
        return () => { window.removeEventListener('scroll', handleScroll) }
    }, [])

    const stick = scrollPos >= 65

    const color = stick ? 'white' : 'black'

    return (
        <Box sx={{ m: 0 }}>
            {count > 0
                ?   <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Pagination
                            size='small'
                            page={page}
                            count={totalPages}
                            onChange={(event, value) => setPage(value)}
                            showLastButton
                            sx={{
                                position: stick ? 'fixed' : 'initial', 
                                top: 120, 
                                zIndex: stick ? 10 : 0,
                                backgroundColor: stick ? 'primary.light' : 'transparent',
                                '& .MuiPaginationItem-text': { color }
                            }}
                        />
                    </Box>
                :   isFetching
                        ?   <Skeleton variant='text' width={385} height={30} />
                        :   sf !== 4 ? <Typography>Хайлтанд тохирох үр дүн олдсонгүй!</Typography> : null
            }
        </Box>
    )
}
