import GetLocation from '../RegistrationForm/GetLocation'
import { Box, Button, Typography } from '@mui/material'
import { updateBranchInfo } from '../../../api/company'
import { isEmpty } from 'underscore'
import { useState } from 'react'

export default function RegisterLocation({ branches, handleClose, tk, setAlert }) {
    const [isApiFailed, setIsApiFailed] = useState(false)
    const [isGettingAddress, setIsGettingAddress] = useState(false)

    const [addressInfo, setAddressInfo] = useState(null)
    const branch = branches?.[0]
    const handleUpdate = () => {
        if (isEmpty(addressInfo) || !branch?.id) return
        const data = { ...addressInfo, branchId: branch?.id }
        updateBranchInfo(tk, data)
            .then(_ => { setAlert({ m: 'Амжилттай!', s: 'success' }) })
            .catch(err => { setAlert({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }); console.log("err: ", err) })
            .finally(() => { handleClose() })
    }

    return (
        <Box sx={{ width: '700px', height: '100%', p: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
                <Typography sx={{ p: '4px', m: '4px' }}>
                    <span style={{ color: 'primary.light', fontWeight: 600 }}>{branch?.name}</span>-н хаягийг газрын зураг дээр сонгоно уу.
                </Typography>
            </Box>
            <GetLocation
                setAddressInfo={setAddressInfo}
                setIsApiFailed={setIsApiFailed}
                setIsGettingAddress={setIsGettingAddress}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
                <Button
                    onClick={ handleUpdate }
                    size='small'
                    variant='contained'
                    sx={{ p: '4px', width: '150px' }}
                    disabled={isGettingAddress}
                >Илгээх</Button>
            </Box>
        </Box>
    )
}
