
// for categories
const getChildIds = (arrayOfObjects, arr) => {
    arrayOfObjects.forEach(obj => {
        arr.push(obj.id)
        if (obj?.children?.length > 0) {
            getChildIds(obj.children, arr)
        }
    })
    return arr
}
export const getChilds = (arrayOfObjects) => {
    return arrayOfObjects.map(obj => {
        let changed = {
            ...obj,
            childs: getChildIds(obj.children, []),
            children: getChilds(obj.children)
        }
        return changed
    })
}

export const filterBoxStyle = { 
    borderRadius: 2, backgroundColor: 'white', width: '250px',
    m: 1, mt: 0, p: 1, pt: 1, boxShadow: 5
}