import TBody from "../../components/confirmation/Main/Companies/TBody"
import Companies from "../../components/confirmation/Main/Companies"
import Notif from "../../components/confirmation/Main/Notif"
import Otp from "../../components/confirmation/Main/Otp"

export const confirmRoutes = [
    {
        path: 'main',
        element: <Companies />,
        children: [
            {
                index: true,
                element: <TBody />,
            },
            {
                path: 'list',
                element: <TBody />,
            },
        ]
    },
    {
        path: 'otp',
        element: <Otp />
    },
    {
        path: 'notif',
        element: <Notif />
    },
]