import { TableCell, TableFooter, TableRow, Typography } from '@mui/material'
import { toPrice } from '../../../../utils/functions'


export default function Footer({ totalCount, totalPrice }) {
    return (
        <TableFooter style={{ position: 'sticky', bottom: 0 }}>
            <TableRow sx={{ '& > td': { py: '4px' } }}>
                <TableCell colSpan={3} sx={{ borderColor: 'transparent' }} />
                <TableCell colSpan={1} align='right'>
                    <Typography sx={{ fontSize: '14px', color: 'secondary.main', color: 'primary.main', fontWeight: 'bold', fontStyle: 'italic' }}>Нийт тоо, ширхэг:</Typography>
                </TableCell>
                <TableCell align='right'>
                    <Typography sx={{ fontSize: '14px', color: 'secondary.main', color: 'primary.main' }}>{totalCount}</Typography>
                </TableCell>
            </TableRow>
            <TableRow sx={{ '& > td': { py: '4px' } }}>
                <TableCell colSpan={3} sx={{ borderColor: 'transparent' }} />
                <TableCell colSpan={1} align='right'>
                    <Typography sx={{ fontSize: '14px', color: 'secondary.main', color: 'primary.main', fontWeight: 'bold', fontStyle: 'italic' }}>Үнийн дүн:</Typography>
                </TableCell>
                <TableCell align='right' colSpan={2}>
                    <Typography sx={{ fontSize: '14px', color: 'secondary.main', color: 'primary.main' }}>{toPrice(totalPrice)}</Typography>
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}
