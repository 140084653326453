import { TableCell, TableRow } from "@mui/material"

export function formatDate(dateStr) {
	let date = new Date(dateStr)
	let formattedDatetimeStr = date.toISOString().slice(0, 19).replace("T", " ")
	return formattedDatetimeStr
}

export default function Row(props) {
	const { row, idx, page, pageSize } = props;
	const rowCustTypes = row.cust_type?.map(ct => ct.name).join(', ')||'-'
	const cp = row.is_cmp ? 'Компани' : row.is_cmp === false ? 'Хувь хүн' : 'Тодорхойгүй'
	const created = row?.created ? formatDate(row.created) : ''
	return (
		<TableRow key={`${idx + 1}`} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "& > td": { padding: "2px 4px", fontSize: "13px" } }}>
			<TableCell>
				{page === 1 ? idx + 1 : page * pageSize + idx + 1}
			</TableCell>
			<TableCell align="left" sx={{ paddingLeft: '15px !important' }}>{row.name}</TableCell>
			<TableCell align="left">{row.rn}</TableCell>
			<TableCell align="left">{row.email}</TableCell>
			<TableCell align="right">{row.phone}</TableCell>
			<TableCell align="center">{cp}</TableCell>
			<TableCell align="center">{rowCustTypes}</TableCell>
			<TableCell align="center">{row.note}</TableCell>
			<TableCell align="center">{created}</TableCell>
		</TableRow>
	)
}
