import { Dashboard, ShoppingCart, People, Settings, ProductionQuantityLimitsRounded, List, LocalShipping, Redeem, Dvr } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material'
import { primaryLight, secondaryLight } from '../../utils/constants'

export const boardHeight = 'calc(100vh - 100px)'

export const menus = [
	{
		icon: <ShoppingCart sx={{ color: 'secondary.light' }} />,
		name: 'Захиалга',
		route: 'orders',
	},
	{
		icon: <Redeem sx={{ color: 'secondary.light' }} />,
		name: 'Promotion',
		route: 'promotion',
	},
	{
		icon: <People sx={{ color: 'secondary.light' }} />,
		name: 'Харилцагч',
		route: 'customers',
	},
	{
		icon: <Dashboard sx={{ color: 'secondary.light' }} />,
		name: 'Бараа',
		route: 'products/list',
		subMenus: [
		{
			icon: <ProductionQuantityLimitsRounded sx={{ color: 'secondary.light' }} />,
			name: 'Жагсаалт',
			route: 'products/list',
		},
		{
			icon: <List sx={{ color: 'secondary.light' }} />,
			name: 'Ангилал',
			route: 'products/category',
		},
		]
	},
	{
	   icon: <LocalShipping sx={{ color: 'secondary.light' }} />,
	   name: 'Түгээлт',
	   route: 'shipment',
	},
	{
		icon: <Settings sx={{ color: 'secondary.light' }} />,
		name: 'Тохиргоо',
		route: 'settings',
	},
	{
		icon: <Dvr sx={{ color: 'secondary.light' }} />,
		name: 'Лог',
		route: 'system_log',
	},
]

export const VerifyDialog = ({ msg, open, setOpen, handleOk }) => {
	const handleClose = () => { setOpen(false) }
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle><Typography sx={{ fontSize: '17px', color: primaryLight, fontWeight: 'bold' }}>{msg}</Typography></DialogTitle>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
				<Button onClick={handleOk} size='small' variant='contained' sx={{ backgroundColor: secondaryLight }}>Тийм</Button>
				<Button onClick={handleClose} size='small' variant='contained'>Үгүй</Button>
			</DialogActions>
		</Dialog>
	)
}

// delman color
export const dColors = [
    '#00A373',
    '#CD853F',
    '#00007C',
    '#40E0D0',
    '#ff4040',
    '#00BFFF',
    '#8B390D',
    '#CC33CC',
    '#228B22',
    '#F27C6E',
    '#FFE417',
    '#663399',
    '#FFA07A',
    '#32CD32',
    '#800000',
    '#FF8C00',
    '#00FFFF',
    '#C71585',
    '#BDB76B',
    '#7B68EE',
]

export const defaultDelmanClr = "#004EFF"

export const randomColor = () =>  `#${Math.floor(Math.random()*16777215).toString(16)}`

export const saveDelmansColor = (delmans)=> {
    let colorsToSelect = [ ...dColors ]
    const delmansColor = delmans.map(d => ({ ...d, color: colorsToSelect?.length > 0 ? colorsToSelect.shift() : randomColor() }))
    window.localStorage.setItem(new Date().toISOString().slice(0, 10), JSON.stringify(delmansColor))
    return delmansColor
}

export const getDelmansColor = (delmanLocs) => {
    try {
        let e = window.localStorage.getItem(new Date().toISOString().slice(0, 10))
        if (e) {
            e = JSON.parse(e)
			let colorsToSelect = [ ...dColors ]
			return delmanLocs.map(dl => ({ ... dl, color: e?.find(d => d.id === dl.delmanId)?.color||colorsToSelect.shift()||randomColor() }))
        }
    }
    catch {
        return null
    }
    return null
}

export const getDelmanColor = (delmanId) => {
    try {
        let e = window.localStorage.getItem(new Date().toISOString().slice(0, 10))
        if (e) {
            e = JSON.parse(e)
			const f = e?.find(d => d.id === delmanId)?.color
			if (f) {
				return f
			} else {
				let colorsToSelect = [ ...dColors ]
				return colorsToSelect.shift()||randomColor()
			}
        } else {
			let colorsToSelect = [ ...dColors ]
			return colorsToSelect.shift()||randomColor()
		}
    }
    catch {
        return randomColor()
    }
}