import { VerifyDialog } from '../../../../../../../utils'
import { IconButton, TableCell } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useState } from 'react'

export default function DeleteBtn({ handleDelete, groupId }) {
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handlePropagation = (e) => { e.stopPropagation(); console.log("Eee: ", e)}

    return (
        <TableCell align='center' onClick={handlePropagation}>
            <IconButton sx={{ p: 0 }} onClick={handleOpen}><Delete sx={{ '&:hover': { color: 'primary.main' } }} /></IconButton>
            <VerifyDialog msg='Хадгалсан жагсаалтыг устгахдаа итгэлтэй байна уу?' open={open} setOpen={setOpen} handleOk={() => handleDelete(groupId)} />
        </TableCell>
    )
}
