import { addBasketItem, getBasket, removeFromBasket, updateItemQty } from '../../api/basket'
import { createSellerOrder, searchCustomer, searchProduct } from '../../api/seller'
import { Dashboard, PersonSearch, ShoppingCart } from '@mui/icons-material'
import { createContext, useContext, useEffect, useState } from 'react'
import { getToast } from '../auth/helpers/ToastAlert'
import { checkProductQty } from '../../api/product'
import { Badge, Container } from '@mui/material'
import FixedBottomNavigation from './bottomnav'
import SearchCustomer from './SearchCustomer'
import { useQuery } from 'react-query'

const tabs = [
    { idx: 0, label: 'Харилцагч', icon: () => <PersonSearch sx={{ fontSize: '35px' }} /> },
    { idx: 1, label: 'Бараа', icon: () => <Dashboard sx={{ fontSize: '35px' }} /> },
    { idx: 2, label: 'Сагс', icon: (bc) => <Badge badgeContent={bc} color={'secondary'} sx={{
        '& .MuiBadge-badge': {
          width: '30px',
          height: '30px',
          fontSize: '20px',
          p: '1px',
          right: '-6px',
          borderRadius: '50%',
        },
      }}><ShoppingCart sx={{ fontSize: '35px' }} /></Badge>},
]

const SellerCtx = createContext(null)
export const useSellerCtx = () => useContext(SellerCtx)

export default function Seller() {
    const [tabIdx, setTabIdx] = useState(0)
    const [seval, setSeVal] = useState(null)
    useEffect(() => { if (seval) {setSeVal(null)} }, [tabIdx])
    console.log("TB: ", seval)
    const isSearchCustomer = tabIdx === 0// && Boolean(seval)
    const isSearchProduct = tabIdx === 1// && Boolean(seval)

    // page
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);

    const { data, isFetched, isFetching } = useQuery(['searchCustomer', seval, page, pageSize], searchCustomer, { enabled: isSearchCustomer })
    console.log("C: ", data, isFetched)
    const { data: pdata, isFetched: pisFetched, isFetching: pisFetching } = useQuery(['searchProduct', seval, page, pageSize], searchProduct, { enabled: isSearchProduct })
    console.log("P: ", pdata, pisFetched)

    const [order, setOrder] = useState({
        customer_id: null,
        basket_id: null,
        payType: 'L',
        note: null,
    })
    const custName = order?.customer_id ? data?.data?.results?.find(c => c.id === order?.customer_id)?.name : null

    // basket
    const [basket, setBasket] = useState(null)
    const fetchBasket = () => {
        getBasket()
            .then(res => {
                const newBask = res?.data
                if (newBask) { setBasket(newBask); setOrder(prev => ({ ...prev, basket_id: newBask.id })) } else { setBasket(null) }
            })
            .catch(e => { console.log("ERRs: ", e) })
    }
    useEffect(() => { fetchBasket() }, [])

    const [msg, setMsg] = useState(null)
    const waitAlert = () => { setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) }
    // add to basket
    const add = ({ productId, qty }) => {
        addBasketItem({ product: productId, qty })
            .then(_ => { fetchBasket(); setMsg({ m: 'Сагсанд амжилттай нэмэгдлээ!', s: 'success' }) })
            .catch(e => { waitAlert(); console.log("add err: ", e) })
    }
    // remove from basket
    const remove = (itemId) => {
        removeFromBasket(itemId)
            .then(_ => { fetchBasket(); setMsg({ m: 'Сагснаас амжилттай хасагдлаа!', s: 'success' }); })
            .catch(_ => { waitAlert() })
    }
    // update basket item qty
    const update = ({ itemId, qty }) => {
        updateItemQty(itemId, qty)
            .then(_ => {
                fetchBasket();
                //setMsg({ m: 'Амжилттай!', s: 'success' });
            })
            .catch(_ => { waitAlert() })
    }
    // check qty
    const checkQty = (product, qty) => {
        const id = product?.itemname_id || product?.id
        if (id && qty && qty > 0) {
            checkProductQty({[id]: qty}).then(r => {
                const l = r?.data?.[id]
                if (l === null) { setMsg({ m: 'Бараа дууссан байна!', s: 'warning' }); }
                else if (l === false) { setMsg({ m: 'Үлдэгдэл хүрэлцэхгүй байна!', s: 'warning' }); }
                else {
                    const idToFind = product?.itemname_id ? 'product_itemname_id' : 'product_id'
                    const itemFound = basket?.items?.find(i => i?.[idToFind] === id)
                    if (itemFound) { update({ itemId: itemFound?.id, qty }) }
                    else { add({ productId: product.id, qty }) }
                }
            })
            .catch(e => { waitAlert(); console.log("E: ", e) })
        }
    }
    console.log("order: ", basket)
    const createOrder = () => {
        createSellerOrder(order)
            .then(res => { console.log("RES: ", res); setMsg({ m: 'Захиалга амжилттай үүслээ!', s: 'success' }) })
            .catch(e => { console.log("ERR: ", e); waitAlert() })

    }
    
    return (
        <SellerCtx.Provider value={{ custName, basket, checkQty, remove, order, setOrder, createOrder }}>
            <Container sx={{ p: 0 }}>
                {getToast(Boolean(msg?.m), () => setMsg(null), msg?.m, msg?.s, 1500 )}
                <SearchCustomer tabIdx={tabIdx} seval={seval} setSeVal={setSeVal}  />
                <FixedBottomNavigation
                    cData={data}
                    pData={pdata}
                    tabs={tabs}
                    tabIdx={tabIdx}
                    setTabIdx={setTabIdx}
                    order={order}
                    setOrder={setOrder}
                    basket={basket}
                />
            </Container>
        </SellerCtx.Provider>
    )
}


function isCompanyReg(s) {
    return /^\d{7}$/.test(s)
}
function isCitizenReg(s) {
    const pattern = /^[\u0410-\u042F\u0430-\u044F]{2}\d{8}$/;
    return pattern.test(s);
}