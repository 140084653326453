import { useEffect, useState } from 'react'
import { getOs } from '../../../../api/confirm'
import { Box } from '@mui/material'
import OTable from './OTable'

const cols = ['№', 'Имэйл', 'Утас', 'Код', 'Огноо']

export default function Otp() {
    const [reg, setReg] = useState(null)
    const [reset, setReset] = useState(null)

    useEffect(() => {
        getOs()
            .then(res => {
                const { reg, reset } = res?.data||{}
                if (reg) { setReg(reg) }
                if (reset) { setReset(reset) }
            }).catch(e => { console.log("oE: ", e) })
    }, [])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            {reg?.length > 0  && <OTable rows={reg} cols={cols} title={'Бүртгэл баталгаажуулах код'} />}
            {reset?.length > 0  && <OTable rows={reset} cols={cols} title={'Нууц үг сэргээх код'} />}
        </Box>
    )
}
