import { toPrice } from "../../../../../../utils/functions"
import { Box, Typography } from "@mui/material"

export default function ExtraInfo({ props }) {
    const { barcode, price, in_stock, expDate } = props
    const date1 = expDate ? expDate.replace('T', ' ') : null;
    const date2 = date1 ? date1.split(' ')[0] : null;
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <Typography fontSize='22px' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>{toPrice(price)}</Typography>
            {Boolean(date2) && <Typography fontSize='22px' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>{date2}</Typography>}
        </Box>
    )
}
