import { Button, Dialog, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { useQuery } from "react-query"
import { isEmpty, keys } from "underscore"
import { getCustomer, getCustomers } from "../../../../../api/company"
import { scrollStyle } from "../../../../../utils/constants"
import { getToast } from '../../../../auth/helpers/ToastAlert'
import TablePages from "../../../../common/TablePages"
import AddCustomer from './AddCustomer'
import HeadCell from "./Cells/HeadCell"
import FilterRow from "./Filter"
import Row from "./Row"

export default function Customers() {
	const [columns, setColumns] = useState({
		name: { label: "Нэр", order: "", align: "center" },
		rd: { label: "РД", order: "", align: "center" },
		is_company: { label: "Компани эсэх", order: "", align: "center" },
		debt: { label: "Нийт өр", order: "", align: "center" },
		credit: { label: "Зээлийн лимит", order: "", align: "center" },
		badCnt: { label: "Найдваргүйн тоо", order: "", align: "center" },
		isBad: { label: "Найдваргүй", order: "", align: "center" },
		email: { label: "И-мэйл", order: "", align: "center" },
		phone: { label: "Утасны дугаар", order: "", align: "center" },
		orders_cnt: { label: "Захиалга тоо", order: "", align: "center" },
		orders_sum: { label: "Захиалга дүн", order: "", align: "center" },
		created: { label: "Огноо", order: "", align: "center" },
	})
	// ordering
	const handleOrder = (fieldName) => {
		const c = columns?.[fieldName]
		const v =
			c?.order === ""
				? fieldName
				: c?.order === fieldName
					? `-${fieldName}`
					: ""
		setColumns({ ...columns, [fieldName]: { ...c, order: v } });
	}

	const orderParam = keys(columns)?.reduce((acc, k) => {
		const cur = columns?.[k]?.order;
		if (cur !== "") {
			acc += '&ordering=' + cur
		}
		return acc
	}, "")

	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(20)
	const [filtering, setFiltering] = useState(null)

	const [partners, setPartners] = useState([])
	const [count, setCount] = useState(0)
	const getData = (res) => { setPartners(res?.data?.results || []); setCount(res?.data?.count || 0) }
	const { data, refetch: refetchAllCusts } = useQuery(["get_customers", page, pageSize, orderParam, filtering], getCustomers, { onSuccess: getData })

	useEffect(() => {
		if (!isEmpty(data?.data)) {
			setPartners(data?.data?.results || [])
		} else {
			setPartners([])
		}
	}, [data?.data])

	const fetchCustomers = () => {
		refetchAllCusts();
	}

	const hhRef = useRef(null)

	const [open, setOpen] = useState(false)
	const handleOpen = () => {
		setCust(null);
		setOpen(true);
	}
	const handleClose = () => { setOpen(false) }

	const [sucAlert, setSucAlert] = useState(false)
	const alertOn = () => { setSucAlert(true); handleClose(); }
	const alertOff = () => { setSucAlert(false) }

	const [cust, setCust] = useState(null)
	const getCustToEdit = (custId) => {
		getCustomer(custId)
			.then((res) => {
				setCust(res.data);
				handleOpen();
			})
	}

	return (
		<>
			{/* <Grid container direction='row' sx={{ justifyContent: "flex-end", alignItems: 'center' }}>
				<Button sx={{ marginBottom: '10px', marginTop: '5px', marginRight: '5px' }} size='small' variant='contained' onClick={handleOpen}>Харилцагч нэмэх</Button>
			</Grid> */}
			<TableContainer sx={{ mt: 1, height: '100%', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								"& > th": {
									fontSize: "12px",
									backgroundColor: "secondary.light",
									color: "white",
									borderRight: "1px solid white",
									p: "4px 4px",
									textAlign: "center",
								},
							}}
							ref={hhRef}
						>
							<TableCell>
								<Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
									№
								</Typography>
							</TableCell>
							{
								keys(columns)?.map((c) => (
									<HeadCell
										key={c}
										field={columns?.[c]}
										fieldName={c}
										handleOrder={handleOrder}
									/>
								))
							}
						</TableRow>
					</TableHead>
					<FilterRow filtering={filtering} setFiltering={setFiltering} />
					<TableBody>
						{
							partners?.map((row, idx) => (
								<Row
									key={`${idx}`}
									row={row}
									setPartners={setPartners}
									idx={idx}
									page={page}
									pageSize={pageSize}
									getCustToEdit={getCustToEdit}
								/>
							))
						}
					</TableBody>
					<TablePages
						count={count}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						colSpan={keys(columns)?.length + 1}
					/>
				</Table>
			</TableContainer>
			<Dialog open={open} onClose={handleClose}>
				<AddCustomer refetch={fetchCustomers} alertOn={alertOn} cust={cust} />
			</Dialog>
			{getToast(sucAlert, alertOff, 'Амжилттай нэмэгдлээ!')}
		</>
	)
}