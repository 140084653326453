import { IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { secondaryLight } from '../../../../utils/constants'
import { removeFromBasket } from '../../../../api/basket'
import { useAuthCtx } from '../../../../context/AuthCtx'
import { toPrice } from '../../../../utils/functions'
import { HighlightOff } from '@mui/icons-material'
import { calcItemPrice } from '../utils'
import CountInput from './CountInput'


export default function Row({ item, idx, qd, qtysErr, setQtysErr }) {
    const { fetchBasket } = useAuthCtx()
    const hasDiscount = item?.product_discount > 0
    const hasWholesale = Boolean(item.product_sale_qty) && Boolean(item.product_sale_price)
    const totalPrice = calcItemPrice(item)

    const handleRemove = () => {
        if (item?.id) {
            removeFromBasket(item.id)
                .then(res => { fetchBasket() })
                .catch(e => { console.log("ERRrm: ", e) })
        }
    }

    return (
        <TableRow
            sx={{
                '& > td': {
                    py: '2px', px: '6px', textAlign: 'right',
                    fontSize: '13px', color: 'primary.main'
                },
                '&:hover': { backgroundColor: '#f5f5f5' }
            }}
        >
            <TableCell>{idx + 1}</TableCell>
            <TableCell sx={{ textAlign: 'left !important' }}>{item?.product_name}</TableCell>
            <TableCell align='center'>{toPrice(item?.main_price)}</TableCell>
            <TableCell align='center'>
                {hasDiscount && <span style={{ fontWeight: 'bold', color: secondaryLight }}>{toPrice(item.price)}</span>}
                &nbsp;&nbsp;<span>{hasDiscount ? `(${item.product_discount}%)` : '-'}</span>
            </TableCell>
           <TableCell align='center'>
                {hasWholesale && <span style={{ fontWeight: 'bold', color: secondaryLight }}>{toPrice(item.product_sale_price)}</span>}
                &nbsp;&nbsp;<span>{hasWholesale ? `(${item.product_sale_qty})` : '-'}</span>
            </TableCell>
            <TableCell align='center'>
                <CountInput item={item} qd={qd} qtysErr={qtysErr} setQtysErr={setQtysErr} />
            </TableCell>
            <TableCell align='right'>{toPrice(totalPrice)}</TableCell>
            <TableCell align='right'>
                <Tooltip title={<Typography fontSize='14px'>Захиалгаас хасах</Typography>} placement='right'>
                    <IconButton onClick={handleRemove} sx={{ p: '4px' }}>
                        <HighlightOff fontSize='15px' sx={{ color: 'primary.light', '&:hover': { color: 'secondary.light' } }} />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )
}
