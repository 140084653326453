import { Comment, Phone } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"

export default function Contact({ props }) {
    const { phone, phone2, phone3, note } = props
    const phones = '' + phone + (phone2 ? ', ' + phone2 : '') + (phone3 ? ', ' + phone3 : '')
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            {Boolean(phones) && <Typography fontSize='22px' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}><Phone sx={{ fontSize: '25px', mr: 1 }} /> {phones}</Typography>}
            {Boolean(note) && <Typography fontSize='22px' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}><Comment sx={{ fontSize: '25px', mr: 1 }} /> {note}</Typography>}
        </Box>
    )
}
