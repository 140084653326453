import { Box, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material'
import { getAimag, getBag, getSum } from '../../../../api/order'
import { scrollStyle } from '../../../../utils/constants'
import { ArrowDropDown } from '@mui/icons-material'
import DropMenu from '../../../helpers/DropMenu'
import { useQuery } from 'react-query'
import { useRef } from 'react'
import { keys } from 'underscore'

export default function AddressForm({ setFieldValue, values, errors }) {
    //console.log("V: ", values, errors, touched)
    const { data: aimagData, isFetched } = useQuery('get_aimag', getAimag)
    const provinces = aimagData?.data?.sort((a, b) => a.id - b.id)
    
    const { data: sumData } = useQuery(['get_sum', values?.province], getSum, { enabled: Boolean(values?.province) })
    const districts = sumData?.data?.sort((a, b) => a.ner > b.ner ? 1 : -1 )
    
    const { data: bagData } = useQuery(['get_bag', values?.district], getBag, { enabled: Boolean(values?.district) })
    const khoroos = bagData?.data?.sort((a, b) => a.id - b.id )
    
    const getNer = (field, id) => {
        switch (field) {
            case 'province':
                return provinces?.find(a => a.id === id)?.ner
            case 'district':
                return districts?.find(a => a.id === id)?.ner
            case 'khoroo':
                return khoroos?.find(a => a.id === id)?.ner
            }
    }

    const getErrors = (name) => {
        const isErr = keys(errors)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }
    const r = useRef(null)

    return (
        <>
        {
            isFetched
                ?   <Grid container spacing={1} sx={{ width: '100%' }}>
                        <Grid item xs={4}>
                            <DropMenu
                                title={
                                    <TextField
                                        ref={r}
                                        label={values?.province ? values.province === 1 ? 'Хот' : 'Аймаг' : 'Аймаг/Хот'}
                                        size='small'
                                        inputProps={{ style: { fontSize: '15px', color: '#1b2e3c', cursor: 'pointer' }}}
                                        InputLabelProps={{ style: { fontSize: '15px' }, shrink: Boolean(values?.province) }}
                                        {...getErrors('province')}
                                        sx={{ minWidth: '100%' }}
                                        value={getNer('province', values?.province)}
                                        InputProps={{ readOnly: true, endAdornment: (<InputAdornment><ArrowDropDown /></InputAdornment>)}}
                                    />
                                }
                                detail={
                                    <Box sx={{ backgroundColor: 'white', boxShadow: 8, width: '100%', maxHeight: '250px', overflowY: 'auto', ...scrollStyle, '&::-webkit-scrollbar': { width: 6 } }}>
                                        {provinces?.map(a => <MenuItem key={`${a.id}`} onClick={_ => { setFieldValue('province', a.id) }} sx={{ width: `${r?.current?.getBoundingClientRect()?.width}px` }}>
                                                <Typography fontSize='15px' sx={{ color: '#1b2e3c' }}>{a.ner}</Typography>
                                            </MenuItem>)}
                                    </Box>
                                }
                                top={38}
                                left={0}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DropMenu
                                title={
                                    <TextField
                                        label={values?.province ? values.province === 1 ? 'Дүүрэг' : 'Сум' : 'Сум/Дүүрэг'}
                                        size='small'
                                        inputProps={{ style: { fontSize: '15px', color: '#1b2e3c', cursor: 'pointer' }}}
                                        InputLabelProps={{ style: { fontSize: '15px' }, shrink: Boolean(values?.district) }}
                                        {...getErrors('district')}
                                        sx={{ minWidth: '100%' }}
                                        value={getNer('district', values?.district)}
                                        disabled={!values?.province}
                                        InputProps={{ readOnly: true, endAdornment: (<InputAdornment><ArrowDropDown /></InputAdornment>)}}
                                    />
                                }
                                detail={
                                    <Box sx={{ backgroundColor: 'white', boxShadow: 8, width: '100%', maxHeight: '250px', overflowY: 'auto', ...scrollStyle, '&::-webkit-scrollbar': { width: 6 } }}>
                                        { districts?.map(d => <MenuItem key={`${d.id}`} onClick={_ => { setFieldValue('district', d.id) }} sx={{ width: `${r?.current?.getBoundingClientRect()?.width}px` }}>
                                                    <Typography fontSize='15px' sx={{ color: '#1b2e3c' }}>{d.ner}</Typography>
                                                </MenuItem>) }
                                    </Box>
                                }
                                top={38}
                                left={0}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DropMenu
                                title={
                                    <TextField
                                        label={values?.province ? values.province === 1 ? 'Хороо' : 'Баг' : 'Баг/Хороо'}
                                        size='small'
                                        inputProps={{ style: { fontSize: '15px', cursor: 'pointer', color: '#1b2e3c' }}}
                                        InputLabelProps={{ style: { fontSize: '15px' }, shrink: Boolean(values?.khoroo) }}
                                        {...getErrors('khoroo')}
                                        sx={{ minWidth: '100%' }}
                                        value={getNer('khoroo', values?.khoroo)}
                                        disabled={!values?.district}
                                        InputProps={{ readOnly: true, endAdornment: (<InputAdornment><ArrowDropDown /></InputAdornment>)}}
                                    />
                                }
                                detail={
                                    <Box sx={{ backgroundColor: 'white', boxShadow: 8, width: '100%', maxHeight: '250px', overflowY: 'auto', ...scrollStyle, '&::-webkit-scrollbar': { width: 6 } }}>
                                        { khoroos?.map(kh => <MenuItem key={`${kh.id}`} onClick={_ => { setFieldValue('khoroo', kh.id) }} sx={{ width: `${r?.current?.getBoundingClientRect()?.width}px` }}>
                                                    <Typography fontSize='15px' sx={{ color: '#1b2e3c' }}>{kh.ner}</Typography>
                                                </MenuItem>) }
                                    </Box>
                                }
                                top={38}
                                left={0}
                            />
                            
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label='Хаягийн дэлгэрэнгүй'
                                multiline
                                rows={4}
                                size='small'
                                inputProps={{ style: { fontSize: '15px', maxHeight: '150px', overflowY: 'hidden' } }}
                                InputLabelProps={{ style: { fontSize: '15px' } }}
                                {...getErrors('detailed')}
                                value={values?.detailed}
                                onChange={e => { setFieldValue('detailed', e?.target?.value) }}
                                disabled={!values?.khoroo}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                    </Grid>
                :   null
        }
        </>
    )
}
