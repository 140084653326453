import { Box, Dialog, DialogContent, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { scrollStyle } from '../../../../../../../../../utils/constants'
import { Close } from '@mui/icons-material'
import { useAllocationCtx } from '../../..'
import StopOrder from './StopOrder'

export default function StopOrders ({  open, handleClose , orderer, stopOrders, setStopOrders, delmans, delmanId }) {
    const { setMsg, alertWarning, refetch } = useAllocationCtx()
    const handleScroll = (e) => { e.stopPropagation() }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogContent
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    boxShadow: '0 2px 10px #000',
                    width: 'fit-content',
                    p: '2px',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '13px', m: 0, ml: 1, fontWeight: 'bold' }} noWrap>{orderer||'-'}</Typography>
                    <IconButton onClick={handleClose}><Close sx={{ fontSize: '18px' }} /></IconButton>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <TableContainer
                        sx={{
                            maxHeight: '150px',
                            overflow: 'auto',
                            ...scrollStyle,
                            '&::-webkit-scrollbar': { width: '8px' }
                        }}
                        component={'div'}
                        onWheel={handleScroll}
                    >
                        <Table sx={{ p: '5px', pr: 1, mb: 1 }} stickyHeader>
                            <TableHead
                                sx={{
                                    '& > tr > th': {
                                        p: '0px 4px',
                                        textAlign: 'center',
                                        fontSize: '13px',
                                        backgroundColor: 'secondary.light',
                                        color: 'white',
                                        borderRight: '1px solid white'
                                    }
                                }}
                            >
                                <TableRow>
                                    <TableCell>№</TableCell>
                                    <TableCell sx={{ textWrap: 'nowrap' }}>Захиалгын дугаар</TableCell>
                                    <TableCell>Огноо</TableCell>
                                    <TableCell>Явц</TableCell>
                                    <TableCell>Түгээгч</TableCell>
                                    {!delmanId && <TableCell>Төлөв</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody 
                                sx={{ 
                                    '& > tr > td': { 
                                        p: '0px 4px', 
                                        textAlign: 'center', 
                                        fontSize: '13px'
                                    }
                                }}
                            >
                                {
                                    stopOrders?.map((o, i) => (
                                        <StopOrder 
                                            idx={i} 
                                            order={o} 
                                            setStopOrders={setStopOrders} 
                                            delmans={delmans}
                                            setMsg={setMsg}
                                            alertWarning={alertWarning}
                                            refetch={refetch}
                                            ordersLen={stopOrders?.length}
                                            handleStopOrdersClose={handleClose}
                                        />
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DialogContent>
        </Dialog>
    )
}