import { useCallback, useEffect, useRef, useState } from 'react'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useMap } from '@vis.gl/react-google-maps'
import DeliveryStop from './DeliveryStop'

export default function DeliveryStops({ stopPoints, delmans, showStopLabel, toSelect, selected, setSelected }) {
    const map = useMap()
    const [markers, setMarkers] = useState({})
    const clustererRef = useRef(null)

    const handleRemoveFromSelected = (branchId, e) => {
        if (!branchId) return
        setSelected(prev => prev.filter(p => p?.branchId !== branchId))
        e?.stopPropagation()
    }

    useEffect(() => {
        if (!map) return
        clustererRef.current = new MarkerClusterer({ map })
    }, [map])

    useEffect(() => {
        if (!map||!clustererRef.current) return
        clustererRef.current.clearMarkers()
        clustererRef.current.addMarkers(Object.values(markers))
    }, [markers, map])

    useEffect(() => {
        return () => { clustererRef.current?.clearMarkers() }
    }, [])

    const setMarkersRef = useCallback((marker, key) => {
        if (!marker||!key) return
        setMarkers((prev) => ({ ...prev, [key]: marker }))
    }, [])

    return (
        <>
            {
                stopPoints?.map((s, i) =>(
                    <DeliveryStop
                        key={`_${s?.branchId}`}
                        stop={s}
                        setMarkersRef={setMarkersRef}
                        delmans={delmans}
                        showStopLabel={showStopLabel}
                        toSelect={toSelect}
                        selected={selected}
                        handleRemoveFromSelected={handleRemoveFromSelected}
                    />
                ))
            }
        </>
    )
}
