import { isTokenValid } from '../../../../../../utils/functions'
import { Redeem } from '@mui/icons-material'
import { Box } from '@mui/material'


export const promoBtnColor = '#6847a6'
const initialColor = '#9077bf'

export default function PromosBtn({ selected, setSf }) {
    const { promos_cnt } = isTokenValid()
    const handleClick = () => { selected ? setSf(null) : setSf(4)}

    return (
            <Box
                sx={{
                    px: 1, py: '2px', border: `1px solid ${promoBtnColor}`, borderRadius: 1, 
                    boxShadow: 3, color: 'white', bgcolor: selected ? promoBtnColor : initialColor,
                    '&:hover': { cursor: 'pointer', bgcolor: promoBtnColor, color: 'white', boxShadow: 5 }, 
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    transition: 'ease-in-out 0.2s', fontSize: '13px', position: 'relative'
                }}
                onClick={handleClick}
            >
                {promos_cnt > 0 &&
                <Box 
                    sx={{ 
                        position: 'absolute', p: '2px 6px', bgcolor: 'secondary.light', 
                        borderRadius: '50%', top: -10, right: -10, boxShadow: 3
                    }}
                >{promos_cnt}</Box>
                }
                {<Redeem sx={{ fontSize: '20px' }} />}&nbsp;&nbsp;{'Зарлагдсан урамшуулал'}
            </Box>
    )
}
