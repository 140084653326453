import AxiosWithToken from "../global/axiosBase"

// Сагс
// Сүүлд сонгосон нийлүүлэгчийн сагс авах
export const getBasket = () => {
    return AxiosWithToken.get('get_basket/')
}
// Сагсны мэдээлэл шинэчлэх
export const updateBasketInfo = (basketId, basketInfo) => {
    return AxiosWithToken.patch(`basket/${basketId}/`, basketInfo)
}
// Сагс хоослох
export const clearBasket = (basketId) => {
    return AxiosWithToken.post(`clear_basket/`, { basketId })
}

// Сагсан дахь бараа

// Сагсанд бараа нэмэх
export const addBasketItem = (item) => {
    return AxiosWithToken.post('basket_item/',  item)
}
// Сагсанд барааг олноор нэмэх
export const addBasketItemBulk = ({ items }) => {
    return AxiosWithToken.post('basket_item_bulk/',  { items })
}
// Сагсан дахь барааны тоо, ширхэг өөрчлөх
export const updateItemQty = (itemId, qty) => {
    return AxiosWithToken.patch(`basket_item/${itemId}/`,  { qty })
}
// Сагснаас бараа хасах
export const removeFromBasket = (itemId) => {
    return AxiosWithToken.delete(`basket_item/${itemId}/`)
}

// Сагсны загвар буюу Basket templet

// Сагсны загвар хадгалах/үүсгэх
export const createBasketTemplet = (basket) => {
    return AxiosWithToken.post('basket_templet/', basket)
}
// Сагсны загваруудын жагсаалт авах
export const getBasketTemplets = () => {
    return AxiosWithToken.get(`basket_templet/`)
}
// Сагсны загвар сонгох
export const getBasketTemplet = (id) => {
    return AxiosWithToken.get(`basket_templet/${id}`)
}
// Сүүлд сонгосон нийлүүлэгчийн сагсны загварууд
export const getBasketTempletBySupplier = () => {
    return AxiosWithToken.get(`get_templets/`)
}
// Caгсны загварын мэдээлэл засах
export const updateBasketTemplet = (id, basket) => {
    return AxiosWithToken.patch(`basket_templet/${id}/`, basket)
}
// Caгсны загварын мэдээлэл устгах
export const deleteBasketTemplet = (id) => {
    return AxiosWithToken.delete(`basket_templet/${id}/`)
}
// Сагсны загварыг идэвхтэй сагс болгох
export const createBasketFromTemplet = (templetInfo) => {
    return AxiosWithToken.post('from_templet/', templetInfo)
}

// promo to basket
export const promoToBasket = (data) => AxiosWithToken.post('promo_to_basket/', data)