import { updateBasketClearSettings } from "../../../../../api/user"
import { Checkbox, FormControlLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { useProfileCtx } from "../../.."

export default function IsBasketClear({ settingsData }) {
    const { clear_basket } = settingsData || {}
    const { setMsg } = useProfileCtx()
    const [checked, setChecked] = useState(clear_basket||false)
    useEffect(() => { setChecked(clear_basket) }, [clear_basket])

    const handleChange = (event) => {
        updateBasketClearSettings({ clear_basket: event.target.checked })
            .then(res => { console.log(res?.data); setMsg({ m: 'Амжилттай!', s: 'success' }) } )
            .catch(e => { console.log(e); setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' }) } )
        setChecked(event.target.checked)
    }

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label={'Захиалга үүсгэсний дараа сагсыг хоослох'}
        />
    )
}