import { Box, Button } from '@mui/material'
import SelectAddress from './SelectAddress'
import { CustomDivider } from '..'


const BY_DEL = 'Хүргэлтээр авах'
export const BY_COME = 'Очиж авах'
export default function Address({ selectedType, setSelectedType }) {
	const selectType = (val) => { 
		setSelectedType(val)
		if (selectType === BY_COME) { window.localStorage.setItem('adr', '') }
	}

	return (
		<>
			<CustomDivider text={'Хүргэлтийн хаяг'} />
				<Box sx={{ mb: 4 }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', p: 1, mt: 3 }}>
						<Button 
							variant={ selectedType === BY_DEL ? 'contained' : 'outlined' }
							size='small' 
							sx={{ ml: 2 }}
							onClick={() => selectType(BY_DEL)}
						>{BY_DEL}</Button>
						<Button
							variant={ selectedType === BY_COME ? 'contained' : 'outlined' }
							size='small'
							sx={{ ml: 2 }}
							onClick={() => selectType(BY_COME)}
						>{BY_COME}</Button>
					</Box>
					{ selectedType === BY_DEL && <SelectAddress /> }
				</Box>
		</>
	)
}