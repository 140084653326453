import { Box, IconButton, ListItemText, Typography } from '@mui/material'
import { getToast } from '../../../auth/helpers/ToastAlert'
import { StyledBadge } from '../../../helpers/styledBadge'
import { useAuthCtx } from '../../../../context/AuthCtx'
import { ShoppingCart } from '@mui/icons-material'
import DropMenu from '../../../helpers/DropMenu'
import { useNavigate } from 'react-router'
import BasketDetail from './BasketDetail'
import { useState } from 'react'

export default function Basket () {
    const { basket } = useAuthCtx()
    const basketItems = basket?.items

    const [empty, setEmpty] = useState(false)
    const emptyOff = () => { setEmpty(false) }
    
    const navi = useNavigate()
    const goToOrder = () => {
        if (basket?.items?.length === 0) {
            setEmpty(true)
        } else { navi('/order') }
    }

    return (
        <DropMenu
            title={
                <IconButton onClick={goToOrder}>
                    {getToast(empty, emptyOff, 'Сагсанд бараа нэмнэ үү!', 'warning', 1500)}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'  }}>
                        <StyledBadge 
                            color='secondary' 
                            showZero={false} 
                            badgeContent={
                                basketItems?.length
                                    ?   <Typography color='white' fontSize='14px'>{basketItems?.length}</Typography>
                                    :   0
                                }
                        >
                            <ShoppingCart sx={{ color: "white", '&:hover': { color: '#e2e2e2' }, fontSize: 30 }} />
                        </StyledBadge>
                        <ListItemText
                            sx={{ color: 'white', fontSize: '14px' }} 
                            primary={<Typography fontSize='13px'>Захиалга</Typography>}
                        />
                    </Box>
                </IconButton>
            }
            detail={<BasketDetail basket={basket} goToOrder={goToOrder} />}
            top={70}
            left={-150}
        />
    )
}