import BaskTemps from '../../../../profile/BaskTemps'
import { Box } from '@mui/material'


export default function TempletList() {
    return (
        <Box>
            <BaskTemps />
        </Box>
    )
}
