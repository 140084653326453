import { useState } from 'react'
import { useEffect } from 'react'

export const Timer = ({ leftover, setRemaining = null, style = null }) => {
    const [curTime, setCurTime] = useState(leftover||30)
    useEffect(() => { if (leftover) { setCurTime(leftover) } }, [leftover])
    
    useEffect(() => {
        const interval = setInterval(() => {
            setCurTime(prev => prev > 0 ? prev - 1 : prev)
          }, 1000)
          return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (setRemaining) {
            setRemaining(curTime)
        }
    }, [curTime])
    
    const min = curTime > 60 ? Math.floor(curTime / 60) : 0
    const sec = min > 0 ? curTime - min * 60 : curTime

    return (
        <span style={{ ...style }}>{` 0${min}:${sec <= 9 ? '0' : ''}${sec} `}</span>
    )
}
