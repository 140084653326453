import Checkbox from '@mui/material/Checkbox'
import * as React from 'react'

export default function CheckColumn({ setUpdatedCols, idx, curVal }) {
    const [checked, setChecked] = React.useState(curVal)
    const handleChange = (event) => {
        const val = event.target.checked
        setChecked(val)
        setUpdatedCols(prev => {
            return prev.map(col => {
                if (col.idx === idx) {
                    return { ...col, isShown: val }
                }
                return col
            })
        })
        //console.log("C: ", event.target.checked)
    }

    return (
        <Checkbox
            size='small'
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ p: '3px' }}
        />
    )
}
