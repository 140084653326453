import { createTargetGroup } from '../../../../../../../../api/promo'
import { Box, Button, Dialog, TextField } from '@mui/material'
import { useAdminCtx } from '../../../../../..'
import { useAddPromoCtx } from '../..'
import { useState } from 'react'

export default function SaveCustomerList({ selected }) {
    const { alertWarning, alertSuccess } = useAdminCtx()
    const { setIsNewListAdded } = useAddPromoCtx()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [name, setName] = useState('')

    const handleCustomerSave = () => {
        createTargetGroup({ name, customers: selected.map(s => s.id) })
            .then(() => { handleClose(); alertSuccess(); setIsNewListAdded(true) })
            .catch(() => { alertWarning() })
            .finally(() => { console.log("...") })
    }

    const handleEnter = (e) => { if (e.key === 'Enter') { handleCustomerSave() } }

    return (
        <>
            <Button
                variant='contained'
                size='small'
                onClick={handleOpen}
                sx={{ ml: 1 }}
            >Жагсаалт хадгалах </Button>
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ display: 'flex', flexDirection: 'row', p: 2, alignItems: 'center' }}>
                    <TextField
                        variant='standard'
                        size='small'
                        label='Нэр'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyDown={handleEnter}
                        sx={{ width: '200px', mb: 1, mr: 1 }}
                    />
                    <Button variant='contained' size='small' onClick={handleCustomerSave}>Хадгалах</Button>
                </Box>
            </Dialog>
        </>
    )
}
