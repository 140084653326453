import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { scrollStyle } from "../../../../../../../../utils/constants"

export default function DelmansSelected({ delmans, delmanId, handleDelmanId }) {
    return (
        <FormControl>
            <FormLabel>
                <Typography sx={{ fontSize: '13px', color: 'primary.light' }}>Түгээгч сонгосон: </Typography>
            </FormLabel>
            <RadioGroup
                value={delmanId}
                onChange={(e, id) => handleDelmanId(id)}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        ...scrollStyle,
                        '&::-webkit-scrollbar': { width: 8 }
                    }}
                >
                    <FormControlLabel
                        value={0}
                        control={<Radio size='small' sx={{ p: '3px', ml: 3 }} />}
                        label={<Typography sx={{ fontSize: '13px', color: 'primary.light' }}>Бүгд</Typography>}
                    />
                    {
                        delmans?.map(d => (
                            <FormControlLabel
                                key={d.id}
                                value={d.id}
                                control={<Radio size='small' sx={{ p: '3px', ml: 3 }} />}
                                label={<Typography sx={{ fontSize: '13px', bgcolor: d.color||'black', color: 'white', p: '2px 5px', borderRadius: 2 }}>{d.name}</Typography>}
                            />
                        ))
                    }
                </Box>
            </RadioGroup>
        </FormControl>
    )
}
