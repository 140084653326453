import { TextField } from '@mui/material'


export default function ReadOnlyField({ label, value }) {
    return (
        <TextField
            label={label}
            value={value}
            size='small'
            inputProps={{ readOnly: true, style: { cursor: 'not-allowed' } }}
            InputLabelProps={{ shrink: true }}
        />
    )
}
