import { Box } from '@mui/material'

export default function LocationLabel({ children }) {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '-10px',
                left: '40px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                backgroundColor: 'white',
                boxShadow: '0 2px 10px #000',
                width: 'fit-content',
                p: '2px', border: 1,
                zIndex: 10000
            }}
        >
            {children}
        </Box>
    )
}
