import { Box } from '@mui/material'


const magnifierWidth = 300
const magnifierHeight = 300

const ImageMagnifier = ({ img, mouseXY, setIsMagnifierHover, style }) => {
    const { x, y } = mouseXY||{}    
    return (
        <Box
            onMouseEnter={() => setIsMagnifierHover(true)}
            onMouseLeave={() => setIsMagnifierHover(false)}
            sx={{
                ...style,
                width: `${magnifierWidth}px`,
                height: `${magnifierHeight}px`,
                overflow: 'hidden',
                backgroundImage: `url('${img}')`,
                //backgroundColor: 'white',
                backgroundSize: `${450 / 2 * 3}px ${450 / 2 * 3 }px`,
                backgroundPositionX: `-${x||1}px`,
                backgroundPositionY: `-${y||1}px`,
                backgroundRepeat: 'no-repeat'
            }}
        />
    )
}

export default ImageMagnifier