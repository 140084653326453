export const toPrice = (price) => {
    return `${
                new Intl.NumberFormat(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                }).format(price)
            } ₮`
}

// new order notif
const newOrderNotifKey = 'new_order_notif'
export const isShownNewOrderNotif = (orderNo) => {
    const prevsData = window.localStorage.getItem(newOrderNotifKey)
    const prevs = prevsData ? JSON.parse(prevsData) : null
    if (prevs?.length > 0) {
        if (prevs.find(p => p === orderNo)) return true
        window.localStorage.setItem(newOrderNotifKey, JSON.stringify([ ...prevs, orderNo]))
        return false
    }
    window.localStorage.setItem(newOrderNotifKey, JSON.stringify([orderNo]))
    return false
}
export const showNewOrderNotif = (notif) => {
    const notifData = notif?.data
    if (notifData?.orderNo && !isShownNewOrderNotif(notifData.orderNo)) {
        const wd = notifData?.who?.split(' ')
        const who = wd?.[0] === 'seller' ? 'Борлуулагч' : 'Захиалагч'
        const td = notifData?.total?.split(' ')
        const price = td?.[0] ? toPrice(td[0]) : ''
        const pt = td?.[1] === 'C' ? 'Бэлнээр' : 'Зээлээр'
        new Notification(notif?.title, {
            body: `${who}:\t${wd?.[1]}\nҮнийн дүн:\t${price}\nТөлбөр:\t\t${pt}`,
            icon: '/Pharmo.ico'
        })
    }
}

// order delivery notif
const orderDeliveryNotifKey = 'order_delivery_notif'
export const isShownOrderDeliveryNotif = (uniqId) => {
    const prevsData = window.localStorage.getItem(orderDeliveryNotifKey)
    const prevs = prevsData ? JSON.parse(prevsData) : null
    if (prevs?.length > 0) {
        if (prevs.find(p => p === uniqId)) return true
        window.localStorage.setItem(orderDeliveryNotifKey, JSON.stringify([ ...prevs, uniqId]))
        return false
    }
    window.localStorage.setItem(orderDeliveryNotifKey, JSON.stringify([uniqId]))
    return false
}
export const showOrderDeliveryNotif = (notif) => {
    const notifData = notif?.data
    if (notifData?.uniqId && !isShownOrderDeliveryNotif(notifData?.uniqId)) {
        if (notif?.type === 'OO') {
            new Notification(notif?.title, {
                body: `\nЗахиалгын дугаар:\t${notifData?.orders?.join(', ')}\nТүгээгч:\t${notifData?.delman||'-'} (${notifData?.supplier||'-'})\nУтас:\t${notifData?.delmanPhone|'-'}`,
                icon: '/Pharmo.ico'
            })
        }
    }
}