import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { scrollStyle } from '../../../../../utils/constants'
import { keys } from 'underscore'

const StyledCell = ({ children, styling }) => <TableCell sx={{ p: '2px', fontSize: '14px', ...styling }}>{children}</TableCell>
const Row = ({ field, label, value }) => {
    const val = field === 'sale_price' 
                    ?   value ? `${value} ₮` : '-'
                    :   field === 'discount'
                            ?   value ? `${value} %` : '-'
                            :   value||'-'
    return <TableRow>
                <StyledCell styling={{ fontWeight: 'bold' }}>{label}</StyledCell>
                <StyledCell>{val}</StyledCell>
            </TableRow>
}

const promoInfo = {
    sale_price: 'Бөөний үнэ',
    sale_qty: 'Бөөний тоо',
    discount: 'Хямдрал',
    discount_expiredate: 'Хямдрал дуусах хугацаа'
}

const productInfo = {
    expDate: 'Барааны дуусах хугацаа',
    intName: 'Ерөнхий нэршил',
    dose: 'Тун, хэмжээ',
    form: 'Хэлбэр',
    presc: 'Олгох нөхцөл',
    nation: 'Улс',
    mfr: 'Үйлдвэрлэгч',
}

export default function Specs({ product }) {
    const { expDate, sale_price, sale_qty, discount, discount_expiredate, intName, detail } = product
    const sp = sale_price !== '0.00' ? parseFloat(sale_price) : null
    const sq = (sp && sale_qty && !isNaN(sale_qty)) ? parseInt(sale_qty) : null
    const d = discount !== '0.00' ? discount : null
    const de = !d ? null : discount_expiredate
    const values = { sale_price: sp, sale_qty: sq, discount: d, discount_expiredate: de }
    const values2 = detail ? { ...detail, expDate, intName } : { expDate }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TableContainer
                sx={{ 
                    mt: 1, maxWidth: '450px',
                    maxHeight: '350px', ...scrollStyle,
                    '&::-webkit-scrollbar': { width: 7 },
                    '&::-webkit-scrollbar-thumb': { backgroundColor: 'primary.main' }
                }}
            >
                <Typography sx={{ p: '3px', pl: 0, fontSize: '14px', borderBottom: 1 }}>Барааны мэдээлэл:</Typography>
                <Table>
                    <TableBody>
                        { keys(productInfo).map(k => <Row key={`${k}`} field={k} label={productInfo[k]} value={values2[k]} />) }
                    </TableBody>
                </Table>
                <Box sx={{ mt: 1, mb: 1 }} />
                <Typography sx={{ p: '3px', pl: 0, fontSize: '14px', borderBottom: 1 }}>Урамшууллын мэдээлэл:</Typography>
                <Table>
                    <TableBody>
                        { keys(promoInfo).map(k => <Row key={`${k}`} field={k} label={promoInfo[k]} value={values[k]} />) }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
