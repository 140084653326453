import { TableCell, TableRow } from "@mui/material"
import QtyCell from "./QtyCell"

export default function OrderItem({ item, disabled, idx, toPrice, setOrderItems, updateOrderItemQty, isDelman }) {
    const updateQty = (qty) => { updateOrderItemQty(item.itemId, qty) }
    return (
        <TableRow sx={{ '& > td': { p: '2px 6px' } }}>
            <TableCell width='30px'>{idx+1}</TableCell>
            <TableCell align="left">{item.itemName}</TableCell>
            <TableCell align="right">{item.itemPrice ? toPrice(item.itemPrice) : '-'}</TableCell>
            {
                isDelman
                    ?   <QtyCell itemQty={item.itemQty} disabled={disabled} setOrderItems={setOrderItems} updateQty={updateQty} />
                    :   <TableCell align='center'>{item.itemQty||'-'}</TableCell>
            }            
            <TableCell align="right">{item.itemTotalPrice ? toPrice(item.itemTotalPrice) : '-'}</TableCell>
        </TableRow>
    )
}
