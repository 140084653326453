import { TableCell, TableRow } from "@mui/material";
import { keys } from "underscore";
import { updatePartner } from "../../../../../../api/company";

export default function Row(props) {
	const { row, setPartners, idx, page, pageSize } = props;
	// const handleCheck = () => {
	// 	updatePartner(row?.id, { isBad: !row?.isBad })
	// 		.then((res) => {
	// 			if (res?.data && keys(res.data).includes("isBad")) {
	// 				setPartners(
	// 					(prev) =>
	// 						prev.map((p) =>
	// 							p.id === row?.id ? { ...p, ...res?.data } : p
	// 						) || null
	// 				)
	// 			}
	// 		})
	// 		.catch((e) => {
	// 			console.log("ERRbad: ", e)
	// 		})
	// }
	return (
		<TableRow
			key={`${idx + 1}`}
			sx={{
				"&:last-child td, &:last-child th": { border: 0 },
				"& > td": { padding: "2px 4px", fontSize: "13px" },
			}}
		>
			<TableCell>{page === 1 ? idx + 1 : page * pageSize + idx + 1}</TableCell>
			<TableCell align="left">{row.action}</TableCell>
			<TableCell align="left">{row?.note}</TableCell>
			{/* <TableCell align="center">{row.staff}</TableCell> */}
			<TableCell align="center">{row.name}</TableCell>
			<TableCell align="center">{row.role}</TableCell>
			<TableCell align="center">{row?.ip}</TableCell>
			<TableCell align="center">{row.at}</TableCell>
		</TableRow>
	)
}
