import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { StyledGridItem } from '../../utils'


export default function CheckInput({ label, field_name, setFieldValue, value }) {
    return (
        <StyledGridItem 
            xs={field_name === 'is_cash' ? 4 : 12}
            sx={{ display: 'flex', alignItems: 'end', mt: field_name === 'is_per' ? 1 : 0 }}
        >
            <FormControlLabel
                label={<Typography sx={{ ml: 1 }}>{label}</Typography>}
                control={<Checkbox
                            checked={value}
                            onChange={(e) => setFieldValue(field_name, e.target.checked)}
                            sx={{ p: '2px', ml: 2 }}
                        />}
            />
        </StyledGridItem>
    )
}
