import { changeShipmentOrderStatus } from '../../../../../../../../../../../../api/shipment'
import { Box, Dialog, MenuItem, TableCell } from '@mui/material'
import { useState } from 'react'

const options = [
    { label: 'Биелсэн', value: 'C'},
    { label: 'Цуцалсан', value: 'S'},
]
export default function ChangeStatusCell({ orderId, ordersLen, setStopOrders, setMsg, alertWarning, refetch, handleStopOrdersClose }) {
    const [open, setOpen] = useState(false)
    const handleOpen = (e) => { setOpen(true); e.stopPropagation() }
    const handleClose = () => { setOpen(false) }

    const handleChange = (status) => {
        changeShipmentOrderStatus({ orderIds: [orderId], status })
            .then(res => {
                const l = res.data?.length
                if (l == ordersLen) {
                    refetch()
                } else if (l > 0) {
                    setStopOrders(prev => prev.filter(o => !res.data?.includes(o.id) ))
                }
                setMsg({ m: 'Амжилттай!', s: 'success' })
                handleStopOrdersClose()
            })
            .catch(error => {
                alertWarning()
                console.log("ERR: ", error)
            })
    }

    
    return (
        <>
            <TableCell onClick={handleOpen} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>Өөрчлөх</TableCell>
            <Dialog open={open} onClose={handleClose} >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0 2px 10px #000',
                        borderRadius: '5px',
                        bgcolor: 'white',
                        width: 'fit-content',
                        p: '2px'
                    }}
                >
                    {
                        options.map(d => (
                            <MenuItem 
                                key={d.value}
                                onClick={() => handleChange(d.value)}
                                sx={{ 
                                    bgcolor: 'white', 
                                    color: 'primary.light', 
                                    fontSize: '14px',
                                    '&:hover': { bgcolor: 'primary.light', color: 'white' },
                                    transition: 'ease-in 0.15s', borderRadius: '5px'
        
                                }}
                            >{d.label}</MenuItem>
                        ))
                    }
                </Box>
            </Dialog>
        </>
    )
}
