import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import { useSellerCtx } from '../../..'
import Radio from '@mui/material/Radio'
import { Box } from '@mui/material'

export default function PayTypeSelection() {
    const { order, setOrder } = useSellerCtx()
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={order.payType}
                    onChange={(e) => { setOrder(prev => ({ ...prev, payType: e.target.value })) }}
                >
                    <FormControlLabel value="C" control={<Radio />} label="Бэлнээр" sx={{'& .MuiTypography-root': {fontSize: '23px' }}} />
                    <FormControlLabel value="L" control={<Radio />} label="Зээлээр" sx={{'& .MuiTypography-root': {fontSize: '23px' }}} />
                </RadioGroup>
            </FormControl>
        </Box>
    );
}
