import { Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useBcCtx } from '../../../../../../../context/BroadcastCtx'
import { scrollStyle } from '../../../../../../../utils/constants'
import { useEffect, useState } from 'react'
import ShipDetailRow from './ShipDetailRow'

const cols = ['№', 'Захиалагч', '№', 'Захиалгын дугаар', 'Төлөв', 'Хүргэсэн цаг', 'Тэмдэглэл', 'Түгээлт татах']

export default function ShipDetail({ detail, setUpdMsg, fetchShip, delmanNames, refetchShipmentHistory }) {
    const [items, setItems] = useState(detail||[])

    // real time update
    /* const { msg, setMsg } = useBcCtx()
    useEffect(() => {
        if (msg?.ship_item) {
            fetchShip()
            setItems(p => ([...p.map(i => i.id === msg.ship_item.id ? msg.ship_item : i)]))
            setUpdMsg({ s: 'success', m: `${msg.ship_item.orderNo} дугаартай захиалга хүргэгдлээ!`, id: msg.ship_item.id })
            setMsg(null)
        }
    }, [msg]) */

    // for row №
    const branchOrderCnt = items?.length > 0 ? items.reduce((acc, cur) => acc[cur.branchId] ? { ...acc, [cur.branchId]: acc[cur.branchId] + 1 } : { ...acc, [cur.branchId]: 1 }, {}) : {}
    const branchOrderId = items?.length > 0 ? items.reduce((acc, cur) => acc[cur.branchId] ? acc : { ...acc, [cur.branchId]: cur.id }, {}) : {}
    const orderRowSpans = (branchOrderCnt && branchOrderId) ? Object.keys(branchOrderCnt).map(k => ({ [branchOrderId[k]]: branchOrderCnt[k] })).reduce((acc, cur) => ({ ...acc, ...cur }), {}) : {}

    // when mouse over
    const [hover, setHover] = useState(null)

    return (
        <TableRow sx={{ '& > td': { p: '3px', pl: 3, border: '1px solid transparent', borderColor: 'secondary.light', borderTop: 'none' } }}>
            <TableCell colSpan={10}>
                <Collapse in={true} timeout='auto' unmountOnExit>
                    <TableContainer 
                        sx={{
                            ...scrollStyle,
                            maxHeight: '400px',
                            '&::-webkit-scrollbar': { width: 8 },
                            boxShadow: 5,
                            mb: 2
                        }}
                    >
                        <Table stickyHeader>
                            <TableHead sx={{ '& > tr > th': { backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' } }}>
                                <TableRow sx={{ '& > th': { borderBottomWidth: '2px', p: '2px 6px', textAlign: 'center' } }}>
                                    {cols.map(c => <TableCell>{c}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items?.map(item => 
                                    <ShipDetailRow
                                        key={item.id}
                                        order={item}
                                        orderRowSpans={orderRowSpans}
                                        hover={hover}
                                        setHover={setHover}
                                        branchOrderCnt={branchOrderCnt}
                                        delmanNames={delmanNames}
                                        refetchShipmentHistory={refetchShipmentHistory}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

{/* <Table sx={{ '&>tr>td': { p: '2px' } }}>
                        <TableRow>
                            <TableCell sx={{ display: 'flex', flexDirection: 'row', border: 'none', mb: 1 }}>
                                <Typography sx={{ fontSize: '14px', color: 'primary.light', mr: 2 }}>Хүргэсэн:</Typography>
                                <Typography sx={{ fontSize: '14px', color: 'primary.light', mr: 8 }}>{doneCnt}</Typography>
                                <Typography sx={{ fontSize: '14px', color: 'primary.light', mr: 2 }}>Хүлээгдэж буй:</Typography>
                                <Typography sx={{ fontSize: '14px', color: 'primary.light', mr: 9 }}>{waitingCnt}</Typography>
                                <Typography sx={{ fontSize: '14px', color: 'primary.light', mr: 2 }}>Хаалттай:</Typography>
                                <Typography sx={{ fontSize: '14px', color: 'primary.light' }}>{failedCnt}</Typography>
                            </TableCell>
                        </TableRow>
                    </Table> */}