import { Box, Checkbox, Typography } from '@mui/material'


export default function Check({ label, value, handleChange }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', mt: 1 }}>
            <Typography sx={{ fontSize: '15px', mr: 1 }}>{label}</Typography>
            <Checkbox
                sx={{ mr: 10 }}
                name='isReviewed'
                checked={value}
                onChange={handleChange}
                size='small'
            />
        </Box>
    )
}
