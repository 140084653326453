import { getSearchResults } from '../../../api/product'
import { isTokenValid } from '../../../utils/functions'
import { scrollStyle } from '../../../utils/constants'
import { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box } from '@mui/material'
import SearchField from './SearchField'
import SearchInput from './SearchInput'
import { useQuery } from 'react-query'
import ProductBox from './ProductBox'

export default function SimpleSearch() {
    const searchWidth = '600px'
    const { email } = isTokenValid()
    const [value, setValue] = useState('')
    const [searchKey, setSearchKey] = useState('name')
    const { data, error } = useQuery(['get_search', searchKey, value], getSearchResults, { enabled: Boolean(email && value) })
    const [results, setResults] = useState([])
    useEffect(() => { if (data?.data?.length) { setResults(data?.data) } }, [data])
    useEffect(() => { if (error?.response?.status === 400 && 'barcode' in error.response.data) { setResults([]) } }, [error])

    // delayed search
    const [search, setSearch] = useState(null)
    const searchTimeoutRef = useRef(null)
    useEffect(() => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
            searchTimeoutRef.current = null
        }
        searchTimeoutRef.current = setTimeout(() => { setValue(search) }, 650)
        return () => { clearTimeout(searchTimeoutRef.current) }
    }, [search])

    const disabled = window.location.pathname === '/promo_order'

    return (
        <Box sx={{ mt: 1 }}>
            <Autocomplete
                id="company-select"
                sx={{ 
                    width: searchWidth,
                    '& .MuiAutocomplete-option:hover': { backgroundColor: 'transparent' },
                }}
                noOptionsText={'Тохирох бараа олдсонгүй!'}
                disabled={disabled}
                open={Boolean(value)}
                options={results}
                getOptionLabel={(option) => option?.[searchKey]}
                clearOnBlur={true}
                renderOption={(props, option) => {
                    return <ProductBox {...props} sx={{ p: 0 }} row={option} setResults={setResults} setValue={setValue} />
                }}
                renderInput={(params) => (
                    <SearchInput
                        params={params}
                        value={value}
                        searchKey={searchKey}
                        setSearch={setSearch}
                        setValue={setValue}
                        setResults={setResults}
                        disabled={disabled}
                    />
                )}
                ListboxComponent={(props) => {
                    return <Box 
                                { ...props}
                                sx={{
                                    ...scrollStyle,
                                    '&::-webkit-scrollbar': { width: 9, height: 9 },
                                    display: 'flex', justifyContent: 'center',
                                    flexDirection: 'row', flexWrap: 'wrap',
                                    gap: 1, width: searchWidth
                                }}
                            >{props.children}</Box>
                }}
            />
            <SearchField
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                searchWidth={searchWidth}
                disabled={disabled}
            />
        </Box>
    )
}