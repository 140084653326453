import { useRef } from 'react'
import ReactQuill from 'react-quill'

export default function Editr({ nw, setNw }) {
    const handleChange = (val) => { setNw(prev => ({ ...prev, body: val })) }
    const cc = [
        '#485763', '#00ff00', '#0000ff', '#FF0000', '#800080', 
        '#FFFF00', '#000000', '#FFA500', '#A52A2A', '#008000', 
        '#808000', '#7FFFD4', '#FFC0CB', '#FF00FF', '#800080',
        '#C0C0C0', '#808080', '#FFFFFF', '#00FF00', '#FFFF00'
    ]
    const quillRef = useRef(null)
    const handleTextColor = (value) => {
        const editor = quillRef.current.getEditor();
        const range = editor.getSelection();
        if (range) { editor.formatText(range.index, range.length, 'color', value) }
    }

    return (
        <ReactQuill
            ref={quillRef}
            value={nw}
            onChange={handleChange}
            placeholder="Мэдэгдлийн тайлбар..."
            modules={{
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'color': cc }, { 'background': [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link'],
                ],
            }}
            handlers = {{ 'color': handleTextColor }}
        />
    )
}
