import { removeTokens } from '../../../../../utils/functions'
import AxiosWithToken from '../../../../../global/axiosBase'
import { useAuthCtx } from '../../../../../context/AuthCtx'
import { Box, MenuItem, Typography } from '@mui/material'
import { useNavigate } from 'react-router'

export default function SignedInMenu({ isSupplier, role }) {
    const { clearUserItems } = useAuthCtx()
    const navi = useNavigate()
    const handleClick = (e) => {
        const txt = e.currentTarget.textContent
        if (txt === 'Захиалгууд') {
            isSupplier
                ?   navi('/admin/orders')
                :   navi('/profile/orders')
        }
        if (txt === 'Бараанууд') { navi('/admin/products/list') }
        if (txt === 'Тохиргоо') {
            isSupplier
                ?   navi('/admin/settings')
                :   navi('/profile/settings')
        }
        if (txt === 'Хадгалсан сагс') {
            isSupplier
                ?   navi('/admin/orders')
                :   navi('/profile/templets')
        }
        if (txt === 'Гарах') {
            AxiosWithToken.post('auth/logout/').then(_ => {
                    removeTokens()
                    clearUserItems()
                    window.location.href = '/signin'
                    //navi('/signin')
                }).catch(e => console.log(e))
        }
    }

    return (
        <Box
            sx={{
                borderBottom: '1px solid', borderBottomColor: 'secondary.light',
                backgroundColor: '#f1f1f1', width: '150px'
            }}
        >
            <MenuItem
                onClick={handleClick}
                sx={{ display: 'flex', justifyContent: 'left' }}
            ><Typography sx={{ ml: 2, fontSize: '15px', color: 'primary.main' }}>Захиалгууд</Typography></MenuItem>
            {role !== 'A' && <MenuItem
                onClick={handleClick}
                sx={{ display: 'flex', justifyContent: 'left' }}
            ><Typography sx={{ ml: 2, fontSize: '15px', color: 'primary.main' }}>Хадгалсан сагс</Typography></MenuItem>}
            {
                role === 'A' &&
                <MenuItem
                    onClick={handleClick}
                    sx={{ display: 'flex', justifyContent: 'left' }}
                ><Typography sx={{ ml: 2, fontSize: '15px', color: 'primary.main' }}>Бараанууд</Typography></MenuItem>
            }
            {
                (!isSupplier || role === 'A') &&
                <MenuItem
                    onClick={handleClick}
                    sx={{ display: 'flex', justifyContent: 'left' }}
                ><Typography sx={{ ml: 2, fontSize: '15px', color: 'primary.main' }}>Тохиргоо</Typography></MenuItem>
            }
            <MenuItem
                onClick={handleClick}
                sx={{ display: 'flex', justifyContent: 'left' }}
            ><Typography sx={{ ml: 2, fontSize: '15px', color: 'primary.main' }}>Гарах</Typography></MenuItem>
        </Box>
    )
}