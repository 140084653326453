import { Box, Button, Dialog, DialogContent, Link, TextField, Typography } from '@mui/material'
import { secondaryLight } from '../../../../utils/constants'
import { getToast } from '../../../auth/helpers/ToastAlert'
import { useState } from 'react'

const noteLimit = 400
export default function Note({ note, setNote }) {
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    const handleChange = (e) => {
        const newInput = e.target.value??''
        const bk = e.key === 'Backspace' || e.code === 8
        if (newInput.length === 0) { setNote(null) }
        else {
            if (newInput.length >= noteLimit) {
                setNote(newInput.slice(0, noteLimit))
                if (!bk) {
                    setErr(`${noteLimit} тэмдэгтээс хэтэрч болохгүй!`)
                }
            } else { setNote(newInput) }
        }
    }

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            {getToast(Boolean(err), errOff, err, 'warning', 2000)}
            <Link href='#' onClick={handleOpen} sx={{ fontSize: '14px', fontWeight: 'bold', color: secondaryLight }}>Нэмэлт тайлбар бичих</Link>
            <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
                <DialogContent>
                    <Typography sx={{ fontSize: '15px', textAlign: 'right', color: note?.length >= noteLimit ? secondaryLight : 'initial' }}>{`${note?.length??'0'}/${noteLimit}`}</Typography>
                    <TextField
                        autoFocus={open}
                        label='Нэмэлт тайлбар'
                        value={note}
                        onChange={handleChange}
                        multiline
                        rows={6}
                        sx={{ width: '100%' }}
                    />
                    <Box sx={{ width: '100%', textAlign: 'center', pt: 1 }}>
                        <Button size='small' variant='contained' onClick={handleClose}>Нэмэх</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}
