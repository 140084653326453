import AxiosWithToken, { Axios } from "../global/axiosBase"

export const isRegistered = (email) => {
    return Axios.post('auth/reged/', { email })
}

export const createPassword = ({ email, otp, new_pwd }) => {
    return Axios.post('auth/reset/', { email, otp, new_pwd })
}

export const changePassword = ({ email, old_pwd, new_pwd }) => {
    return AxiosWithToken.post('auth/change/', { email, old_pwd, new_pwd })
}

export const LogIn = ({ email, password }) => {
    return Axios.post('auth/login/', { email, password })
}

export const getRegistrationOTP = ({ email, phone }) => {
    return Axios.post('auth/reg_otp/', { email, phone })
}

export const registerUser = ({ email, phone, otp, password }) => {
    //console.log("Auth")
    return Axios.post('auth/register/', { email, phone, otp, password })
}

export const getOTP = (email) => {
    return Axios.post('auth/get_otp/', { email })
}

export const verifyOTP = ({ email, otp }) => {
    return Axios.post('auth/ver_otp/', { email, otp })
}

// pin code to verify order
export const changePin = (data) => {
    return AxiosWithToken.patch('auth/pin/', data)
}

// pick supplier
export const pickSupplier = (pId) => {
    return AxiosWithToken.post('pick/', { supplierId: pId })
}

// get company info from ebarimt
export const getCompanyName = (rd) => { return Axios.get(`get_cn/?rd=${rd}`) }

// update user info
export const updateUserInfo = (data) => { return AxiosWithToken.patch(`auth/update_user/`, data) }

export const deleteUserAccount = (data) => AxiosWithToken.patch('auth/delete_user_account/', data)