import { Box, Button, Dialog, IconButton, TextField, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useDragOver } from "@minoru/react-dnd-treeview"
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import styles from "./CustomNode.module.css"
import { useState } from "react"

export const CustomNode = (props) => {
    const { id, text } = props.node
    const [visibleInput, setVisibleInput] = useState(false)
    const [labelText, setLabelText] = useState(text)
    const indent = props.depth * 24

    const [confirm, setConfirm] = useState(false)

    const handleToggle = (e) => {
        e.stopPropagation()
        props.onToggle(props.node.id)
    }

    const handleShowInput = () => {
        setVisibleInput(true)
    }

    const handleCancel = () => {
        setLabelText(text)
        setVisibleInput(false)
    }

    const handleChangeText = (e) => {
        setLabelText(e.target.value)
    }

    const handleSubmit = () => {
        setVisibleInput(false)
        props.onTextChange(id, labelText, "edit")
    }

    const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

    const handleClose = () => { setConfirm(false) }
    const handleDeleteShow = () => {
        setConfirm(true)
    }
    const handleDelete = () => {
        props.handleDelete(id)
        handleClose()
    }

    return (
        <div
            className={`tree-node ${styles.root}`}
            style={{ paddingInlineStart: indent }}
            {...dragOverProps}
        >
            <div className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ""}`}            >
                {props.node.droppable && (
                    <div onClick={handleToggle}>
                        <ArrowForwardIcon />
                    </div>
                )}
            </div>
            <div className={styles.labelGridItem}>
                {visibleInput ? (
                    <div className={styles.inputWrapper}>
                        <TextField className={`${styles.textField} ${styles.nodeInput}`} value={labelText} onChange={handleChangeText} />
                        <IconButton className={styles.editButton} onClick={handleSubmit} disabled={labelText === ""}  >
                            <CheckIcon className={styles.editIcon} />
                        </IconButton>
                        <IconButton className={styles.editButton} onClick={handleCancel}>
                            <CloseIcon className={styles.editIcon} />
                        </IconButton>
                    </div>
                ) : (
                    <div className={styles.inputWrapper}>
                        <Typography variant="body2" className={styles.nodeLabel}>
                            {props.node.text}
                        </Typography>
                        <IconButton className={styles.editButton} onClick={handleShowInput}>
                            <EditIcon className={styles.editIcon} />
                        </IconButton>
                        <IconButton className={styles.editButton} onClick={handleDeleteShow}>
                            <DeleteIcon className={styles.editIcon} />
                        </IconButton>
                    </div>
                )}
            </div>
            <Dialog open={confirm} onClose={handleClose}>
                <Box sx={{ p: 2, border: 1, borderRadius: 1 }}>
                    <Typography textAlign='center'>
                        Устгахдаа итгэлтэй байна уу?
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 4 }}>
                        <Button onClick={handleDelete} variant="contained" sx={{ mr: 2 }}>Тийм</Button>
                        <Button onClick={handleClose} variant="outlined">Үгүй</Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}
