import { getBranches } from '../../../../api/company'
import { Box, Button } from '@mui/material'
import SelectAddress from './SelectAddress'
import { useEffect, useState } from 'react'
import { CustomDivider } from '..'

const BY_DEL = 'Хүргэлтээр авах'
export const BY_COME = 'Очиж авах'
export default function Address({ adrType, setAdrType, order, setOrder }) {
	const [branches, setBranches] = useState([])
    useEffect(() => {
		if (adrType === BY_DEL) {
			if (branches.length === 0) {
				getBranches()
					.then(res => { setBranches(res?.data) })
					.catch(err => { console.log("ERr: ", err) })
			}
		} else { setOrder(prev => ({ ...prev, branchId: null })) }
    }, [adrType])

	return (
		<>
			<CustomDivider text={'Хүргэлтийн хаяг'} />
				<Box sx={{ mb: 4 }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', p: 1, mt: 3 }}>
						<Button 
							variant={ adrType === BY_DEL ? 'contained' : 'outlined' }
							size='small' 
							sx={{ ml: 2 }}
							onClick={() => setAdrType(BY_DEL)}
						>{BY_DEL}</Button>
						<Button
							variant={ adrType === BY_COME ? 'contained' : 'outlined' }
							size='small'
							sx={{ ml: 2 }}
							onClick={() => setAdrType(BY_COME)}
						>{BY_COME}</Button>
					</Box>
					{ adrType === BY_DEL && <SelectAddress branches={branches} order={order} setOrder={setOrder} /> }
				</Box>
		</>
	)
}