import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { updateCompanyContact } from '../../../../../api/company'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object({
    email: Yup.string().email('Алдаатай имэйл хаяг байна!').required('Имэйл хаяг оруулна уу!').nullable(),
    phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар оруулна уу!').nullable()
})

export default function Contacts({ email, phone, cId, refetch }) {
    const [msg, setMsg] = useState(null)
    const msgOff = () => {
        setMsg(null)
        if (msg?.s === 'success') { refetch() }
    }
    const { handleSubmit, getFieldProps, errors, touched } = useFormik({
        initialValues: { email, phone },
        onSubmit: (values) => {
            updateCompanyContact(cId, values)
                .then(_ => {
                    setMsg({ m: 'Амжилттай!', s: 'success' })
                })
                .catch(e => { 
                    setMsg({ m: 'Түр хүлээгээд дахин оролдоно уу!', s: 'warning' })
                    console.log("ERR: ", e) 
                })
        },
        validationSchema,
        validateOnChange: false
    })

    const getErrors = (name) => {
        const err = errors[name] && touched[name]
        return {
            error: err,
            helperText: err ? errors[name] : ''
        }
    }
    console.log("E: ", msg, Boolean(msg))

    return (
        <Box>
            {getToast(Boolean(msg), msgOff, msg?.m, msg?.s, 1500)}
            <Typography sx={{ fontSize: '15px', color: '#666', mb: 2, mt: 2 }}>Холбоо барих</Typography>
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <TextField
                        label='Имэйл'
                        name='email'
                        {...getFieldProps('email')}
                        {...getErrors('email')}
                        size='small'
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        label='Утасны дугаар'
                        name='phone'
                        {...getFieldProps('phone')}
                        {...getErrors('phone')}
                        size='small'
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button 
                        size='small' 
                        variant='contained' 
                        sx={{ p: '9px' }}
                        onClick={handleSubmit}
                    >Хадгалах</Button>
                </Grid>
            </Grid>
        </Box>
    )
}
