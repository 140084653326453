import { FilterAlt } from "@mui/icons-material";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { primaryLight, secondaryLight, ISCOMPANIES } from "../../../../../../utils/constants";
import DropCell from '../DropCell';
import ByField from './ByField';
import TextFieldFilter from './FilterCells/TextFieldFilter';

export default function FilterRow({ filtering, setFiltering }) {
  // const handleFiltering = ({ fieldName, newValue, lookup }) => {
  //   if (newValue) {
  //     const lu = !lookup ? "=" : lookup;
  //     setFiltering({
  //       ...filtering,
  //       [fieldName]: `${lu}${newValue}`,
  //     });
  //   } else {
  //     const updated = { ...filtering };
  //     delete updated[fieldName];
  //     setFiltering({ ...updated });
  //   }
  // };

  //console.log("F: ", filtering)

  return (
    // <TableRow
    //   sx={{
    //     "& > td": {
    //       p: "2px ",
    //       borderBottom: "1px solid gray",
    //       borderBottomColor: primaryLight,
    //     },
    //   }}
    // >
    //   <TableCell align="center" sx={{ p: "2px" }}>
    //     <Tooltip title="Шүүж хайх мөр" placement="right">
    //       <FilterAlt
    //         sx={{ color: secondaryLight, fontSize: "20px", margin: 0 }}
    //       />
    //     </Tooltip>
    //   </TableCell>
    //   <TextFieldFilter
    //     fieldName={"name"}
    //     setNewValue={handleFiltering}
    //   />
    //   <TextFieldFilter
    //     fieldName={"rd"}
    //     setNewValue={handleFiltering}
    //   />
    //   <TableCell />
    //   <NumberFieldFilter fieldName={"debt"} setNewValue={handleFiltering} />
    //   <TableCell />
    //   <TableCell />
    //   <NumberFieldFilter fieldName={"orderSum"} setNewValue={handleFiltering} />
    //   <TableCell />
    //   <TableCell />
    //   <ChoiceFieldFilter fieldName={"isBad"} setNewValue={handleFiltering} />
    // </TableRow>

    <TableRow
      sx={{
        "& > td": {
          p: "2px ",
          borderBottom: "1px solid gray",
          borderBottomColor: primaryLight,
        },
      }}
    >
      <TableCell align="center" sx={{ p: "2px" }}>
        <Tooltip title="Шүүж хайх мөр" placement="right">
          <FilterAlt sx={{ color: secondaryLight, fontSize: "20px", margin: 0 }} />
        </Tooltip>
      </TableCell>
      <TextFieldFilter setFilters={setFiltering} filters={filtering} field="customer__name" />
      <TableCell />
      <DropCell field='is_company' choices={ISCOMPANIES} filters={filtering} setFilters={setFiltering} />
      <ByField field='debt' label={'Нийт өр'} filters={filtering} setFilters={setFiltering} />
      <ByField field='credit' label={'Зээлийн лимит'} filters={filtering} setFilters={setFiltering} />
      <ByField field='bad_cnt' label={'Найдваргүй тоо'} filters={filtering} setFilters={setFiltering} />
      <TableCell />
      <TextFieldFilter setFilters={setFiltering} filters={filtering} field="customer__email" />
      <TextFieldFilter setFilters={setFiltering} filters={filtering} field="customer__rd" />
      <ByField field='orders_cnt' label={'Захиалга тоо'} filters={filtering} setFilters={setFiltering} />
      <ByField field='orders_sum' label={'Захиалга дүн'} filters={filtering} setFilters={setFiltering} />
      <ByField field='created' label={'Огноо'} filters={filtering} setFilters={setFiltering} />
    </TableRow>
  )
}
