import { scrollStyle } from '../../../../../../../../../../utils/constants'
import { getVndrs } from '../../../../../../../../../../api/product'
import { DialogContent, Typography } from '@mui/material'
import { useAdminCtx } from '../../../../../../../..'
import { useProductListCtx } from '../../../../..'
import { useEffect } from 'react'

export default function VndrsList({ handleSelect, toClose }) {
    const { alertWarning } = useAdminCtx()
    const { vndrs, setVndrs } = useProductListCtx()
    useEffect(() => {
        if (vndrs?.length === 0) {
            getVndrs()
                .then(res => { setVndrs(res?.data) })
                .catch(e => { console.log("ERR: ", e); alertWarning(); toClose() })
        }
    }, [])

    if (vndrs?.length === 0) return null

    return (
        <DialogContent sx={{ ...scrollStyle, '&::-webkit-scrollbar': { width: 10, height: 10 }, maxHeight: '500px', width: '300px' }}>
            {
                vndrs?.map(
                    v => <Typography
                            key={`${v.id}`}
                            onClick={() => {handleSelect(v)}}
                            variant="body1" 
                            sx={{
                                cursor: 'pointer', p: '3px', fontSize: '14px', fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#f5f5f5', color: 'primary.main' }
                            }}
                        >{v.name}</Typography>
                )
            }
        </DialogContent>
    )
}
