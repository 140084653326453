import { Box, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import SaveCustomerList from './SaveCustomerList'
import SelectCustomer from './SelectCustomer'
import SelectFromList from './SelectFromList'
import { useEffect } from 'react'
import { useAddPromoCtx } from '..'

const cols = [
    { id: 'name', label: 'Харилцагчийн нэр' },
    { id: 'rd', label: 'Регистр' },
    { id: 'email', label: 'Имэйл' },
    { id: 'phone', label: 'Утас' }
]

export default function SelectedCustomers({ selected, setSelected, error }) {
    const { selectedTargetGroup, setFieldValue } = useAddPromoCtx()
    const handleRemove = (customerId) => { setSelected(prev => prev.filter(s => s.id !== customerId)) }
    useEffect(() => { setFieldValue('target', [ ...selected?.map(s => s.id)]) }, [selected])

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ p: 1, display: 'flex', justifyContent: 'space-between', boxShadow: 5 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={cols.length+1} sx={{ p: 0, pb: '4px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <SelectCustomer selected={selected} setSelected={setSelected} />
                                    <SelectFromList selected={selected} setSelected={setSelected} />
                                    {selectedTargetGroup?.length === 0 && selected?.length > 0 && <SaveCustomerList selected={selected} />}
                                    <Typography sx={{ ml: 1, color: '#d32f2f' }}>{error||''}</Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '& > th': { py: '2px', px: '10px', backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' } }}>
                            <TableCell width='30px'>№</TableCell>
                            {
                                cols.map(col => <TableCell key={col.id}>{col.label}</TableCell>)
                            }
                            <TableCell width='60px'>Хасах</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            selected.map((customer, idx) => 
                                <TableRow 
                                    key={idx}
                                    sx={{ '& > td': { py: '1px', px: '10px' }, '&:hover': { backgroundColor: '#e5e5e5' } }}
                                >
                                    <TableCell>{idx+1}</TableCell>
                                    {
                                        cols.map(col => 
                                            <TableCell key={col.id}>{customer[col.id]||'-'}</TableCell>
                                        )
                                    }
                                    <TableCell align='center'>
                                        <IconButton sx={{ p: 0, textAlign: 'center' }} onClick={() => { handleRemove(customer.id) }}>
                                            <RemoveCircle sx={{ fontSize: '20px', '&:hover': { color: 'secondary.light' } }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    )
}
