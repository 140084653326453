import { PushPin } from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'

import { primaryLight, secondaryLight } from '../../../../../../../../utils/constants'

export default function Inputs({ value, setValue, doFilter, error, setIsPinned, isPinned }) {
    const handleEnter = (e) => { if (e?.code === 'Enter') { doFilter() } }
    const handlePin = () => { setIsPinned(!isPinned) }

    return (
        <Box sx={{ backgroundColor: 'white', boxShadow: 8, p: 3, width: '350px'}}>
            <Box 
                sx={{ 
                    position: 'absolute', top: 1, left: 3, 
                    '&:hover': { cursor: 'pointer' }
                }}
                onClick={handlePin}
            >
                <PushPin sx={{ color: isPinned ? secondaryLight : primaryLight }} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start'  }}>
                <TextField
                    size='small'
                    placeholder='Нэр эсвэл Регистрийн дугаар'
                    value={value||''}
                    onChange={e => { setValue(e?.target?.value) }}
                    sx={{
                        flex: 1,
                        '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                        '&:hover fieldset': { borderColor: primaryLight },
                        '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '13px', opacity: 1, ml: '5px' },
                    }}
                    inputProps={{ style: { padding: '3px 5px', fontSize: '13px', color: primaryLight } }}
                    onKeyDown={handleEnter}
                    error={Boolean(error)}
                    helperText={Boolean(error) ?  error :  ''}
                    autoComplete='off'
                />
                <Button 
                    size='small' variant='contained' sx={{ p: '2px', borderRadius: 0, ml: 1, fontSize: '13px' }}
                    onClick={doFilter}
                >Хайх</Button>
            </Box>
        </Box>
    )
}
