import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Table, TableCell, TableRow, Typography } from '@mui/material'
import DelmansSelected from './DelmansSelected'
import SelectionTool from './SelectionTool'

const controlPanelWidth = 230
export default function ControlPanelBox({ delmans, ordersCnt, stopsCnt, toSelect, setToSelect, delmanId, setDelmanId, setSelected, isFetchOrders, setIsFetchOrders, showStopLabel, setShowStopLabel }) {
    const handleToSelect = () => {
        setToSelect(prev => {
            if (!prev) { return true }
            else {
                setSelected([])
                return false
            }
        })
    }

    const handleDelmanId = (id) => {
        if (id) {
            setDelmanId(parseInt(id))
            setToSelect(false)
        } else { setDelmanId(null) }
        setIsFetchOrders(false)
    }

    const handleNotAlloted = () => {
        setIsFetchOrders(true)
        setDelmanId(null)
    }

    return (
        <Box 
            sx={{
                boxShadow: '0 2px 10px #000',
                backgroundColor: 'white', 
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                m: 1, p: 1,
                width: `${controlPanelWidth}px`,
            }}
        >
            <SelectionTool
                handleToSelect={handleToSelect}
                toSelect={toSelect}
                w={controlPanelWidth}
            />
            <Box sx={{ mb: 3 }}>
                <Table sx={{ '& > tr > td': { p: '2px', fontSize: '13px' } }}>
                    <TableRow>
                        <TableCell sx={{ color: 'primary.light' }}>Нийт байршлууд:</TableCell>
                        <TableCell sx={{ fontWeight: 600, color: 'secondary.light' }}>{stopsCnt||'-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ color: 'primary.light' }}>Нийт захиалгууд:</TableCell>
                        <TableCell sx={{ fontWeight: 600, color: 'secondary.light' }}>{ordersCnt||'-'}</TableCell>
                    </TableRow>
                </Table>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                    value={isFetchOrders}
                    checked={isFetchOrders}
                    control={<Radio size='small' sx={{ p: '3px', ml: 3 }} />}
                    label={<Typography sx={{ fontSize: '13px', color: 'primary.light' }}>Түгээгч сонгоогүй</Typography>}
                    onChange={handleNotAlloted}
                />
                <DelmansSelected delmans={delmans} delmanId={delmanId} handleDelmanId={handleDelmanId} />
                {
                    delmanId === 0 && 
                        <RadioGroup
                            value={showStopLabel}
                            onChange={() => setShowStopLabel(prev => !prev)}
                            sx={{ mt: 3 }}
                        >
                            <FormControlLabel
                                control={<Radio size='small' sx={{ p: '3px', ml: 3 }} checked={showStopLabel} />}
                                label={<Typography sx={{ fontSize: '12px', color: 'primary.light' }}>Байршлын нэр харуулах</Typography>}
                            />
                            <FormControlLabel
                                control={<Radio size='small' sx={{ p: '3px', ml: 3 }} checked={!showStopLabel} />}
                                label={<Typography sx={{ fontSize: '12px', color: 'primary.light' }}>Түгээгчийн нэр харуулах</Typography>}
                            />
                        </RadioGroup>
                }
                {
                    delmanId === null &&
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    size='small' 
                                    sx={{ p: '3px', ml: 3 }} 
                                    checked={showStopLabel}
                                    onChange={() => setShowStopLabel(!showStopLabel)}
                                />
                            }
                            label={<Typography sx={{ fontSize: '12px', color: 'primary.light' }}>Байршлын нэр харуулах</Typography>}
                            sx={{ mt: 3 }}
                        />
                }
            </Box>
        </Box>
    )
}