import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { forwardRef, useState } from 'react'
import { TextField } from '@mui/material'
import { mn } from 'date-fns/locale'
import { format } from 'date-fns'
registerLocale('mn', mn)

const CustomDateInput = forwardRef(({ value, onClick, label }, ref) => {
	return <TextField
				ref={ref}
				onClick={onClick}
				value={value}
				label={label}
				sx={{ width: '230px' }}
				variant='standard'
			/>
})

export default function EndDatePicker({ setFieldValue, initialValue }) {
	const [end, setEnd] = useState(initialValue ? new Date(initialValue) : null)

	const handleStartChange = (date) => {
		if (date) {
			setEnd(date)
			setFieldValue('end_date', format(date, 'yyyy-MM-dd'))
		} else {
			setEnd(null)
			setFieldValue('end_date', null)
		}
	}

	return (
		<DatePicker
            locale='mn'
            selected={end}
            onChange={handleStartChange}
            selectsStart
            startDate={end}
            dateFormat="yyyy/MM/dd"
            showIcon
            isClearable
            customInput={<CustomDateInput label='Дуусах огноо' />}
			withPortal
        />
	)
}