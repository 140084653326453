import { Close } from '@mui/icons-material';
import { InputAdornment, TableCell, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export default function CharFilterCell({ setFilters, filters, field, staffs }) {
  const [value, setValue] = useState('');
  const searchTimeoutRef = useRef(null);
  useEffect(() => {
    if (value) {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current)
        searchTimeoutRef.current = null
      }
      searchTimeoutRef.current = setTimeout(() => {
        setFilters(prev => {

          let f = null;
          if (field === 'name') {
            const nn = staffs.filter(ss => ss.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
            if (nn.length > 0) {
              f = { k: 'staff__id', v: nn[0].id, o: '=' }
            }
          } else if (field === 'email') {
            const ee = staffs.filter(ss => ss.email.toLowerCase().indexOf(value.toLowerCase()) > -1);
            if (ee.length > 0) {
              f = { k: 'staff__id', v: ee[0].id, o: '=' }
            }
          }

          if (f != null) {
            if (prev?.length) {
              if (prev.map(p => p.k).includes('staff__id')) {
                return prev.map(p => p?.k === 'staff__id' ? f : p);
              } else {
                return [...prev, f];
              }
            }
            return [f];
          }
        })
      }, 650)
    } else {
      if (filters != null)
        setFilters(prev => prev.filter(f => f.k !== 'staff__id'))
    }
    return () => { clearTimeout(searchTimeoutRef.current) }
  }, [value])

  const clearName = () => { setValue(''); setFilters(prev => prev.filter(f => f.k !== field)) }

  return (
    <TableCell>
      <TextField
        placeholder='Хайх'
        value={value}
        onChange={(e) => { setValue(e.target.value) }}
        InputProps={{
          endAdornment: (<InputAdornment sx={{ backgroundColor: 'transparent' }}>
            <Close
              sx={{
                fontSize: '15px', color: 'primary.light',
                backgroundColor: 'transparent',
                '&:hover': { cursor: 'pointer' },
                display: value ? 'block' : 'none',
              }}
              onClick={clearName}
            />
          </InputAdornment>)
        }}
        inputProps={{ style: { padding: '2px 8px' } }}
        sx={{
          '& fieldset': { borderRadius: 0, borderColor: 'primary.light' },
          '& .MuiInputBase-input::placeholder': {
            color: 'primary.light', textAlign: 'center',
            fontSize: '13px', opacity: 1
          },
          border: 'none', width: '100%'
        }}
        size='small'
      />
    </TableCell>
  )
}
