import { getProductAddCols } from '../../../../api/product'
import { useEffect, useState, Suspense, lazy } from 'react'
import ProductTable from './ProductTable'
import { useQuery } from 'react-query'
import { isEmpty } from 'underscore'
import { Box } from '@mui/material'
import ImportXLSX from './Import'

const ProductAdd = lazy(() => import('./ProductAdd/index'))

export default function Products() {
    const { data: colData } = useQuery('get_product_add_cols', getProductAddCols, { retry: false })
    const cols = colData?.data||[]
    const [productInfo, setProductInfo] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    useEffect(() => { if (selectedProduct) { setProductInfo(selectedProduct) } }, [selectedProduct])

    const [open, setOpen] = useState(true)
    const handleAddOpen = () => { setProductInfo({ name: '', itemname_id: null }); setOpen(true) }

    const [added, setAdded] = useState(false)
    const refetchProds = () => { setAdded(true) }

    const cats = cols?.find(c => c.field === 'category')?.values
    
    return (
        <Box sx={{ height: '100%' }}>
            {/* <Box>
                <ImportXLSX />
            </Box> */}
            { !isEmpty(productInfo) &&
                <Suspense fallback={<div>...loading</div>}>
                    <ProductAdd
                        open={open}
                        setOpen={setOpen}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        cols={cols}
                        productInfo={productInfo}
                        setProductInfo={setProductInfo}
                        refetchProds={refetchProds}
                    />
                </Suspense>
            }
            <ProductTable
                setOpen={setOpen}
                handleAddOpen={handleAddOpen}
                setSelectedProduct={setSelectedProduct}
                cats={cats}
                added={added}
                setAdded={setAdded}
            />
        </Box>
    )
}
