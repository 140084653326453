import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export default function CloseBtn({ handleRemove, bgcolor }) {
    return (
        <IconButton
            sx={{
                p: '2px',
                position: 'absolute',
                top: '-10px',
                right: '-20px',
                bgcolor,
                '&:hover': { bgcolor }
            }}
            onClick={handleRemove}
        >
            <Close sx={{ fontSize: '16px', color: 'white' }} />
        </IconButton>
    )
}
