import { ListItem, Typography } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'


export default function CategoryItem({ menuItem, onSelect, indent }) {
    const iconStyle = { fontSize: '18px', fontWeight: 'bolder', color: 'primary.dark' }
    const handleClick = () => { onSelect(menuItem) }

    return (
        <ListItem
            sx={{
                p: 0, pl: `${indent}px`, m: 0, cursor: 'pointer',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
            }}
            onClick={handleClick}
        >
            {menuItem?.childs?.length > 0 && <ChevronRight sx={{ ...iconStyle }}/>}
            <Typography
                variant="body1"
                fontWeight='bold'
                sx={{ color: 'primary.dark', fontSize: '14px', fontWeight: 'bold', p: '3px' }}
            >{menuItem.name}</Typography>
        </ListItem>
    )
}
