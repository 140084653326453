import { getToast } from '../../../../auth/helpers/ToastAlert'
import { paperStyle } from '../../../../../utils/constants'
import { getStaffs } from '../../../../../api/staff'
import { Box, Button, Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import StaffsList from './StaffsList'
import AddStaff from './AddStaff'

export default function Staff() {
    const [isFetched, setIsFetched] = useState(false)
    const [staffs, setStaffs] = useState([])
    const fetchStaffs = () => {
        getStaffs()
            .then(r => { setStaffs(r?.data) })
            .catch(e => { console.log("E: ", e) })
            .finally(() => { setIsFetched(true) })
    }
    useEffect(() => { fetchStaffs() }, [])
    
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [sucAlert, setSucAlert] = useState(false)
    const alertOn = () => { setSucAlert(true); handleClose() }
    const alertOff = () => { setSucAlert(false) }

    return (
        <>
            {
                isFetched
                    ?   <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, ...paperStyle, p: 3  }}>
                            <Box>
                                <Button size='small' variant='contained' onClick={ handleOpen }>Ажилтан нэмэх</Button>
                                <Dialog open={open} onClose={ handleClose }>
                                    <AddStaff refetch={ fetchStaffs } alertOn={ alertOn } />
                                </Dialog>
                            </Box>
                            <StaffsList staffs={staffs} refetch={ fetchStaffs } />
                            { getToast(sucAlert, alertOff, 'Амжилттай нэмэгдлээ!') }
                        </Box>
                    :   null
            }
        </>
    )
}
