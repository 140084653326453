import { NumberFormatCustom, StyledGridItem } from '../../utils'
import { TextField, Typography } from '@mui/material'


export default function PriceInput({ label, field_name, getFieldProps, values, errors, touched, totalPrice = 0 }) {
    return (
        <StyledGridItem 
            item 
            xs={field_name === 'bundle_price' ? 12 : 4}
            sx={{ 
                display: 'flex', alignItems: field_name === 'bundle_price' ? 'center' : 'initial',
                flexDirection: 'column', mb: field_name === 'bundle_price' ? 3 : '0px',
            }}
        >
            <TextField
                variant='standard'
                size='small'
                label={label}
                {...getFieldProps(field_name)}
                value={values?.[field_name]}
                error={errors?.[field_name] && touched?.[field_name]}
                helperText={errors?.[field_name] && touched?.[field_name] && errors?.[field_name]}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: { suffix: field_name === 'procent' ? ' %' : ' ₮' }
                }}
                sx={{
                    width: '230px',
                    '& .MuiInput-root': { '& input': { textAlign: 'center' } },
                }}
            />
            {totalPrice > 0 && <Typography sx={{ fontSize: '13px', color: '#ED6C02' }}>{values?.[field_name] > totalPrice ? 'Багцын үнэ барааны нийт үнийн дүнгээс их байна!' : ' '}</Typography>}
        </StyledGridItem>
    )
}
