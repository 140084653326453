import { Box, Divider, IconButton, List } from '@mui/material'
import { isTokenValid } from '../../../utils/functions'
import { drawerWidth } from '../../../utils/constants'
import { ChevronLeft } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import StaffInfo from './StaffInfo'
import Menu from './Menu'

const DrawerBase = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      	'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: theme.spacing(4),
				[theme.breakpoints.up('sm')]: {
					width: theme.spacing(6),
				},
        	}),
      },
    }),
)

export default function Drawer({ tab, open, toggleDrawer, menus }) {
	const { user_id } = isTokenValid()

	return (
		<DrawerBase variant="permanent" open={open}>
			<Toolbar
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					p: 0, px: [1],
				}}
				variant='dense'
			>
				{
					open && 
						<Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', my: 1 }}>
							<StaffInfo user_id={user_id} />
						</Box>
				}
				<IconButton onClick={toggleDrawer} sx={{ p: '2px' }}>
					<ChevronLeft />
				</IconButton>
			</Toolbar>
			<Divider />
			<List component="nav" sx={{ ml: 1 }}>
				<Menu tab={tab} menus={menus} open={open} />
				<Divider sx={{ my: 1 }} />
			</List>
		</DrawerBase>
	)
}