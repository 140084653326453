import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { HighlightAlt } from '@mui/icons-material'

export default function SelectionTool({ handleToSelect, toSelect, w }) {
    return (
        <Box 
            sx={{
                boxShadow: '0 2px 10px #000',
                backgroundColor: 'white', 
                borderRadius: '5px',
                p: '5px',
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                position: 'absolute', 
                left: `${w+10}px`,
                top: 0
            }}
        >
            <Tooltip title={<Typography sx={{ fontSize: '13px' }}>{toSelect ? 'Болих (Esc)' : 'Байршлууд сонгох: (S)'}</Typography>} placement='right'>
                <IconButton onClick={handleToSelect} sx={{ p: 0 }}>
                    <HighlightAlt
                        sx={{ 
                            fontSize: '30px', 
                            color: toSelect ? '#1967D2' : 'initial',
                            boxShadow: toSelect ? 'inset 2px 2px 5px rgba(0, 0, 0, 0.3)' : 'none'
                        }} 
                    />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
