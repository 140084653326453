import { Box, Button, Dialog, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { scrollStyle } from '../../../../../../../../utils/constants'
import { getCustomers } from '../../../../../../../../api/promo'
import { Close, Search } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import { useAdminCtx } from '../../../../../..'
import { useQuery } from 'react-query'
import { CheckCell } from '../Cells'


export default function SelectCustomer({ selected, setSelected }) {
    const { alertWarning } = useAdminCtx()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [name, setName] = useState('')
    const { data, isFetched, isFetching } = useQuery(['customers', name], getCustomers, { onError: alertWarning })
    const customers = data?.data?.results||[]
    //console.log("ISF: ", data)

    // delayed search
    const [search, setSearch] = useState(null)
    const searchTimeoutRef = useRef(null)
    useEffect(() => {
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current)
            searchTimeoutRef.current = null
            //console.log("cleared")
        }
        searchTimeoutRef.current = setTimeout(() => { setName(search) }, 650)
        return () => { clearTimeout(searchTimeoutRef.current) }
    }, [search])

    const addToSelected = (customer) => {
        if (selected.some(s => s.id === customer.id)) {
            setSelected(prev => [...new Set(prev.filter(s => s.id !== customer.id))])
        } else { setSelected([...selected, customer]) }
    }

    return (
        <>
            <Button variant='contained' size='small' onClick={handleOpen}>Харилцагч нэмэх</Button>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <Box sx={{ p: 2, width: 730, height: 450  }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            variant='standard'
                            size='small'
                            label='Харилцагч хайх'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            sx={{ width: '50%', mb: 1 }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                                ),
                            }}
                        />
                        <IconButton sx={{ p: '2px' }} onClick={handleClose}><Close /></IconButton>
                    </Box>
                    <Paper sx={{ p: 1, display: 'flex', justifyContent: 'space-between', boxShadow: 5 }}>
                        <TableContainer sx={{ height: 350, maxHeight: 350, ...scrollStyle, '&::-webkit-scrollbar': { width: 8 } }}>
                            <Table>
                                <TableHead>
                                    <TableRow sx={{ '& > th': { py: '2px', px: '10px', backgroundColor: 'secondary.light', color: 'white', borderRight: '1px solid white' } }}>
                                        <TableCell width='20px' />
                                        <TableCell sx={{ px: '4px !important' }}>№</TableCell>
                                        {
                                            ['Харилцагчийн нэр', 'Регистр', 'Имэйл', 'Утас'].map(
                                                (col, idx) => <TableCell key={idx}>{col}</TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        customers.map((customer, idx) => 
                                            <TableRow 
                                                key={idx} 
                                                sx={{ '& > td': { py: '1px', px: '10px' }, '&:hover': { backgroundColor: '#e5e5e5' }, cursor: 'pointer' }}
                                                onClick={() => {addToSelected(customer.partner)}}
                                            >
                                                <CheckCell checked={selected.some(s => s.id === customer.partner?.id)} />
                                                <TableCell sx={{ textAlign: 'center !important' }}>{idx+1}</TableCell>
                                                <TableCell>{customer.partner?.name||'-'}</TableCell>
                                                <TableCell>{customer.partner?.rd||'-'}</TableCell>
                                                <TableCell>{customer.partner?.email||'-'}</TableCell>
                                                <TableCell>{customer.partner?.phone||'-'}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Box>
            </Dialog>
        </>
    )
}
