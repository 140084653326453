import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import BottomNavigation from '@mui/material/BottomNavigation'
import CssBaseline from '@mui/material/CssBaseline'
import { Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import CustomerList from './customers'
import ProductList from './products'
import Box from '@mui/material/Box'
import SellerBasket from './basket'
import * as React from 'react'

export default function FixedBottomNavigation({ tabs, tabIdx, setTabIdx, cData, pData, order, setOrder, basket }) {
	const ref = React.useRef(null)
	const customers = cData?.data?.results||[]
	const products = pData?.data?.results||[]

	const selectCustomer = (customer_id) => { setOrder(prev => ({ ...prev, customer_id })) }
	const blen = basket?.items?.length
	return (
		<Box sx={{ pb: 7, mt: 15 }} ref={ref}>
			<CssBaseline />
			{ tabIdx === 0 && <CustomerList customers={customers} selectCustomer={selectCustomer} selected={order?.customer_id} /> }
			{ tabIdx === 1 && <ProductList products={products} /> }
			{ tabIdx === 2 && <SellerBasket basket={basket} />}
			<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={10}>
				<BottomNavigation
					showLabels
					value={tabIdx}
					onChange={(event, tabIdx) => { setTabIdx(tabIdx) }}
					sx={{ height: 95 }}
				>
					{tabs?.map(({ idx, label, icon }) => (
						<BottomNavigationAction
							value={idx}
							label={<Typography fontSize={'23px'}>{label}</Typography>}
							icon={idx === 2 ? icon(blen) : icon()}
							sx={(theme) => ({
								'&.Mui-selected': {
									'& .MuiBottomNavigationAction-label': { color: theme.palette.secondary.light, fontWeight: 'bold' },
									'& .MuiSvgIcon-root': { color: theme.palette.secondary.light },
								},
							})}
						/>
					))}
				</BottomNavigation>
			</Paper>
		</Box>
	);
}