import { useBcCtx } from '../../../../../context/BroadcastCtx'
import { getToast } from '../../../../auth/helpers/ToastAlert'
import { useAuthCtx } from '../../../../../context/AuthCtx'
import { Box, Button, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { cp } from '../../../../../api/company'
import { useNavigate } from 'react-router'
import QRCode from 'qrcode.react'


const Qr = ({ qr, boxOff }) => {
    const { clearUserItems } = useAuthCtx()

    const [paid, setPaid] = useState(null)
    const isPaid = paid?.isPaid
    
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    const check = () => {
        cp()
            .then(res => {
                if ( res?.data?.isPaid === true ) {
                    setPaid(res?.data)
                    setErr(null)
                } else if (res?.data === false) {
                    setErr('Төлбөр төлөгдөөгүй байна!')
                }
            })
            .catch(() => {})
    }

    const navi = useNavigate()
    const toCompleted = () => { boxOff(); clearUserItems(); navi('/order/completed', { state: { orderNo: paid?.orderNo }}) }
    useEffect(() => {
        if (qr) { setTimeout(() => { boxOff() }, 1000 * 300) }
        if (isPaid) { setTimeout(() => { toCompleted() }, 1000 * 3) }
    }, [qr, isPaid])

    const { msg, setMsg } = useBcCtx()
    useEffect(() => {
        if (msg && msg?.orderNo && msg?.isPaid) {
            setPaid({ 'orderNo': msg.orderNo, 'isPaid': msg.isPaid })
            setMsg(null)
        }
    }, [msg])

    const qmsg = isPaid ? 'Төлбөр төлөгдлөө!' : 'Доорх QR кодыг уншуулж төлбөр төлснөөр захиалга баталгаажна.'
    
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mb: 5 }}>
            { getToast(Boolean(err), errOff, err, 'warning') }
            <Typography
                textAlign='center' fontWeight='bold'
                fontSize={ isPaid ? '20px': '14px' }
                sx={{
                    color: isPaid ? 'green' : '#19305C',
                    mb: 1, mt: 1, maxWidth: '200px'
                }}
            >{qmsg}</Typography>
            {
                !isPaid
                    ?   <Box sx={{ display: 'flex', flexDirection: 'column',  width: '150px', height: '150px', mt: 1 }}>
                            <Box 
                                sx={{ 
                                    'boxShadow': '0px 2px 8px rgba(0, 0, 0, 0.2)',
                                    display: 'flex', justifyContent: 'center', p: '4px'
                                }}
                            >
                                <QRCode value={qr} />
                            </Box>
                            <Button size='small' variant='contained' sx={{ mt: 3 }} onClick={check}>
                                Төлбөр шалгах
                            </Button>
                        </Box>
                    :   null
            }
        </Box>
    )
}

export default Qr