import { APILoadingStatus, APIProvider, Map, useApiLoadingStatus } from '@vis.gl/react-google-maps'
import { getAddressByCoords } from '../../../../../api/google_map'
import { mapOptions, maxBounds } from '../../../../utils'
import { Box, Button, Typography } from '@mui/material'
import { getToast } from '../../../helpers/ToastAlert'
import { useEffect, useState } from 'react'
import { isEmpty } from 'underscore'
import loc from './loc.png'
import * as Yup from 'yup'

const matchTypes = ['street_address', 'route', 'administrative_area_level_2', 'administrative_area_level_1', 'country']
const latSchema = Yup.number().min(-90, 'Байршлын координат буруу!').max(90, 'Байршлын координат буруу!')
const lngSchema = Yup.number().min(-180, 'Байршлын координат буруу!').max(180, 'Байршлын координат буруу!')

const boundRestriction = {
    restriction: {
        latLngBounds: maxBounds,
        strictBounds: true
    },
}

export default function LocationPicker({ addressInfo, setAddressInfo, setIsApiFailed, setIsGettingAddress, handleLocAddress }) {
    const [center, setCenter] = useState({ lat: 47.920761, lng: 106.917275 })
    // handle address or coords
    const handleGetAddress = () => {
        setIsGettingAddress(true)
        getAddressByCoords(center)
            .then(res => {
                const address2 = res?.data?.results?.find(r => matchTypes.find(w => r?.types?.includes(w)))?.formatted_address
                handleLocAddress(address2)
                const { lat, lng } = center
                setAddressInfo({ lat: lat?.toFixed(6), lng: lng?.toFixed(6), address2 })
                setIsGettingAddress(false)
            })
            .catch(e => {
                console.log("ERR: ", e)
                setIsGettingAddress(false)
            })
    }

    const apiStatus = useApiLoadingStatus()
    const [alert, setAlert] = useState(null)    
    useEffect(() => {
        if ([
            APILoadingStatus.FAILED, 
            APILoadingStatus.AUTH_FAILURE].includes(apiStatus))
            { setIsApiFailed(true) }
        if (addressInfo?.lat && addressInfo?.lng) {
            const lat = parseFloat(addressInfo?.lat)
            const lng = parseFloat(addressInfo?.lng)
            if (latSchema.isValidSync(lat) && lngSchema.isValidSync(lng)) {
                setCenter({ lat, lng })
                if (addressInfo?.address2) { handleLocAddress(addressInfo?.address2) }
            } else { setAlert({m: 'Байршлын координат буруу!', s: 'warning'}); console.log("WRONG") }
        }
    }, [])

    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} region='MN'>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                {getToast(Boolean(!isEmpty(alert)), () => setAlert(null), alert?.m, alert?.s)}
                <Box sx={{ width: '90%', height: '70vh', border: 1, borderColor: '#c4c4c4', borderRadius: 1 }}>
                    <Map
                        reuseMaps={true}
                        defaultCenter={center}
                        onZoomChanged={(e) => {
                            const center = e?.detail?.center
                            if (center) { setCenter(center)}
                        }}
                        onBoundsChanged={(e) => {
                            const center = e?.detail?.center
                            if (center) { setCenter(center)}
                        }}
                        defaultZoom={13}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        minZoom={6}
                        maxZoom={18}
                        mapId={process.env.REACT_APP_GOOGLE_MAP_ID2}
                        options={{ ...mapOptions, ...boundRestriction }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',  
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: 10,
                            }}
                        >
                            <img src={loc} alt='loc.png' style={{ width: '60px', height: '60px' }} />
                        </div>
                    </Map>
                </Box>
                <Typography fontSize={'15px'} sx={{ color: 'primary.light', my: 1, textAlign: 'center' }}>Газрын зургийг чирж хаяг дээрээ очсны дараа 'Байршил сонгох' дээр дарна.</Typography>
                <Button
                    variant='contained'
                    size='small'
                    onClick={handleGetAddress}
                >Байршил сонгох</Button>
            </Box>
        </APIProvider>
    )
}

