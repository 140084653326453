import { primaryLight, secondaryLight } from '../../../../../../../utils/constants'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Delete } from '@mui/icons-material'


export default function Thumbnail({ file, removeImg }) {
    const isImg = file instanceof File && file.type.includes('image')
    const isPdf = file instanceof File && file.type.includes('pdf')

    const handleRemove = (e) => { removeImg(file.name); e.stopPropagation() }

    const handleImgOpen = (e) => { window.open(URL.createObjectURL(file), '_blank'); e.stopPropagation() }

    return (
        <Box sx={{ position: 'relative', width: '80px', height: '80px' }} onClick={handleImgOpen}>
            <img src={isImg ? URL.createObjectURL(file) : isPdf ? './pdf.png' : ''} style={{ width: '80px', height: '80px', borderRadius: '8px', boxShadow: '4px 4px 4px grey' }} />
            <Tooltip title='Устгах' placement='right'>
                <IconButton sx={{ position: 'absolute', bottom: -5, right: -5, p: '2px', backgroundColor: 'white', '&:hover': { backgroundColor: 'white' } }} onClick={handleRemove}>
                    <Delete sx={{ color: primaryLight, '&:hover': { color: secondaryLight } }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
