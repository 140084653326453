import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Clear, Logout } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import * as React from 'react';
import { useSellerCtx } from '..';
import { Typography } from '@mui/material';

export default function SearchCustomer({ tabIdx, seval, setSeVal }) {
	const { custName } = useSellerCtx()
	return (
		<AppBar position="fixed" sx={{ width: '100%', py: 2 }}>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					sx={{ p: 0, m: 0, mr: 2 }}
				>
					<MenuIcon sx={{ fontSize: '35px' }} />
				</IconButton>
				{
					tabIdx === 2
						?	<Typography sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1, color: 'white', fontSize: '25px' }}>{custName||''}</Typography>
						:	<Box 
								sx={(theme) => ({ 
									display: 'flex', 
									flexGrow: 1,
									justifyContent: 'center', 
									alignItems: 'center', 
									borderRadius: theme.shape.borderRadius,
									backgroundColor: alpha(theme.palette.common.white, 0.15),
									'&:hover': {
										backgroundColor: alpha(theme.palette.common.white, 0.25),
									},
								})}
							>
								<IconButton
									type="submit"
									sx={{ p: '10px', color: 'white' }}
									aria-label="search"
								>
									<SearchIcon sx={{ fontSize: '30px' }} />
								</IconButton>
								<InputBase
									placeholder={`${tabIdx === 0 ? 'Харилцагч' : 'Бараа'} хайх`}
									inputProps={{ 'aria-label': 'search', style: { fontSize: '23px' } }}
									sx={{ display: 'flex', flexGrow: 1, color: 'white' }}
									value={seval||''}
									onChange={(e) => setSeVal(e.target.value)}
								/>
								<IconButton
									type="submit"
									sx={{ p: '10px', color: 'white' }}
									aria-label="search"
									onClick={() => setSeVal(null)}
								>
									<Clear sx={{ fontSize: '30px' }} />
								</IconButton>
							</Box>
				}
				<IconButton
					color="inherit"
					aria-label="open drawer"
					sx={{ p: 0, m: 0, ml: 2 }}
				>
					<Logout sx={{ fontSize: '35px' }} />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
