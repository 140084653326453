import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material'
import { usePromoOrderCtx } from '../..'
import { useState } from 'react'

const noteLimit = 400
export default function Note() {
    const { setOrder, setMsg } = usePromoOrderCtx()
    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }

    const [val, setVal] = useState(null)
    const handleChange = (e) => {
        const newInput = e.target.value??''
        const bk = e.key === 'Backspace' || e.code === 8
        if (newInput.length === 0) { setVal(null) }
        else {
            if (newInput.length >= noteLimit) {
                setVal(newInput.slice(0, noteLimit))
                if (!bk) { setMsg({ m: `${noteLimit} тэмдэгтээс хэтэрч болохгүй!`, s: 'warning' }) }
            } else { setVal(newInput) }
        }
    }
    const handleAdd = () => { setOrder(prev => ({ ...prev, note: val })); handleClose() }

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Typography
                onClick={handleOpen}
                sx={{
                    fontSize: '14px', fontWeight: 'bold', cursor: 'pointer',
                    color: 'secondary.light', textDecoration: 'underline'
                }}
            >Нэмэлт тайлбар бичих</Typography>
            <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
                <DialogContent>
                    <Typography sx={{ fontSize: '15px', textAlign: 'right', color: val?.length >= noteLimit ? 'secondary.light' : 'initial' }}>{`${val?.length??'0'}/${noteLimit}`}</Typography>
                    <TextField
                        autoFocus={open}
                        label='Нэмэлт тайлбар'
                        value={val}
                        onChange={handleChange}
                        multiline
                        rows={6}
                        sx={{ width: '100%' }}
                    />
                    <Box sx={{ width: '100%', textAlign: 'center', pt: 1 }}>
                        <Button size='small' variant='contained' onClick={handleAdd}>Нэмэх</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}
