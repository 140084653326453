import { Box, Dialog, DialogContent, TableCell, Typography } from '@mui/material'
import { moveShipmentItem } from '../../../../../../../../../api/shipment'
import { scrollStyle } from '../../../../../../../../../utils/constants'
import { useShipCtx } from '../../../../..'
import { useState } from 'react'

export default function MoveShipmentItem({ delmanNames, orderId, refetchShipmentHistory }) {
    const { setMsg } = useShipCtx()
    const [open, setOpen] = useState(false)
    const handleClose = () => { setOpen(false) }

    const handleSelect = (delmanId) => {
        moveShipmentItem({ orderIds: [orderId], delmanId })
            .then(_ => { 
                handleClose()
                refetchShipmentHistory()
                setMsg({ m: 'Амжилттай шилжүүллээ!', s: 'success'  })
            })
            .catch(err => {
                console.log("ERR: ", err)
                setMsg({ s: 'warning', m: 'Түр хүлээгээд дахин оролдоно уу!' })
            })
    }

    return (
        <>
            <TableCell
                sx={{
                    p: 0, 
                    position: 'relative',
                    '&:hover': { backgroundColor: '#e1e1e1', cursor: 'pointer' }
                }}
                onClick={() => setOpen(true)}
            >Сонгох</TableCell>
            {
                open &&
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxHeight: '300px',
                                backgroundColor: 'white',
                                ...scrollStyle,
                                '&::-webkit-scrollbar': { width: 5 }, width: '100%'
                            }}
                        >
                            {delmanNames?.map(d => (
                                <Typography
                                    key={`${d.id}`}
                                    onClick={ () => { handleSelect(d.id) } }
                                    sx={{
                                        borderBottom: '1px solid transparent',
                                        borderRadius: 0,
                                        fontSize: '13px',
                                        p: '4px 8px',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: '#f1f1f1',
                                            borderBottomColor: '#ad5555'
                                        }
                                    }}
                                >{d.name||''}</Typography>
                            ))}
                        </Box>
                    </DialogContent>
                </Dialog>
            }
        </>
    )
}
