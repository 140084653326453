import { List } from '@mui/material'
import ProductItem from './product'

export default function ProductList({ products }) {    
    return (
        <List>
            {
                products.map((prodData, idx) => (
                        <ProductItem key={prodData?.id} props={prodData} />
                    ))
            }
        </List>
    )
}