import { Box, Divider, ListItemButton, ListItemText, Typography } from "@mui/material"
import Contact from "./contact"

export default function CustomerItem({ props, handleSelect }) {
    const { id, name, loan_block } = props
    console.log('props: ', props)
    return (
        <>
            <ListItemButton key={id} sx={{ my: 1 }} onClick={() => handleSelect(id) }>
                <ListItemText
                    primary={
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                            <Typography fontSize='25px'>{name}</Typography>
                            {
                            loan_block && 
                                <Typography
                                    fontSize='22px'
                                    sx={{ 
                                        background: 'orange', 
                                        color: 'white',
                                        borderRadius: '5px',
                                        width: 'fit-content',
                                        p: '1px 8px'
                                    }}
                                >Зээлээр өгөхгүй</Typography>
                            }
                        </Box>
                    }
                    secondary={<Contact props={props} />}
                />
            </ListItemButton>
            <Divider component="li" />
        </>
    )
}