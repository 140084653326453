import { Checkbox, TableCell, Tooltip } from "@mui/material"

export const BoolCell = ({ checked }) => {
    return (
        <TableCell align='center'>
            <Checkbox checked={checked} inputProps={{ 'aria-label': 'controlled' }} size="small" />
        </TableCell>
    )
}

export const AdrCell = ({ value }) => {
    const s = value?.split(',')
    const frst = Array.isArray(s) ? s[1] : ''
    return (
        <Tooltip title={value||''} placement="right">
            <TableCell sx={{ textAlign: 'center' }}>{frst}</TableCell>
        </Tooltip>
    )
}