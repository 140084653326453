import { TextField } from '@mui/material'
import { useSellerCtx } from '../../..'

export default function Note() {
    const { order, setOrder } = useSellerCtx()
    return (
        <TextField
            rows={4}
            multiline
            fullWidth
            placeholder="Нэмэлт тайлбар"
            InputProps={{
                sx: {'& input::placeholder': { fontSize: '23px' }},
            }}
            value={order.note}
            onChange={(e) => { setOrder(prev => ({ ...prev, note: e.target.value })) }}
            sx={{ 
                boxShadow: 2, mb: 5,
                fontSize: '23px',
                "& .MuiInputBase-input::placeholder": { fontSize: "23px" }
            }}
            inputProps={{ style: { padding: "2px 5px", fontSize: "23px" } }}
            InputLabelProps={{ sx: { fontSize: '23px' } }}
        />
    )
}
