import { Box, Button, Dialog, DialogContent, IconButton, TableCell, Typography } from '@mui/material'
import { cancelShipment } from '../../../../../../../api/shipment'
import { Delete } from '@mui/icons-material'
import { useShipCtx } from '../../..'
import { useState } from 'react'

export default function CancelShip({ ship }) {
    const { setMsg, alertWarning, refetchShipments } = useShipCtx()
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleCancelOpen = () => {
        if (ship.startTime || ship.endTime) { setMsg({ m: 'Түгээлтийг цуцлах боломжгүй!', s: 'warning' }) }
        else { setOpen(true) }
    }

    const handleCancel = () => {
        cancelShipment(ship.id)
                .then(_ => {
                    setMsg({ m: 'Амжилттай цуцаллаа!', s: 'success' })
                    refetchShipments()
                })
                .catch(err => { console.log("err: ", err); alertWarning() })
    }

    return (
        <TableCell>
            <IconButton sx={{ p: '2px' }} onClick={handleCancelOpen}>
                <Delete sx={{ '&:hover': { color: 'secondary.light' } }} />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <Typography sx={{ mb: 1 }}>Түгээлтийг цуцлах уу?</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 1 }}>
                        <Button size='small' variant='contained' sx={{ bgcolor: 'secondary.light' }} onClick={handleCancel}>Тийм</Button>
                        <Button size='small' variant='contained' sx={{ bgcolor: 'primary.light' }} onClick={handleClose}>Үгүй</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </TableCell>
    )
}
