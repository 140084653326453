import { Box } from '@mui/material'
import CheckPin from './CheckPin'

import Qr from './Qr'

export default function VerifyBox({ createOrder, boxOff, payType, inv }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', p: 4 }}>
            {
                payType === 'C'
                    ?   <Qr inv={inv} boxOff={boxOff} />
                    :   <CheckPin createOrder={createOrder} boxOff={boxOff} />
            }
        </Box>
    )
}