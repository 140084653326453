import { AdvancedMarker } from '@vis.gl/react-google-maps'
import DelmanName from './LocationLabel/DelmanName'
import ContextMenuBox from './ContextMenuBox'
import Orderer from './LocationLabel/Orderer'
import { useCallback, useState } from 'react'
import LocationIcon from './LocationIcon'
import StopOrders from './StopOrders'
import { Box } from '@mui/material'
import CloseBtn from './CloseBtn'

export default function DeliveryStop({ stop, setMarkersRef, selected, delmans, showStopLabel, toSelect, handleRemoveFromSelected }) {
    const { lat, lng, cmp, branch, orders, delmanId, branchId } = stop
    const inSelected = selected?.find(s => s.order?.orderNo === stop.order?.orderNo)
    const orderer = branch ? `${cmp} - ${branch}` : `${cmp}`
    const [stopOrders, setStopOrders] = useState(orders||[])

    const ref = useCallback((marker) => {
        setMarkersRef(marker, stop?.branchId)
    }, [setMarkersRef, stop?.branchId])

    // handle remove
    const handleRemove = (e) => handleRemoveFromSelected(branchId, e)

    // right click
    const [cmb, setCmb] = useState(false)
    const handleCmbClose = () => { setCmb(false) }
    const handleContextMenu = (e) => { setCmb(true) }
    const isSelected = inSelected || cmb

    // hover
    const [hover, setHover] = useState(false)
    const handleHoverOff = () => { setHover(false); handleCmbClose() }
    
    let color = (stop?.delmanId && delmans?.length > 0) ? `${delmans.find(d => d.id === stop.delmanId)?.color||"#004EFF"}` : "#004EFF"
    const uniqDelmans = orders?.map(o => o.delmanId)?.reduce((acc, delmanId) => acc.includes(delmanId) ? acc : [...acc, delmanId], [])||[]
    const delmanNames = uniqDelmans?.map(id => delmans?.find(d => d.id === id)?.name)||[]

    const [open, setOpen] = useState(false)
    const handleOpen = () => { setOpen(true) }
    const handleClose = () => { setOpen(false) }
    const handleClick = () => { handleOpen() }

    if (!lat||!lng) return null
    return (
        <AdvancedMarker
            position={{ lat, lng }}
            onClick={handleClick}
            ref={ref}
        >
            <Box 
                sx={{
                    width: '50px', height: '50px',
                    transform: `translate(5%, 45%)`,
                    position: 'relative'
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={handleHoverOff}
                onContextMenu={handleContextMenu}
            >
                <LocationIcon 
                    isSelected={isSelected}
                    color={color}
                    delmanId={delmanId}
                    uniqDelmans={uniqDelmans}
                />
                {inSelected && <CloseBtn handleRemove={handleRemove} bgcolor={color} />}
                <StopOrders 
                    open={open}
                    handleClose={handleClose}
                    orderer={orderer}
                    stopOrders={stopOrders}
                    setStopOrders={setStopOrders}
                    delmans={delmans}
                    delmanId={delmanId}
                />
                {
                    orderer && showStopLabel && !toSelect && 
                        <Orderer 
                            orderer={orderer}
                            ordersLen={stopOrders.length}
                        />
                }
                {
                    !showStopLabel && Boolean(orders?.find(o => o.delmanId)) &&
                        <DelmanName delmanNames={delmanNames} />
                }
                {
                    cmb && hover &&
                        <ContextMenuBox
                            handleCmbClose={handleCmbClose}
                            currentDelmanId={1}
                            orderIds={stop?.orderIds}
                        />
                }
            </Box>
        </AdvancedMarker>
    )
}
