import AxiosWithToken from "../global/axiosBase"
import { keys } from "underscore"

export const getOrders = ({ queryKey }) => {
    const [_, filters, pageSize, page] = queryKey
    let qParam = 'pharmacy/orders/'
    if (keys(filters)?.length) {
        for (let f of filters) {
            let fq = f?.k + f?.o + f?.v
            qParam += qParam?.endsWith('/') ? `?${fq}` : qParam.endsWith('?') ? fq : `&${fq}`
        }
    }
    if (page) {
        let pgs = `page=${page}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }
    if (pageSize) {
        let pgs = `page_size=${pageSize}`
        qParam += qParam.endsWith('/') ? `?${pgs}` : `&${pgs}`
    }
    return AxiosWithToken.get(qParam)
}