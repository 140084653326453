import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { toPrice } from "../../../../utils/functions"
import PayTypeSelection from "./payType"
import { useSellerCtx } from "../.."
import Note from "./note"

export default function SellerBasket({ basket }) {
    const { order, createOrder } = useSellerCtx()
    console.log("ORDER: ", order)
    return (
        <div>
            <Table sx={{ boxShadow: 2 }}>
                <TableHead>
                    <TableRow sx={{ '&>th': { p: '2px 6px', fontSize: '20px', textAlign: 'center', backgroundColor: 'secondary.light', color: 'white' } }}>
                        <TableCell>№</TableCell>
                        <TableCell sx={{ textAlign: 'left !important' }}>Бараа</TableCell>
                        <TableCell>Тоо ширхэг</TableCell>
                        <TableCell>Нэгж үнэ</TableCell>
                        <TableCell>Нийт үнэ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        basket?.items?.map((item, idx) => (
                            <TableRow key={idx} sx={{ '&>td': { p: '2px', fontSize: '20px', textAlign: 'center' } }}>
                                <TableCell>{idx + 1}</TableCell>
                                <TableCell sx={{ textAlign: 'left !important' }}>{item.product_name}</TableCell>
                                <TableCell>{item.qty}</TableCell>
                                <TableCell sx={{ textAlign: 'right !important' }}>{toPrice(item.price)}</TableCell>
                                <TableCell sx={{ textAlign: 'right !important' }}>{toPrice(item.qty * item.price)}</TableCell>
                            </TableRow>
                        ))
                    }
                    <TableRow sx={{ '&>td': { p: '2px', fontSize: '20px', textAlign: 'center' } }}>
                        <TableCell colSpan={3} sx={{ textAlign: 'right !important' }}>Нийт</TableCell>
                        <TableCell colSpan={2} sx={{ textAlign: 'right !important', fontWeight: 'bold' }}>{toPrice(basket?.items?.reduce((acc, cur) => acc + cur.qty * cur.price, 0))}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <PayTypeSelection />
            <Note />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    onClick={() => createOrder()}
                    sx={{ 
                        mb: 10, 
                        fontSize: '23px',
                        bgcolor: 'purple',
                        position: 'fixed', 
                        bottom: 50
                    }}
                >Захиалга үүсгэх</Button>
            </Box>
        </div>
    )
}
