import { FilterAlt } from "@mui/icons-material";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { primaryLight, secondaryLight } from "../../../../utils/constants";

export default function FilterRow({ filtering, setFiltering }) {
  return (
    <TableRow
      sx={{
        "& > td": {
          p: "2px ",
          borderBottom: "1px solid gray",
          borderBottomColor: primaryLight,
        },
      }}
    >
      <TableCell align="center" sx={{ p: "2px" }}>
        <Tooltip title="Шүүж хайх мөр" placement="right">
          <FilterAlt sx={{ color: secondaryLight, fontSize: "20px", margin: 0 }} />
        </Tooltip>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell width={80} />
    </TableRow>
  );
}
