import { Box, Button, IconButton, Toolbar, styled } from '@mui/material'
import Notification from '../../navbar/RightSection/Notification'
import { isTokenValid } from '../../../utils/functions'
import { drawerWidth } from '../../../utils/constants'
import { useNavigate } from 'react-router-dom'
import MuiAppBar from '@mui/material/AppBar'
import { Menu } from '@mui/icons-material'
import LogOut from './LogOut'

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }))

export default function AdminNav({ open, toggleDrawer }) {
    const navi = useNavigate()
    const handleClick = () => { navi('/') }
    const { role } = isTokenValid()

    return (
        <AppBar position="absolute" open={open}>
            <Toolbar 
				sx={{ pr: '24px' }}
				variant='dense'
			>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                	{ !open && <IconButton onClick={toggleDrawer} sx={{ p: '2px', pr: 3 }}><Menu sx={{ color: 'white' }} /></IconButton>}
                </Box>
                {
					['A', 'S', 'B'].includes(role) && 
					<Button 
						sx={{ 
							color: 'white', justifyContent: 'start', p: 0, borderBottom: '1px solid transparent',
							'&:hover': { borderBottomColor: 'white', borderRadius: 0 }, fontSize: '13px', mr: 1
						}}
						onClick={handleClick}
					>Нүүр хуудас</Button>
				}
                <Notification />
				<Box sx={{ flexGrow: 1 }}></Box>
                <LogOut />
            </Toolbar>
        </AppBar>
    )
}
