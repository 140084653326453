import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import { useState } from 'react'

export default function PasswordField(props) {
    const [isShow, setIsShow] = useState(false)
    const handleShow = () => { setIsShow(true) }
    const handleHide = () => { setIsShow(false) }
    return (
        <TextField
            label='Нууц үг'
            name='password'
            size='small'
            {...props}
            type={ isShow ? 'text' : 'password' }
            InputProps={{
                endAdornment: <InputAdornment position='end'>
                                <IconButton 
                                        sx={{ m: 0, cursor: 'default' }} 
                                        onMouseEnter={handleShow}
                                        onMouseLeave={handleHide}
                                    >
                                        <Visibility />
                                    </IconButton>
                            </InputAdornment>
            }}
        />
    )
}