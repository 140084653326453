import { primaryLight, secondaryLight } from '../../../../../utils/constants'
import { compressImgs } from '../../../../../utils/functions'
import { AddPhotoAlternate } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import Thumbs from './Thumbs'


const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/webp']
const allowedFileSize = 10 // 10mb
const allowedFileNameLength = 150
const allowFileCount = 3

export default function LicenseInput({ licenseFiles, setLicenseFiles }) {
    const [fileErr, setFileErr] = useState(null)
    useEffect(() => {
        if (fileErr) setTimeout(() => { setFileErr(null) }, 3000)
    }, [fileErr])

    const fileInputClick = () => {
        document.getElementById('licenseInput').click()
    }
    const handleFileSelection = (e) => {
        const newFiles = e.target.files
        const validFiles = []
        if (newFiles?.length > 0) {
            if (newFiles.length + licenseFiles.length > allowFileCount) { setFileErr(`Зургийн тоо ${allowFileCount}-с ихгүй байх ёстой!`); return }
            for (let newFile of newFiles) {
                if (!allowedFileTypes.includes(newFile.type)) { setFileErr(`Файлын төрөл буруу! JPG, JPEG, WEBP байх ёстой!`); break }
                else if (newFile.size / 1024 / 1024 > allowedFileSize) { setFileErr(`${newFile.name} нэртэй файлын хэмжээ хэт их байна. ${allowedFileSize} MB-с ихгүй байх ёстой!`); break }
                else if (newFile.name?.length > allowedFileNameLength) { setFileErr(`Файлын нэр хэт урт. ${allowedFileNameLength} тэмдэгтээс ихгүй байх ёстой!`); break }
                else { validFiles.push(newFile) }
            }
            if (validFiles?.length > 0) {
                compressImgs(licenseFiles, validFiles, setLicenseFiles)
            }
        } else { setFileErr('Файл хавсаргана уу!') }
    }

    const [isHovered, setIsHovered] = useState(false)
    
    return (
        <>
            <input
                accept="*/*"
                id="licenseInput"
                multiple
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileSelection}
            />
            <Box 
                sx={{ 
                    border: '2px dashed grey', borderRadius: '10px', 
                    p: 1, height: '120px', display: 'flex', flexDirection: 'column',
                    justifyContent: 'center', alignItems: 'center',
                    '&: hover': { borderColor: secondaryLight, cursor: 'pointer' }
                }}
                onClick={fileInputClick}
                onMouseOver={() => { setIsHovered(true) }}
                onMouseLeave={() => { setIsHovered(false) }}
            >
                <Typography fontSize='15px' sx={{ color: isHovered ? secondaryLight : primaryLight }}>Тусгай зөвшөөрөл хавсаргах</Typography>
                {
                    licenseFiles?.length > 0
                        ?   <Thumbs licenseFiles={licenseFiles} setLicenseFiles={setLicenseFiles} />
                        :   <AddPhotoAlternate sx={{ fontSize: '50px', color: 'grey', opacity: isHovered ? 1 : 0.5 }} />
                }
            </Box>
            <Typography fontSize='15px' sx={{ color: secondaryLight, textAlign: 'center' }}>{fileErr}</Typography>
        </>
    )
}