import { primaryLight, scrollStyle } from '../../../../../../../../../utils/constants'
import { Box, Dialog, InputAdornment, TableCell, TextField } from '@mui/material'
import { useProductListCtx } from '../../../..' 
import { Close } from '@mui/icons-material'
import Category from './Category'
import { useState } from 'react'

export default function ByCategory() {
    const { setFilters } = useProductListCtx()
    const [selCatName, setSelCatName] = useState(null)
    
    const [open, setOpen] = useState(false)
    const toOpen = () => { setOpen(true) }
    const toClose = () => { setOpen(false) }
    const handleClear = (e) => { 
        setFilters(prev => prev.filter(f => f.k !== 'category'))
        setSelCatName(null)
        e.stopPropagation() 
    }

    return (
        <TableCell key='category' sx={{ p: 0, px: '2px' }}>
            <TextField
                size='small'
                placeholder='Ангиллалаар'
                value={selCatName||''}
                sx={{
                    flex: 1, width: '100%',
                    '&:hover fieldset': { borderColor: primaryLight },
                    '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '14px', opacity: 1, ml: '5px' },
                }}
                inputProps={{ style: { padding: '3px 5px', fontSize: '14px', color: primaryLight, cursor: 'pointer' } }}
                InputProps={{ 
                    readOnly: true,
                    endAdornment: (
                                <InputAdornment>
                                    <Close
                                        sx={{ 
                                            '&:hover': { cursor: 'pointer' },
                                            fontSize: '18px', color: primaryLight,
                                            visibility: Boolean(selCatName) ? 'visible' : 'hidden'
                                        }} 
                                        onClick={handleClear}
                                    />
                                </InputAdornment>
                            )
                }}
                autoComplete='off'
                onClick={toOpen}
            />
            <Dialog open={open} onClose={toClose}>
                <Box sx={{ width: '300px', maxHeight: '600px', ...scrollStyle }}>
                    <Category setFilters={setFilters} toClose={toClose} setSelCatName={setSelCatName} />
                </Box>
            </Dialog>
        </TableCell>
    )
}
