import { InputAdornment, TableCell, TextField, Tooltip } from '@mui/material'
import { primaryLight } from '../../../../../../../../utils/constants'
import DropCellMenu from '../DropCell/DropCellMenu'
import { Close } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import Inputs from './Inputs'
import * as Yup from 'yup'

const nameValid = Yup.object({
    name: Yup.string().max(150, 'Нэр хэт урт байна!').required('Захиалагчийн нэр оруулна уу!')
})
const rdValid = Yup.object({
    rd: Yup.string().max(7, 'РД буруу байна!').required('Захиалагчийн РД шаардлагатай!')
})

const fields = ['user_company_name', 'user_company_rd']

export default function UserCell({ filters, setFilters }) {
    const prevVal = filters?.find(f => fields.includes(f.fKey))
    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    useEffect(() => { if (error) { setTimeout(() => { setError(null) }, 2000) } }, [error])

    const filt = (fk, value) => {
        const f = {fKey: fk, fValue: value, operator: '='}
        setFilters(prev => {
            if (prev?.length) {
                return [ ...prev.map(p => !fields.includes(p.fKey) ? f : p) ]
            } else { return [f] }
        })
        setError(null)
        setSuccess(true)
    }
    
    const doFilter = () => {        
        if (value) {
            if (!isNaN(value)) {
                rdValid.validate({ rd: value })
                    .then(_ => { filt('user_company_rd', value) })
                    .catch(e => { setError('РД буруу байна!'); setSuccess(false) })
            } else { 
                nameValid.validate({ name: value })
                    .then(_ => { filt('user_company_name', value) })
                    .catch(e => { setError('Нэр буруу байна!'); setSuccess(false) })
            }
        }
    }

    const doClear = () => {
        setFilters(prev => prev?.filter(p => !fields.includes(p.fKey)))
        setValue(null)
    }

    const [isPinned, setIsPinned] = useState(false)    

    return (
        <TableCell key='user' sx={{ p: '2px' }}>
            <DropCellMenu
                title={<Tooltip title={prevVal?.fValue} placement='right-start' open={Boolean(prevVal?.fValue)}>
                            <TextField
                                placeholder={prevVal?.fValue||'Хайх'}
                                InputProps={{
                                    endAdornment: (<InputAdornment>
                                                        <Close 
                                                            sx={{ 
                                                                fontSize: '15px', color: primaryLight, 
                                                                '&:hover': { cursor: 'pointer' },
                                                                display: Boolean(value && success) ? 'block' : 'none'
                                                            }}
                                                            onClick={doClear}
                                                        />
                                                    </InputAdornment>)
                                }}
                                inputProps={{ style: { padding: '2px 5px', cursor: 'pointer' } }}
                                sx={{
                                    '& fieldset': { borderColor: primaryLight, borderRadius: 0 },
                                    '&:hover fieldset': { borderColor: primaryLight },
                                    '& .MuiInputBase-input::placeholder': { color: primaryLight, fontSize: '13px', opacity: 1, ml: '5px' },
                                    '& .MuiInputBase-input': { 'caretColor': 'transparent' }
                                }}
                                size='small'
                            />
                        </Tooltip>}
                detail={
                    <Inputs 
                        value={value} setValue={setValue} 
                        doFilter={doFilter} error={error}
                        isPinned={isPinned} setIsPinned={setIsPinned}
                    />
                }
            />
        </TableCell>
    )
}
