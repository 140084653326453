import { Close } from '@mui/icons-material'
import { Box, InputAdornment, MenuItem, TableCell, TextField, Tooltip, Typography } from '@mui/material'
import { useState } from 'react'
import DropCellMenu from './DropCellMenu'

export default function DropCell({ field, choices, filters, setFilters }) {
    const value = filters?.find(f => f.k === field)?.v
    const handleSelect = (value) => {
        setFilters(prev => {
            const f = { k: field, v: value, o: '=' }
            if (prev?.length) {
                if (prev.map(p => p.k).includes(field)) {
                    return prev.map(p => p?.k === field ? f : p)
                } else { return [...prev, f] }
            }
            return [f];
        })
    }

    const clearSelect = () => { setFilters(prev => prev?.filter(p => p.k !== field)) }
    const [hover, setHover] = useState(false);

    return (
        <TableCell key={field} sx={{ p: '2px', width: '250px' }}>
            <DropCellMenu title={
                <Tooltip title={<Typography fontSize={'13px'}>{choices?.[value]}</Typography>} placement='top' open={Boolean(value && hover)}>
                    <TextField
                        onMouseEnter={() => { setHover(true) }}
                        onMouseLeave={() => { setHover(false) }}
                        placeholder={choices?.[value] ?? 'Сонгох'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment sx={{ backgroundColor: 'transparent' }}>
                                    <Close
                                        sx={{ fontSize: '15px', color: 'primary.light', backgroundColor: 'transparent', '&:hover': { cursor: 'pointer' }, display: value ? 'block' : 'none', }}
                                        onClick={clearSelect}
                                    />
                                </InputAdornment>)
                        }}
                        inputProps={{ style: { padding: '2px 5px', cursor: 'pointer' } }}
                        sx={{
                            '& fieldset': { borderRadius: 0, borderColor: 'primary.light' },
                            '& .MuiInputBase-input::placeholder': {
                                color: 'primary.light', textAlign: 'center',
                                fontSize: '13px', opacity: 1
                            },
                            '& .MuiInputBase-input': { 'caretColor': 'transparent' },
                            border: 'none', width: '100%'
                        }}
                        size='small'
                    />
                </Tooltip>}
                detail={
                    <Box sx={{ backgroundColor: 'white', boxShadow: 8 }}>
                        {
                            (((choices).map(k =>
                                <MenuItem key={k} sx={{ fontSize: '13px', color: 'primary.light' }} onClick={() => handleSelect(k)}>
                                    {k}
                                </MenuItem>)))

                        }
                    </Box>}
            />
        </TableCell >
    )
}
