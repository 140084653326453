import PurchasePromo from '../../../../../home/Main/Result/Promos/Promo/PurchasePromo'
import ProductPromo from '../../../../../home/Main/Result/Promos/Promo/ProductPromo'
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import BundlePromo from '../../../../../home/Main/Result/Promos/Promo/BundlePromo'
import { scrollStyle } from '../../../../../../utils/constants'
import { Close } from '@mui/icons-material'
import ActionBtns from './ActionBtns'
import { useState } from 'react'

export default function MarkedPromos({ promos, setPromos, refetch }) {
    const handleClose = () => { setPromos([]) }
    const [index, setIndex] = useState(0)

    return (
        <Dialog open={true} onClose={handleClose} aria-describedby="alert-dialog-slide-description" maxWidth='md' fullWidth>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <DialogTitle sx={{ color: 'primary.light' }}>{"Онцлох урамшуулал №" + (index + 1)}</DialogTitle>
                <IconButton onClick={handleClose} sx={{ p: '2px', m: 0, mr: 3 }}><Close /></IconButton>
            </Box>
            <DialogContent sx={{ p: 2, ...scrollStyle, '&::-webkit-scrollbar': { width: 8 } }}>
                {promos.length > 0 && promos[index].promo_type === 1 && <BundlePromo promo={promos[index]} />}
                {promos.length > 0 && promos[index].promo_type === 2 && <PurchasePromo promo={promos[index]} />}
                {promos.length > 0 && promos[index].promo_type === 3 && <ProductPromo promo={promos[index]} />}
            </DialogContent>
            <ActionBtns promos={promos} index={index} setIndex={setIndex} refetch={refetch} />
        </Dialog>
    )
}
