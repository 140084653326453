import { Box, List } from '@mui/material'
import CategoryItem from './CategoryItem'


export default function CategoryList({ menus, onSelect, indent = 0 }) {

    return (
        <List sx={{ padding: 0, width: '250px' }}>
            {
                menus.map(
                    m => <Box key={m.id}>
                            <CategoryItem menuItem={{...m, expanded: true}} indent={indent} onSelect={onSelect} />
                            {
                                m?.children?.length > 0
                                    ?   <CategoryList menus={m.children} indent={indent + 35} onSelect={onSelect} />
                                    :   null
                            }
                        </Box>
                )
            }
        </List>
    )
}
