import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { getSupCustTypes, getSuppCustomers } from "../../../../../api/company"
import { scrollStyle } from "../../../../../utils/constants"
import TablePages from "../../../../common/TablePages"
import { useEffect, useRef, useState } from "react"
import { isEmpty, keys } from "underscore"
import { useQuery } from "react-query"
import HeadCell from "./Cells/HeadCell"
import FilterRow from "./Filter"
import Row from "./Row"

export default function Customers() {
	const [columns, setColumns] = useState({
		name: { label: "Нэр", order: "", align: "center" },
		rn: { label: "РД", order: "", align: "center" },
		email: { label: "И-мэйл", order: "", align: "center" },
		phone: { label: "Утасны дугаар", order: "", align: "center" },
		is_cmp: { label: "Компани эсэх", order: "", align: "center" },
		cust_type: { label: "Ангилал", order: "", align: "center" },
		note: { label: "Нэмэлт мэдээлэл", order: "", align: "center" },
		created: { label: "Огноо", order: "", align: "center" },
	})
	// ordering
	const handleOrder = (fieldName) => {
		const c = columns?.[fieldName]
		const v =
			c?.order === ""
				? fieldName
				: c?.order === fieldName
					? `-${fieldName}`
					: ""
		setColumns({ ...columns, [fieldName]: { ...c, order: v } });
	}

	const orderParam = keys(columns)?.reduce((acc, k) => {
		const cur = columns?.[k]?.order;
		if (cur !== "") {
			acc += '&ordering=' + cur
		}
		return acc
	}, "")

	const [page, setPage] = useState(1)
	const [pageSize, setPageSize] = useState(20)
	const [filtering, setFiltering] = useState(null)

	const [partners, setPartners] = useState([])
	const [count, setCount] = useState(0)
	const getData = (res) => { setPartners(res?.data?.results || []); setCount(res?.data?.count || 0) }
	const { data } = useQuery(["get_suppcustomers", page, pageSize, orderParam, filtering], getSuppCustomers, { onSuccess: getData })
	const [custTypes, setCustTypes] = useState([])
	const { data: ctData } = useQuery(["get_supcusttypes"], getSupCustTypes, { onSuccess: (res) => setCustTypes(res?.data || []) })
	//console.log("C: ", custTypes)

	useEffect(() => {
		if (!isEmpty(data?.data)) {
			setPartners(data?.data?.results || [])
		} else {
			setPartners([])
		}
	}, [data?.data])

	const hhRef = useRef(null)
	//console.log("P: ", data)

	return (
		<>
			<TableContainer sx={{ mt: 1, height: '100%', ...scrollStyle, '&::-webkit-scrollbar': { width: 8, height: 8 } }}>
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow
							sx={{
								"& > th": {
									fontSize: "12px",
									backgroundColor: "secondary.light",
									color: "white",
									borderRight: "1px solid white",
									p: "4px 4px",
									textAlign: "center",
								},
							}}
							ref={hhRef}
						>
							<TableCell>
								<Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
									№
								</Typography>
							</TableCell>
							{
								keys(columns)?.map((c) => (
									<HeadCell
										key={c}
										field={columns?.[c]}
										fieldName={c}
										handleOrder={handleOrder}
									/>
								))
							}
						</TableRow>
					</TableHead>
					<FilterRow filtering={filtering} setFiltering={setFiltering} />
					<TableBody>
						{
							partners?.map((row, idx) => (
								<Row
									key={`${idx}`}
									row={row}
									setPartners={setPartners}
									idx={idx}
									page={page}
									pageSize={pageSize}
									//custTypes={custTypes}
								/>
							))
						}
					</TableBody>
					<TablePages
						count={count}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						colSpan={keys(columns)?.length + 1}
					/>
				</Table>
			</TableContainer>
		</>
	)
}