import { TableCell, TextField } from '@mui/material'


export default function QtyCell({ setSelected, itemId, initialQty }) {
    const handleQty = (qty) => {
        if (qty >= 1) {
            setSelected(prev => prev.map(item => item.id === itemId ? { ...item, qty } : item))
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            handleQty(e.target.value)
        }
    }

    return (
        <TableCell align='center' key={'qty'} sx={{ width: '100px' }}>
            <TextField
                size='small'
                variant='standard'
                type='number'
                defaultValue={initialQty||1}
                sx={{ 
                    width: '100%',
                    '& .MuiInput-root': {
                        '& input': { padding: 0, textAlign: 'center' },
                    },
                }}
                onBlur={(e) => handleQty(e.target.value)}
                onKeyDown={handleEnter}
            />
        </TableCell>
    )
}
