import { Box, Tab, Tabs } from '@mui/material'
import CustomerSupp from './CustomerSupp'
import Customer from './Customer'
import { useState } from 'react'

export default function CustomerGroup() {
    const [tab, setTab] = useState(0)

    return (
        <>
            <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                aria-label='tabs'
                sx={{
                    minHeight: '30px', height: '30px',
                    '& .MuiTab-root': { minHeight: '30px', height: '30px' }
                }}
            >
                <Tab label={'PHARMO харилцагч'} value={0} />
                <Tab label={'MACS харилцагч'} value={1} />
            </Tabs>
            <Box sx={{ height: '100%' }}>
                {tab === 0 && <CustomerSupp />}
                {tab === 1 && <Customer />}
            </Box>
        </>
    )
}

