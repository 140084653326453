import { Switch, TableCell, TableRow, Tooltip } from "@mui/material"
import { getCustomerCredit } from '../../../../../../api/company'
import DebtLimitCell from "./Cells/DebtLimitCell"
import { formatDate } from '../../Customer/Row'
import { keys } from 'underscore'
import { toPrice } from "../../../../../../utils/functions"

export default function Row(props) {
	const { row, setPartners, idx, page, pageSize, getCustToEdit } = props;
	const handleCheck = () => {
		getCustomerCredit(row?.id, { is_bad: !row?.is_bad })
			.then((res) => {
				if (res?.data && keys(res.data).includes("is_bad")) {
					setPartners((prev) => prev.map((p) => p.id === row?.id ? { ...p, ...res?.data } : p) || null)
				}
			})
			.catch((e) => {
				console.log("ERROR: ", e);
			})
	}
	// const partner = row?.partner
	const created = row?.created ? formatDate(row.created) : ''
	return (
		<TableRow key={`${idx + 1}`} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "& > td": { padding: "2px 4px", fontSize: "13px" } }}>
			<TableCell>
				{page === 1 ? idx + 1 : page * pageSize + idx + 1}
			</TableCell>
			<TableCell align="left">{row.customer.name}</TableCell>
			<TableCell align="center">{row.customer.rd}</TableCell>
			<TableCell align="center">{row.customer.is_company ? 'Тийм' : 'Үгүй'}</TableCell>
			<TableCell align="center">{row.debt ? toPrice(row.debt) : '-'}</TableCell>
			<DebtLimitCell row={row} setPartners={setPartners} />
			<TableCell align="center">{row.bad_cnt}</TableCell>
			<TableCell align="center" sx={{ textAlign: "center" }}>
				<Tooltip title={row?.is_bad ? "Найдвартай болгох" : "Найдваргүй болгох"} placement="right">
					<Switch checked={row?.is_bad} onChange={handleCheck} size="small" />
				</Tooltip>
			</TableCell>
			<TableCell align="center">{row.customer.email}</TableCell>
			<TableCell align="center">{row.customer.phone}</TableCell>
			<TableCell align="center">{row.orders_cnt}</TableCell>
			<TableCell align="center">{row.orders_sum ? toPrice(row.orders_sum) : '-'}</TableCell>
			<TableCell align="center">{created}</TableCell>
		</TableRow>
	)
}
