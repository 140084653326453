import { Box, Button, MenuItem, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { useEffect, useState } from "react"
import * as Yup from 'yup'
import { getCustomerTypes } from '../../../../../../api/company'
import { addCustomer } from '../../../../../../api/staff'
import { emailYup } from '../../../../../../utils/constants'



export default function AddStaff({ refetch, alertOn, cust }) {
    const [types, setTypes] = useState([]);

    const validationSchema = Yup.object({
        cmp_name: Yup.string().max(150, 'Нэр хэт урт байна!').required('Ажилтны нэр оруулна уу!'),
        cmp_rn: Yup.string().matches(/^\d{7}$/, 'Регистрийн дугаар буруу байна!').required('Регистрийн дугаар оруулна уу!'),
        ...emailYup,
        phone: Yup.string().matches(/^\d{8}$/, 'Утасны дугаар буруу байна!').required('Утасны дугаар оруулна уу!'),
        address: Yup.string().required('Ажил үүрэг сонгоно уу!'),
        lat: Yup.number().required('Уртраг оруулна уу!'),
        lng: Yup.number().required('Өргөрөг оруулна уу!'),
        cust_type: Yup.string().required('Ажил үүрэг сонгоно уу!')
    });

    const { handleSubmit, getFieldProps, errors, touched, values, setFieldValue } = useFormik({
        initialValues: {
            'cmp_name': '',
            'cmp_rn': '',
            'email': '',
            'phone': '',
            'address': '',
            'lat': '',
            'lng': '',
            'cust_type': ''
        },
        onSubmit: (vals) => {
            if (cust != null && cust !== undefined) {
                console.log('edit');
            } else {
                addCustomer(vals)
                    .then(_ => {
                        alertOn();
                        refetch();
                    })
                    .catch(e => {
                        const err = e?.response?.data
                        alert(err);
                    })
            }
        },
        validationSchema,
        validateOnChange: false,
    })

    const getErrors = (name) => {
        const isErr = Object.keys(errors)?.includes(name) && Object.keys(touched)?.includes(name)
        return {
            error: isErr,
            helperText: isErr ? errors[name] : ' '
        }
    }
    useEffect(() => {
        if (cust !== undefined && cust != null) {
            setFieldValue('cmp_name', cust.customer.name);
            setFieldValue('cmp_rn', cust.customer.rd);
            setFieldValue('email', cust.customer.email);
            setFieldValue('phone', cust.customer.phone);
            // setFieldValue('address', cust);
            // setFieldValue('lat', cust);
            // setFieldValue('lng', cust);
            if (cust.cust_type != null && cust.cust_type !== undefined) {
                setFieldValue('cust_type', cust.cust_type.id);
            }
        }
        getCustomerTypes()
            .then(r => {
                let temp = [];
                for (let i = 0; i < r.data.length; i++) {
                    let obj = {};
                    obj.id = r.data[i]['id'];
                    obj.name = r.data[i]['name'];
                    temp.push(obj);
                }
                setTypes(temp);
            })
            .catch(e => { console.log("SERR: ", e) })
    }, [])

    // enter shift
    const idMap = { f1: 'f2', f2: 'f3', f3: 'f4', f4: 'f5', f5: 'f6', f6: 'f7', f7: 'f8' }
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            const curElmId = e?.target?.id
            e.preventDefault()
            if (curElmId === 'f8' && values?.role) {
                handleSubmit()
            } else if (e?.target?.value) {
                document.getElementById(idMap[curElmId])?.focus()
            }
        }
    }

    return (
        <>
            <Box width='400px' sx={{ p: 5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <TextField label='Байгууллагын нэр' name='cmp_name' {...getFieldProps('cmp_name')} {...getErrors('cmp_name')} size='small' id='f1' onKeyDown={handleEnter} />
                    <TextField
                        label='Регистрийн дугаар'
                        name='cmp_rn'
                        {...getFieldProps('cmp_rn')}
                        {...getErrors('cmp_rn')}
                        size='small'
                        id='f2'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Имэйл хаяг'
                        name='email'
                        {...getFieldProps('email')}
                        {...getErrors('email')}
                        size='small'
                        id='f3'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Утасны дугаар'
                        name='phone'
                        {...getFieldProps('phone')}
                        {...getErrors('phone')}
                        size='small'
                        id='f4'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Хаяг'
                        name='address'
                        {...getFieldProps('address')}
                        {...getErrors('address')}
                        size='small'
                        id='f5'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Уртраг'
                        name='lat'
                        {...getFieldProps('lat')}
                        {...getErrors('lat')}
                        size='small'
                        id='f6'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Өргөрөг'
                        name='lng'
                        {...getFieldProps('lng')}
                        {...getErrors('lng')}
                        size='small'
                        id='f7'
                        onKeyDown={handleEnter}
                    />
                    <TextField
                        label='Харилцагчийн төрөл'
                        name='cust_type'
                        {...getFieldProps('cust_type')}
                        {...getErrors('cust_type')}
                        size='small'
                        select
                        id='f8'
                        onKeyDown={handleEnter}
                    >
                        {
                            types.map(s => <MenuItem value={s.id}>{s.name}</MenuItem>)
                        }
                    </TextField>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ p: '8px' }}
                        onClick={handleSubmit}
                        id='f8'
                    >
                        Нэмэх
                    </Button>
                </Box>
            </Box>
        </>
    )
}
