import { TableCell, TextField, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

export default function QtyCell({ itemQty, disabled, updateQty }) {
    const [count, setCount] = useState(itemQty||0)

    const [alertMsg, setAlertMsg] = useState(null)
    useEffect(() => { 
        if (alertMsg) { setTimeout(() => { setAlertMsg(null) }, 2500) }
    }, [alertMsg])

    const handleReturn = (val) => {
        if (val) {
            const nVal = Number(val)
            if (isNaN(nVal)) {
                setAlertMsg('Тоон утга оруулна уу!')
            } else if (nVal < 0) {
                setAlertMsg('0-с багагүй тоон утга оруулна уу!')
            } else { updateQty(val) }
        }
    }
    const handleKeyDown = (e) => { if (e?.code === 'Enter') { handleReturn(count) } }

    return (
        <TableCell align='center' width='100px'>
            <Tooltip placement='right'>
                <TextField
                    size='small'
                    InputProps={{ style: { padding: '0px', margin: '0px', textAlign: 'center', fontSize: '14px' } }}
                    inputProps={{ style: { padding: '2px 4px', textAlign: 'center', borderColor: 'green', cursor: disabled ? 'not-allowed': 'initial' } }}
                    value={count}
                    onChange={e => { setCount(e?.target?.value) }}
                    onKeyDown={handleKeyDown}
                    error={Boolean(alertMsg)}
                    helperText={
                        Boolean(alertMsg)
                            ?   <Typography
                                    sx={{
                                        color: 'white', 
                                        backgroundColor: '#ed6c02',
                                        padding: '1px 8px',
                                        position: 'absolute',
                                        borderRadius: 1,
                                        bottom: -20, left: 0,
                                        fontSize: '13px'
                                    }}
                                    noWrap
                                >{alertMsg}</Typography>
                            :   ''
                    }
                    disabled={disabled}
                />
            </Tooltip>
        </TableCell>
    )
}
