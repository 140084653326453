import { Grid, MenuItem, TextField, styled } from "@mui/material"
import { NumericFormat } from 'react-number-format'
import React from "react"

export const StyledGridItem = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
}))

export const SelectionInput = ({ label, options, getFieldProps, field, errors, touched }) => {
    return <StyledGridItem item xs={4}>
                <TextField
                    select
                    label={label}
                    variant="standard"
                    sx={{ width: '230px' }}
                    size='small'
                    {...getFieldProps(field)}
                    error={errors?.[field] && touched?.[field]}
                    helperText={errors?.[field] && touched?.[field] && errors?.[field]}
                >
                    {Object.keys(options).map((t) => (
                        <MenuItem key={t} value={parseInt(t)}>
                            {options[t]}
                        </MenuItem>
                    ))}
                </TextField>
            </StyledGridItem>
}

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    )
})