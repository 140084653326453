import { Box, Divider } from '@mui/material'
import Notification from './Notification'
import Basket from './Basket'

import User from './User'
//import BasketTemplet from './BasketTemplet'
//import History from './History'

export default function RightSection() {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Basket />
            {/* <History /> */}
            {/* <BasketTemplet /> */}
            <Divider sx={{ backgroundColor: 'primary.light', ml: 1, mr: 1 }} orientation='vertical' flexItem />
            <Notification />
            <Divider sx={{ backgroundColor: 'primary.light', ml: 1, mr: 1 }} orientation='vertical' flexItem />
            <User />
        </Box>
    )
}
