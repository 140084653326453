import { InputAdornment, TableCell, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Close } from '@mui/icons-material'

export default function NameCell({ text, field, filters, setFilters }) {
    const [value, setValue] = useState('')

    const searchTimeoutRef = useRef(null)
    useEffect(() => {
        if (value) {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current)
                searchTimeoutRef.current = null
            }
            searchTimeoutRef.current = setTimeout(() => {
                if (filters !== undefined && filters != null) {
                    setFilters(prev => prev.filter(f => f.k !== field))
                    setFilters(prev => ([...prev, { k: field, v: value, o: '=' }]));
                } else {
                    const s = { k: field, v: value, o: '=' };
                    setFilters(prev => { return [s]; });
                }
            }, 650)
        } else {
            setFilters(prev => {
                if (prev != null && prev !== undefined) { prev.filter(f => f.k !== field); }
            })
        }
        return () => { clearTimeout(searchTimeoutRef.current) }
    }, [value])

    const clearName = () => {
        console.log(filters);
        setValue('');
        if (filters !== undefined && filters != null)
            setFilters(prev => prev.filter(f => f.k !== field))
    }

    return (
        <TableCell sx={{ p: '2px' }}>
            <TextField
                placeholder={text}
                value={value}
                onChange={(e) => { setValue(e.target.value) }}
                InputProps={{
                    endAdornment: (<InputAdornment sx={{ backgroundColor: 'transparent' }}>
                        <Close
                            sx={{
                                fontSize: '15px', color: 'primary.light',
                                backgroundColor: 'transparent',
                                '&:hover': { cursor: 'pointer' },
                                display: value ? 'block' : 'none',
                            }}
                            onClick={clearName}
                        />
                    </InputAdornment>)
                }}
                inputProps={{ style: { padding: '2px 8px' } }}
                sx={{
                    '& fieldset': { borderRadius: 0, borderColor: 'primary.light' },
                    '& .MuiInputBase-input::placeholder': {
                        color: 'primary.light', textAlign: 'center',
                        fontSize: '13px', opacity: 1
                    },
                    border: 'none', width: '100%'
                }}
                size='small'
            />
        </TableCell>
    )
}
