import { createTheme } from "@mui/material"

const healthy = createTheme({
    palette: {
        primary: {
            main: '#8063b6',
        },
        secondary: {
            main: '#017db2',
        },
        neutral: {
            main: '#e0e0e0',
        }
    }
})

const crimson = createTheme({
    palette: {
        primary: {
            main: '#1B2E3C', //#203646
        },
        secondary: {
            main: '#660000',
        },
        neutral: {
            main: '#e0e0e0',
        }
    },
    typography: {
        tableCell: {
            fontSize: 13, // Adjust font size as desired (in rem or px)
        },
        fontFamily: [
            'Lato-Regular',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
})

const organic = createTheme({
    palette: {
        primary: {
            main: '#638A55',
        },
        secondary: {
            main: '#C48D60',
        },
        neutral: {
            main: '#97E5DB',
        }
    }
})

const glamour = createTheme({
    palette: {
        primary: {
            main: '#1D2D5F',
        },
        secondary: {
            main: '#DE3C63',
        },
        neutral: {
            main: '#6B6E8D',
        }
    }
})

export const themes = [
    {
      'name': 'Crimson',
      'theme': crimson
    },
    {
      'name': 'Healthy',
      'theme': healthy
    },
    {
      'name': 'Organic',
      'theme': organic
    },
    {
      'name': 'Glamour',
      'theme': glamour
    }
  ]
