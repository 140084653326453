import { TableCell, TableRow } from "@mui/material";

export default function Row(props) {
	const { row, idx, page, pageSize } = props;
	return (
		<TableRow
			key={`${idx + 1}`}
			sx={{
				"&:last-child td, &:last-child th": { border: 0 },
				"& > td": { padding: "2px 4px", fontSize: "13px" },
			}}
		>
			<TableCell>				{page === 1 ? idx + 1 : page * pageSize + idx + 1}			</TableCell>
			<TableCell align="center">{row.supplier.name}</TableCell>
			<TableCell align="center">{row.debt}</TableCell>
			<TableCell align="center">{row.credit}</TableCell>
			<TableCell align="center">{row.orders_cnt}</TableCell>
			<TableCell align="center">{row.orders_sum}</TableCell>
			<TableCell align="center">{row.created}</TableCell>
		</TableRow>
	)
}
