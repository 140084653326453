import { Box, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'
import { Clear } from '@mui/icons-material'


export default function Adr({ address, setFieldValue, error }) {
    const handleClear = () => { setFieldValue('address', null) }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
                label='Дэлгэрэнгүй хаяг'
                value={address ? address : ''}
                multiline
                rows={4}
                inputProps={{ style: { fontSize: '15px', cursor: 'pointer' } }}
                InputLabelProps={{ style: { fontSize: '15px' } }}
                InputProps={{ readOnly: true, endAdornment: (
                    <InputAdornment>
                        <Tooltip title='Арилгах' placement='right'>
                            <IconButton onClick={handleClear}>
                                <Clear fontSize='15px' />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                )}}
                size='small'
                sx={{ width: '100%', mb: 1 }}
                error={Boolean(error)}
                helperText={Boolean(error) ? error : ''}
            />
        </Box>
    )
}
