import { scrollStyle } from '../../../../../../../../../../utils/constants'
import { getMnfrs } from '../../../../../../../../../../api/product'
import { DialogContent, Typography } from '@mui/material'
import { useAdminCtx } from '../../../../../../../..'
import { useProductListCtx } from '../../../../..'
import { useEffect } from 'react'

export default function MnfrList({ handleSelect, toClose }) {
    const { alertWarning } = useAdminCtx()
    const { mnfrs, setMnfrs } = useProductListCtx()
    useEffect(() => {
        if (mnfrs?.length === 0) {
            getMnfrs()
                .then(res => { setMnfrs(res?.data) })
                .catch(e => { console.log("ERR: ", e); alertWarning(); toClose() })
        }
    }, [])

    if (mnfrs?.length === 0) { return null }

    return (
        <DialogContent sx={{ ...scrollStyle, '&::-webkit-scrollbar': { width: 10, height: 10 }, maxHeight: '500px', width: '300px' }}>
            {
                mnfrs?.map(
                    m => <Typography
                            key={`${m.id}`}
                            onClick={() => {handleSelect(m)}}
                            variant="body1" 
                            sx={{
                                cursor: 'pointer', p: '3px', fontSize: '14px', fontWeight: 'bold',
                                '&:hover': { backgroundColor: '#f5f5f5', color: 'primary.main' }
                            }}
                        >{m.name}</Typography>
                )
            }
        </DialogContent>
    )
}
