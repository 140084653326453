import { getToast } from '../../../../../auth/helpers/ToastAlert'
import { getPromoById } from '../../../../../../api/promo'
import { promoBtnColor } from '../../Featured/PromosBtn'
import { PROMO_TYPES } from '../../../../../constants'
import { TableCell, TableRow } from '@mui/material'
import { Redeem } from '@mui/icons-material'
import { useState } from 'react'

export default function Row(props) {
    const { row, idx, page, pageSize, setSelectedPromo } = props
    const [err, setErr] = useState(null)
    const errOff = () => { setErr(null) }

    const getPromoDetail = (promoId) => {
        getPromoById(promoId)
            .then(res => { setSelectedPromo(res?.data) })
            .catch(err => { console.log("PROMO ERR: ", err) })
    }

    return (
        <>
            {getToast(Boolean(err), errOff, err, 'warning', 1500)}
            <TableRow onClick={() => getPromoDetail(row.id)} sx={{ '&:hover': { backgroundColor: '#e4e4e4', cursor: 'pointer' }, '& > td': { textAlign: 'center', padding: '2px' } }}>
                <TableCell>{page <= 1 ? idx + 1 : (page - 1) * pageSize + idx + 1}</TableCell>
                <TableCell>{row?.name || '-'}</TableCell>
                <TableCell>{row?.promo_code || '-'}</TableCell>
                <TableCell>{PROMO_TYPES[row?.promo_type] || '-'}</TableCell>
                <TableCell>{row?.end_date || '-'}</TableCell>
                <TableCell><Redeem sx={{ color: row?.has_gift ? promoBtnColor : 'transparent' }} /></TableCell>
            </TableRow>
        </>
    )
}
